import React, { useEffect, useMemo, useRef, useState } from "react";
import { View, Card, Text, TouchableOpacity } from "react-native-ui-lib";
import { Col, CustomCheckbox, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../mycomponents/DynamicForm";
import { ScrollView, StyleSheet } from "react-native";
import { Icon } from "react-native-eva-icons";
import DynamicTable from "../../mycomponents/DynamicTable";
import { UNIT_OF_MEASUREMENTS } from "../../services/api";


const ACTIVE_STEP_WIDTH = 30;
const INACTIVE_STEP_WIDTH = 30;

const CustomWizard = ({ steps, activeStep, setActiveStep }) => {
    const scrollViewRef = useRef(null);

    const stepPositions = useMemo(() => {
        let accumulator = 0;
        return steps.map((_, index) => {
            const position = accumulator;
            accumulator += index === activeStep ? ACTIVE_STEP_WIDTH : INACTIVE_STEP_WIDTH;
            return position;
        });
    }, [steps, activeStep]);

    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({
                x: stepPositions[activeStep],
                animated: true,
            });
        }
    }, [activeStep, stepPositions]);

    const renderStep = (step, index) => {
        const isActive = index === activeStep;
        const isDone = index < activeStep || step.status === 'done';

        return (
            <TouchableOpacity
                key={index}
                style={[
                    styles.step,
                ]}
                onPress={() => setActiveStep(index)}
            >
                <View
                    style={[
                        styles.step,
                    ]}
                >
                    <View row centerV style={[
                        styles.iconContainer
                    ]}>
                        <View backgroundColor={
                            index <= activeStep ? '#0e86fe' : "#c3d1eb"
                        } br100 padding-4>
                            <Text center style={{
                                width: 16,
                                height: 16,
                                fontSize: 12,
                                fontFamily: 'SourceSansProSemiBold',
                                color: 'white',
                            }}>{index + 1}</Text>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.lineContainer}>
                <View
                    style={[
                        styles.line,
                        styles.backgroundLine
                    ]}
                />
                <View
                    style={[
                        styles.line,
                        {
                            backgroundColor: '#0e86fe',
                            width: `${((activeStep) / (steps.length - 1)) * 100}%`,
                            zIndex: 1,
                        },
                    ]}
                />
            </View>

            <ScrollView
                ref={scrollViewRef}
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={styles.scrollContent}
            >
                {steps.map(renderStep)}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    lineContainer: {
        position: 'relative',
        width: '100%',
        height: 4, // Adjust as needed
        top: '50%',
    },
    line: {
        position: 'absolute',
        height: '100%',
        left: 0,
        top: 0,
    },
    backgroundLine: {
        backgroundColor: '#c3d1eb', // Faded color
        width: '100%',
        zIndex: 0,
    },
    container: {
        height: 60,
        position: 'relative',
    },
    scrollContent: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    step: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeStepText: {
        fontWeight: 'bold',
    },
    iconContainer: {

    },
    stepNumber: {
        fontSize: 16,
    },
});




export const productionWorkFlowfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const inputcolumns = [
        {
            "field": "item_id",
            "label": "Item",
            "editable": true,
            "type": "select",
            "visible": true,
            "order": 1,
            "Header": "Item",
            "accessor": "item_id",
            "placeholder": "Select the item",
            "options": response.Items || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = value?.value || value;
                const item = response.Items.find((item) => item._id == item_id);

                const newItems = [...getObj().input_materials];

                newItems[tIndex].item_id = item_id;
                newItems[tIndex].unit = item.unit;
                newItems[tIndex].quantity = 1;
                newItems[tIndex].unit_quantity = 1;
                newItems[tIndex].purchase_rate = item.purchase_rate;


                const obj = getObj();
                const steps = obj.steps;

                const newSteps = steps.map((step, index) => {
                    if (index == obj.activeStep) {
                        return {
                            ...step,
                            input_materials: newItems,
                        }
                    } else {
                        return step;
                    }
                });

                setObj({ input_materials: newItems, steps: newSteps });

                const formObject = getObj();
                const unit = item.unit;
                const original_unit = item.unit;
                const unit_quantity = 1;

                console.log({ formObject, unit, original_unit, unit_quantity });

                updateMappedItems({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "unit_quantity",
            "label": "Quantity",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 2,
            "Header": "Quantity",
            "accessor": "unit_quantity",
            "placeholder": "Enter the quantity",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = getObj().input_materials[tIndex].item_id;
                const item = response.Items.find((item) => item._id == item_id);

                const formObject = getObj();
                const unit = getObj().input_materials[tIndex].unit;
                const original_unit = item.unit;
                const unit_quantity = value;

                updateMappedItems({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "custom_unit",
            "label": "Unit",
            "editable": true,
            "type": "custom",
            "visible": true,
            "order": 3,
            "Header": "Unit",
            "accessor": "unit",
            "placeholder": "Enter the unit",
            "component": ({ field, tKey, tIndex, useForm, viewOnly }) => {
                const formObject = useForm((state) => state.formObject);
                const item_id = formObject?.['input_materials']?.[tIndex]?.item_id;
                const item = response?.Items?.find((item) => item._id == item_id);
                const original_unit = item?.unit;
                const unit = formObject?.['input_materials']?.[tIndex]?.unit;
                const unit_quantity = formObject?.['input_materials']?.[tIndex]?.unit_quantity;

                const setObj = useForm((state) => state.setFormObject);
                const getObj = useForm((state) => state.getFormObject);

                const unitValue = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

                if (!item || !unitValue) {
                    return <View>
                        {/* <Text>-</Text> */}
                    </View>
                }

                let options = [];

                if (unitValue?.base_unit) {
                    const all_units = UNIT_OF_MEASUREMENTS.filter((el) => el.base_unit == unitValue.base_unit);
                    options = all_units.map((el) => ({ label: (el.code + " (" + el.name + ")"), value: el.code })) || [];
                } else {
                    options = [{ label: (unitValue.code + " (" + unitValue.name + ")"), value: unitValue.code }];
                }


                return <View>
                    {viewOnly ? <Text>{unit}</Text> :
                        <CustomSelect
                            value={unit}
                            onChange={(value) => {
                                const newItems = [...getObj().input_materials];
                                newItems[tIndex].unit = value;

                                const obj = getObj();
                                const steps = obj.steps;

                                const newSteps = steps.map((step, index) => {
                                    if (index == obj.activeStep) {
                                        return {
                                            ...step,
                                            input_materials: newItems,
                                        }
                                    } else {
                                        return step;
                                    }
                                });

                                setObj({ input_materials: newItems, steps: newSteps });

                                updateMappedItems({ setObj, unit: value, original_unit, unit_quantity, tIndex, getObj, formObject });
                            }}
                            options={options}
                        />
                    }
                </View>
            },
        },
        {
            label: (props) => null,
            accessor: 'actions',
            field: 'actions',
            Header: (props) => null,
        }
    ];

    const outputcolumns = [
        {
            "field": "item_id",
            "label": "Item",
            "editable": true,
            "type": "select",
            "visible": true,
            "order": 1,
            "Header": "Item",
            "accessor": "item_id",
            "placeholder": "Select the item",
            "options": response.Items || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = value?.value || value;
                const item = response.Items.find((item) => item._id == item_id);

                const newItems = [...getObj().output_materials];

                newItems[tIndex].item_id = item_id;
                newItems[tIndex].unit = item.unit;
                newItems[tIndex].quantity = 1;
                newItems[tIndex].unit_quantity = 1;
                newItems[tIndex].purchase_rate = item.purchase_rate;


                const obj = getObj();
                const steps = obj.steps;

                const newSteps = steps.map((step, index) => {
                    if (index == obj.activeStep) {
                        return {
                            ...step,
                            output_materials: newItems,
                        }
                    } else {
                        return step;
                    }
                });

                setObj({ output_materials: newItems, steps: newSteps });

                const formObject = getObj();
                const unit = item.unit;
                const original_unit = item.unit;
                const unit_quantity = 1;

                console.log({ formObject, unit, original_unit, unit_quantity });

                updateMappedItemsOutput({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "unit_quantity",
            "label": "Quantity",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 2,
            "Header": "Quantity",
            "accessor": "unit_quantity",
            "placeholder": "Enter the quantity",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = getObj().output_materials[tIndex].item_id;
                const item = response.Items.find((item) => item._id == item_id);

                const formObject = getObj();
                const unit = getObj().output_materials[tIndex].unit;
                const original_unit = item.unit;
                const unit_quantity = value;

                updateMappedItemsOutput({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "custom_unit",
            "label": "Unit",
            "editable": true,
            "type": "custom",
            "visible": true,
            "order": 3,
            "Header": "Unit",
            "accessor": "unit",
            "placeholder": "Enter the unit",
            "component": ({ field, tKey, tIndex, useForm, viewOnly }) => {
                const formObject = useForm((state) => state.formObject);
                const item_id = formObject?.['output_materials']?.[tIndex]?.item_id;
                const item = response?.Items?.find((item) => item._id == item_id);
                const original_unit = item?.unit;
                const unit = formObject?.['output_materials']?.[tIndex]?.unit;
                const unit_quantity = formObject?.['output_materials']?.[tIndex]?.unit_quantity;

                const setObj = useForm((state) => state.setFormObject);
                const getObj = useForm((state) => state.getFormObject);

                const unitValue = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

                if (!item || !unitValue) {
                    return <View>
                        {/* <Text>-</Text> */}
                    </View>
                }

                let options = [];

                if (unitValue?.base_unit) {
                    const all_units = UNIT_OF_MEASUREMENTS.filter((el) => el.base_unit == unitValue.base_unit);
                    options = all_units.map((el) => ({ label: (el.code + " (" + el.name + ")"), value: el.code })) || [];
                } else {
                    options = [{ label: (unitValue.code + " (" + unitValue.name + ")"), value: unitValue.code }];
                }


                return <View>
                    {viewOnly ? <Text>{unit}</Text> :
                        <CustomSelect
                            value={unit}
                            onChange={(value) => {
                                const newItems = [...getObj().output_materials];
                                newItems[tIndex].unit = value;

                                const obj = getObj();
                                const steps = obj.steps;

                                const newSteps = steps.map((step, index) => {
                                    if (index == obj.activeStep) {
                                        return {
                                            ...step,
                                            output_materials: newItems,
                                        }
                                    } else {
                                        return step;
                                    }
                                });

                                setObj({ output_materials: newItems, steps: newSteps });

                                updateMappedItemsOutput({ setObj, unit: value, original_unit, unit_quantity, tIndex, getObj, formObject });
                            }}
                            options={options}
                        />
                    }
                </View>
            },
        },
        {
            label: (props) => null,
            accessor: 'actions',
            field: 'actions',
            Header: (props) => null,
        }
    ];

    const LevelsComponent = ({ field, useForm, tKey, tIndex }) => {
        const obj = useForm((state) => state.formObject);
        const getObj = useForm((state) => state.getFormObject);
        const setObj = useForm((state) => state.setFormObject);
        // const [activeStep, setActiveStep] = useState(0);
        const activeStep = obj.activeStep || 0;
        const input_materials = obj.input_materials || [];
        const output_materials = obj.output_materials || [];

        const steps = obj.steps || [{
            name: 'Process 1',
            incharge: '',
            duration: 0,
            duration_unit: 'days',
            input_materials: [],
            output_materials: [],
        }];

        const step = steps[activeStep] || {};

        const setSteps = (steps) => {
            setObj({
                steps: steps
            });

            console.log({ steps });
        }

        const setActiveStep = (newStep) => {
            setObj({
                activeStep: newStep
            });
        }

        useEffect(() => {
            const activeStepData = steps[0];
            const input_materials = activeStepData.input_materials || [];
            const output_materials = activeStepData.output_materials || [];
            setObj({
                input_materials: input_materials,
                output_materials: output_materials,
            });
        }, []);

        return <View style={{ marginBottom: 20 }} flex>

            <View row right>
                <SecondaryButton label="Add Process" onPress={() => {
                    setSteps([...steps, { name: `Process ${steps.length + 1}`, input_materials: [], output_products: [] }]);
                }} />
            </View>
            <CustomWizard steps={steps} activeStep={activeStep} setActiveStep={(newStep) => {
                setActiveStep(newStep);
                const activeStepData = steps[newStep];
                const input_materials = activeStepData.input_materials || [];
                const output_materials = activeStepData.output_materials || [];
                setObj({
                    input_materials: input_materials,
                    output_materials: output_materials,
                });
            }} />

            {steps[activeStep] ? <View style={{ marginTop: 20 }} flex>
                <Row>
                    <Col width={"100%"}>
                        <View row centerV style={{
                            width: '100%',
                        }}>
                            <View row style={{
                                flex: 1,
                                marginRight: 10
                            }}>
                                <View style={{
                                    flex: 1,
                                    marginRight: 10
                                }}>
                                    <CustomTextField
                                        label="Name"
                                        placeholder="Enter Name"
                                        value={steps[activeStep].name}
                                        onChange={(value) => {
                                            steps[activeStep].name = value;
                                            setSteps([...steps]);
                                        }}
                                    />
                                </View>
                            </View>

                            <DangerButton label="Remove" onPress={() => {
                                steps.splice(activeStep, 1);
                                setSteps([...steps]);

                                if (activeStep > 0) {
                                    setActiveStep(activeStep - 1);
                                } else {
                                    setActiveStep(0);
                                }
                            }} />
                        </View>

                        <View marginT-10 row centerV style={{
                            width: '100%',
                        }}>
                            <View style={{
                                width: 350,
                                marginRight: 10
                            }}>
                                {Label({ label: 'Process Incharge' })}
                                <CustomSelect
                                    options={response.Employees || []}
                                    placeholder="Select Process Incharge"
                                    value={step.incharge}
                                    onChange={(value) => {
                                        steps[activeStep].incharge = value;
                                        setSteps([...steps]);
                                    }}
                                />
                            </View>

                            <View style={{
                                width: 300,
                                marginRight: 10
                            }}>
                                {Label({ label: 'Process Duration' })}
                                <CustomNumberInput
                                    label="Duration"
                                    placeholder="Enter Duration"
                                    value={step.duration}
                                    onChange={(value) => {
                                        steps[activeStep].duration = value;
                                        setSteps([...steps]);
                                    }}
                                />
                            </View>

                            <View style={{
                                width: 300
                            }}>
                                {Label({ label: 'Process Duration Unit' })}
                                <CustomSelect
                                    options={[
                                        {
                                            label: 'Days',
                                            value: 'days',
                                        },
                                        {
                                            label: 'Hours',
                                            value: 'hours',
                                        },
                                        {
                                            label: 'Minutes',
                                            value: 'minutes',
                                        }
                                    ]}
                                    placeholder="Select Process Duration Unit"
                                    value={step.duration_unit}
                                    onChange={(value) => {
                                        steps[activeStep].duration_unit = value;
                                        setSteps([...steps]);
                                    }}
                                />
                            </View>
                        </View>
                    </Col>
                </Row>

                <Row>
                    <Col width={"100%"}>
                        {/* {Label({ label: 'Input Materials and Costings' })} */}
                        <Text Text h1 marginB-10>Input Materials and Costings</Text>

                        <DynamicTable
                            data={input_materials}
                            columns={inputcolumns}
                            editable={true}
                            updateData={(data) => {

                            }}
                            actions={true}
                            actionFunctions={(row) => ([{
                                label: 'Delete',
                                key: 'delete',
                                fn: ({ data, updateData, row, row_index }) => {
                                    let newData = [...data];
                                    newData.splice(row_index, 1);
                                    setObj({ input_materials: newData });

                                    const newSteps = steps.map((step, index) => {
                                        if (index == activeStep) {
                                            return {
                                                ...step,
                                                input_materials: newData,
                                            }
                                        } else {
                                            return step;
                                        }
                                    });
                                    setSteps(newSteps);
                                }
                            }])}
                            useForm={useForm}
                            tableKey={'input_materials'}
                        />

                        <View marginT-20>
                            <SecondaryButton
                                label="Add Input Material/Costing"
                                onPress={() => {
                                    let data = [...input_materials];
                                    data.push({
                                        quantity: 1,
                                    });

                                    setObj({
                                        input_materials: data
                                    });

                                    const newSteps = steps.map((step, index) => {
                                        if (index == activeStep) {
                                            return {
                                                ...step,
                                                input_materials: data,
                                            }
                                        } else {
                                            return step;
                                        }
                                    });
                                    setSteps(newSteps);
                                }}
                            />
                        </View>
                    </Col>

                    <Col width={"100%"}>
                        {/* {Label({ label: 'Output Products' })} */}
                        <Text Text h1 marginB-10>Output Products</Text>

                        <DynamicTable
                            data={output_materials}
                            columns={outputcolumns}
                            editable={true}
                            updateData={(data) => {

                            }}
                            actions={true}
                            actionFunctions={(row) => ([{
                                label: 'Delete',
                                key: 'delete',
                                fn: ({ data, updateData, row, row_index }) => {
                                    let newData = [...data];
                                    newData.splice(row_index, 1);
                                    setObj({ output_materials: newData });

                                    const newSteps = steps.map((step, index) => {
                                        if (index == activeStep) {
                                            return {
                                                ...step,
                                                output_materials: newData,
                                            }
                                        } else {
                                            return step;
                                        }
                                    });
                                    setSteps(newSteps);
                                }
                            }])}
                            useForm={useForm}
                            tableKey={'output_materials'}
                        />

                        <View marginT-20>
                            <SecondaryButton
                                label="Add Output Product"
                                onPress={() => {
                                    let data = [...output_materials];
                                    data.push({
                                        quantity: 1,
                                    });

                                    setObj({
                                        output_materials: data
                                    });

                                    const newSteps = steps.map((step, index) => {
                                        if (index == activeStep) {
                                            return {
                                                ...step,
                                                output_materials: data,
                                            }
                                        } else {
                                            return step;
                                        }
                                    });
                                    setSteps(newSteps);
                                }}
                            />
                        </View>
                    </Col>
                </Row>


            </View> : null}
        </View>
    }


    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Name',
            visible: true,
            width: '25%',
            required: true,
        },
        {
            label: '',
            key: 'levels',
            type: 'custom',
            component: LevelsComponent,
            visible: true,
            width: '100%',
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const productionWorkFlowMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const productionWorkFlowMain = {
    fields: productionWorkFlowfields,
    mobileCard: null
}

const updateMappedItems = ({ setObj, unit, original_unit, unit_quantity, tIndex, getObj, formObject }) => {
    if (unit && original_unit && unit_quantity) {
        const originalUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == original_unit);
        const selectedUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

        if (originalUnitData && selectedUnitData && originalUnitData.base_unit === selectedUnitData.base_unit) {
            // Convert to base unit, then to original unit
            const baseUnitQuantity = unit_quantity * selectedUnitData.conversion_factor;
            const originalUnitQuantity = baseUnitQuantity / originalUnitData.conversion_factor;

            // Update the formObject with the new quantity
            setObj({
                input_materials: formObject.input_materials.map((item, index) =>
                    index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                )
            });

            const obj = getObj();
            const steps = obj.steps;

            const newSteps = steps.map((step, index) => {
                if (index == obj.activeStep) {
                    return {
                        ...step,
                        input_materials: formObject.input_materials.map((item, index) =>
                            index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                        ),
                    }
                } else {
                    return step;
                }
            });

            setObj({ steps: newSteps });
        }
    }
}

const updateMappedItemsOutput = ({ setObj, unit, original_unit, unit_quantity, tIndex, getObj, formObject }) => {
    if (unit && original_unit && unit_quantity) {
        const originalUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == original_unit);
        const selectedUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

        if (originalUnitData && selectedUnitData && originalUnitData.base_unit === selectedUnitData.base_unit) {
            // Convert to base unit, then to original unit
            const baseUnitQuantity = unit_quantity * selectedUnitData.conversion_factor;
            const originalUnitQuantity = baseUnitQuantity / originalUnitData.conversion_factor;

            // Update the formObject with the new quantity
            setObj({
                output_materials: formObject.output_materials.map((item, index) =>
                    index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                )
            });

            const obj = getObj();
            const steps = obj.steps;

            const newSteps = steps.map((step, index) => {
                if (index == obj.activeStep) {
                    return {
                        ...step,
                        output_materials: formObject.output_materials.map((item, index) =>
                            index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                        ),
                    }
                } else {
                    return step;
                }
            });

            setObj({ steps: newSteps });
        }
    }
}