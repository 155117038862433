// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomTextFieldWithScanner, DangerButton, Heading, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../mycomponents/state/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import * as Print from 'expo-print';
import Svg, { Circle, Path, Rect } from "react-native-svg"
import moment from "moment"
import ListingScreen from "../../../../../mycomponents/CRUD/ListingScreen"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const ErrorMessage = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={202}
        height={135}
        fill="none"
        {...props}
    >
        <Circle cx={100} cy={74} r={61} fill="#F1F2F8" />
        <Path
            fill="#fff"
            d="M53.802 108.728v-48.97l13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18v48.971a2 2 0 0 1-2 2h-88.03a2 2 0 0 1-2-2Z"
        />
        <Path
            stroke="#D9DBE8"
            d="M53.802 59.758v48.97a2 2 0 0 0 2 2h88.03a2 2 0 0 0 2-2v-48.97m-92.03 0h25.562a2 2 0 0 1 1.849 1.238l3.17 7.7a5 5 0 0 0 4.624 3.096h21.795c1.937 0 3.7-1.118 4.524-2.87l3.772-8.016a2 2 0 0 1 1.81-1.148h24.924m-92.03 0 13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18"
        />
        <Rect
            width={12.743}
            height={4.248}
            x={93.445}
            y={97.277}
            fill="#D9DBE8"
            rx={2.124}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={83.535}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={110.436}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Path
            fill="#fff"
            stroke="#D9DBE8"
            d="M109.936 18.698c0 6.505 4.174 12.15 10.409 15.363l-.387 6.192c-.068 1.09 1.231 1.703 2.03.958l5.158-4.814a27.5 27.5 0 0 0 5.235.499c12.291 0 22.446-8.052 22.446-18.198S144.672.5 132.381.5c-12.29 0-22.445 8.052-22.445 18.198Z"
        />
        <Path
            fill="#fff"
            d="M154.327 18.698c0 9.774-9.826 17.698-21.946 17.698-12.12 0-21.945-7.924-21.945-17.698C110.436 8.924 120.261 1 132.381 1c12.12 0 21.946 7.924 21.946 17.698Z"
        />
        <Path
            fill="#fff"
            d="m120.457 40.284.55-8.786a.707.707 0 0 1 .806-.657l7.688 1.098a.708.708 0 0 1 .383 1.219l-8.237 7.688a.708.708 0 0 1-1.19-.562Z"
        />
        <Path
            fill="#D9DBE8"
            d="m129.035 9.36-4.552.317a.5.5 0 0 0-.458.581l2.284 13.74a.5.5 0 0 0 .528.418l1.918-.134a.5.5 0 0 0 .465-.487l.35-13.924a.5.5 0 0 0-.535-.512ZM141.114 10.533l-4.469-.926a.5.5 0 0 0-.598.435l-1.522 13.845a.5.5 0 0 0 .396.545l1.882.39a.5.5 0 0 0 .579-.342l4.108-13.31a.5.5 0 0 0-.376-.637ZM126.645 28.186l-.128-1.827a.5.5 0 0 1 .464-.534l1.827-.128a.5.5 0 0 1 .534.464l.128 1.827a.5.5 0 0 1-.464.534l-1.827.128a.5.5 0 0 1-.534-.464ZM133.715 28.009l.371-1.794a.5.5 0 0 1 .591-.388l1.794.372a.5.5 0 0 1 .388.591l-.372 1.794a.5.5 0 0 1-.591.388l-1.793-.372a.499.499 0 0 1-.388-.591Z"
        />
        <Circle cx={25.485} cy={110.02} r={8.495} fill="#F1F2F8" />
        <Circle
            cx={179.074}
            cy={41.322}
            r={8.495}
            fill="#F1F2F8"
            transform="rotate(132.827 179.074 41.322)"
        />
        <Circle cx={4.248} cy={40.644} r={4.248} fill="#F1F2F8" />
        <Circle cx={12.743} cy={125.594} r={4.248} fill="#F1F2F8" />
        <Circle
            cx={195.892}
            cy={35.675}
            r={4.248}
            fill="#F1F2F8"
            transform="rotate(132.827 195.892 35.675)"
        />
    </Svg>
)

const AssetsScreen = observer(function AssetsScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    let queryParams = {};

    if (Platform.OS === 'web') {
        const params = window.location.search.substring(1).split('&')
        params.map((param) => {
            const [key, value] = param.split('=')
            if (key) {
                queryParams[key] = value;
            }
        })
    } else {
        queryParams = props.route.params || {};
    }

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const [search, setSearch] = useState("");
    const [counter, setCounter] = useState(null);
    const [methods, setMethods] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccountId, setcashAccountId] = useState('');

    const [selectedMethod, setSelectedMethod] = useState("1");
    const [locations, setLocations] = useState([]);

    const [saving, setSaving] = useState(false);

    const [tables, setTables] = useState([]);
    const [areas, setAreas] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const debounceRef = React.useRef(null);

    const [response, setResponse] = useState(null);

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);

    const moduleName = "Invoices";

    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);

    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    const [takeAwayInvoices, setTakeAwayInvoices] = useState([]);
    const [inKitchenItems, setInKitchenItems] = useState([]);
    const [categories, setCategories] = useState([]);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {
                    //navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getAllTables({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    //goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getAllTables({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                //goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }

    const getAllTables = async (values = null) => {
        const response = await api.get("Tables", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {},
            "search": "",
            "groupBy": null
        });

        setTables(response?.data.data || []);

        const areas = response?.data.data?.map((item) => {
            return item.area
        });

        const uniqueAreas = [...areas].filter((area) => {
            return area != null;
        }).filter((area, index, self) =>
            index === self.findIndex((t) => (
                t._id === area._id
            ))
        );

        setAreas(uniqueAreas);
    }

    const goToEdit = (id) => {
        if (Platform.OS === 'web') {
            setOpenModal({
                ["Tables"]: {
                    isVisible: true,
                    moduleName: "Tables",
                    id: id,
                    isEdit: true,
                    afterSaveEvent: 'reloadListing',
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "Tables",
                isEdit: true,
                id: id,
                goBackAfterSave: true
            });
        }
    }

    const goToPOS = (table_id, invoice_id) => {
        const obj = {
            // table_id: table_id,
        };

        if (table_id) {
            obj.table_id = table_id;
        }

        if (invoice_id) {
            obj.invoice_id = invoice_id;
        }

        navigationFn('POS', obj);
    }

    const newModule = () => {
        if (Platform.OS === 'web') {
            const query = {}
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    query[key] = value;
                }
            })

            setOpenModal({
                ["Tables"]: {
                    isVisible: true,
                    moduleName: "Tables",
                    afterSaveEvent: 'reloadListing',
                    disableEdit: props.disableEdit,
                    disableDelete: props.disableDelete,
                    ...(props.default_values ? props.default_values : {})
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "Tables",
                goBackAfterSave: true,
                disableEdit: props.disableEdit,
                disableDelete: props.disableDelete,
                ...(props.default_values ? props.default_values : {})
            })
        }
    }

    const getTakeAwayInvoices = async () => {
        const response = await api.get("Invoices", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {
                '$and': [
                    {
                        "table_id": {
                            "$exists": false,
                        },
                    },
                    {
                        "date": {
                            "$gte": moment().subtract(15, 'hours').toISOString(),
                            "$lt": moment().add(15, 'hours').toISOString()
                        }
                    }
                ],


            },
            "search": "",
            "groupBy": null
        });

        setTakeAwayInvoices(response?.data.data || []);
    }

    const getInKitchenInvoices = async () => {
        const response = await api.get("Invoices", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {
                "$and": [
                    // {
                    //     "line_items.kot_status": { "$ne": "Delivered" }
                    // },
                    {
                        "$or": [
                            { "line_items.kot_status": "Pending" },
                            { "line_items.kot_completed_at": { "$gte": moment().subtract(15, 'minutes').toISOString() } }
                        ]
                    }
                ]
            },
            "search": "",
            "groupBy": null
        });

        const invoices = response?.data.data || [];

        let items = [];

        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];

            for (let j = 0; j < invoice.line_items.length; j++) {
                const lineItem = invoice.line_items[j];

                if (lineItem.kot_status != "Delivered") {
                    items.push({
                        ...lineItem,
                        invoice: invoice,

                    });
                }
            }
        }

        setInKitchenItems(items);
    }

    const getModulePreferences = async () => {
        const response = await api.getCreateData(moduleName, params);

        const categories = response?.Items?.map((item) => {
            return item.item_category;
        });

        const uniqueCategories = [...categories].filter((category) => {
            return category != null;
        }).filter((category, index, self) =>
            index === self.findIndex((t) => (
                t._id === category._id
            ))
        );

        setCategories(uniqueCategories);
    }

    useEffect(() => {
        setMethods([
            { _id: "1", name: "Dine In" },
            { _id: "2", name: "Take Away" },
            { _id: "3", name: "In Kitchen" },
        ]);

        getAllTables();
        getTakeAwayInvoices();
        getInKitchenInvoices();
        getModulePreferences();
    }, [refreshCount]);

    useEffect(() => {
        DeviceEventEmitter.addListener("reloadListing", (event) => {
            console.log("reloadListing");
            setRefreshCount(Math.random());
        })

        DeviceEventEmitter.addListener("goToEdit", (event) => {
            goToEdit(event._id);
        })

        return () => {
            DeviceEventEmitter.removeAllListeners("reloadListing");
            DeviceEventEmitter.removeAllListeners("goToEdit");
        }
    }, [])

    return (
        <View flex>
            <View backgroundColor="#f9f9f9b5"
                style={{
                    padding: Platform.OS == 'web' ? 20 : 6
                }}
            >
                <Row>
                    <Col width={Platform.OS == 'web' ? "100%" : '100%'} >
                        <Card padding-10 style={{ width: '100%', height: Dimensions.get('window').height - 80 }}>
                            <ListingScreen
                                {...{
                                    moduleName: 'Items',
                                    hideWrapper: true,
                                    extraFilters: {
                                        'product_type': 'Assets',
                                    },
                                    hideViews: true,
                                    hideMainHeader: false,
                                }}
                                title={"Assets"}
                                default_values={{
                                    product_type: 'Assets',
                                    is_default_values: true
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
            </View>
        </View >
    )
})

export default AssetsScreen

