"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationStoreModel = void 0;
var _mobxStateTree = require("mobx-state-tree");
var AuthenticationStoreModel = exports.AuthenticationStoreModel = _mobxStateTree.types.model("AuthenticationStore").props({
  authToken: _mobxStateTree.types.maybe(_mobxStateTree.types.string),
  authEmail: "",
  user: _mobxStateTree.types.maybe(_mobxStateTree.types.frozen())
}).views(function (store) {
  return {
    get isAuthenticated() {
      return !!store.authToken;
    },
    get getToken() {
      return store.authToken;
    },
    get getUser() {
      return store.user;
    },
    get validationError() {
      if (store.authEmail.length === 0) return "can't be blank";
      if (store.authEmail.length < 6) return "must be at least 6 characters";
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.authEmail)) return "must be a valid email address";
      return "";
    }
  };
}).actions(function (store) {
  return {
    setAuthToken: function setAuthToken(value) {
      store.authToken = value;
    },
    setUser: function setUser(value) {
      store.user = value;
    },
    setAuthEmail: function setAuthEmail(value) {
      store.authEmail = value.replace(/ /g, "");
    },
    logout: function logout() {
      store.authToken = undefined;
      store.authEmail = "";
    }
  };
});