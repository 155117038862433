import React, { useEffect, useState } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { CustomSelectItemForContacts } from "../accountancy/transaction";
import { api } from "../../services/api";
import { CustomCheckbox, Label } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";

export const hotelReservationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: true,
            width: '25%',
            options: response.Contacts || [],
            tab: 'General',
            creatableAction: (props) => {
                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
                        is_default_values: true,
                        phone: props.search,
                        is_supplier: false,
                    } : {})
                };
                OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
            },
            customSelectItem: CustomSelectItemForContacts,
            otherSearchKeys: ['phone', 'email'],
            autoFocus: true,
        },

        {
            label: 'Phone',
            key: 'contact___phone',
            type: 'text',
            placeholder: 'Enter the number of people',
            visible: view ? true : false,
            width: '25%',
            tab: 'General',
        },

        {
            label: 'Pax',
            key: 'pax',
            type: 'number',
            placeholder: 'Enter the number of people',
            visible: true,
            width: '25%',
            tab: 'General',
        },

        {
            label: 'Check In',
            key: 'date',
            type: 'datetime',
            placeholder: 'Select the date and time',
            visible: true,
            width: '25%',
            tab: 'General',
        },

        {
            label: 'Check Out',
            key: 'end_date',
            type: 'datetime',
            placeholder: 'Select the date and time',
            visible: true,
            width: '25%',
            tab: 'General',
        },

        // {
        //     label: 'Duration',
        //     key: 'duration',
        //     type: 'duration',
        //     placeholder: 'Enter the duration',
        //     visible: false,
        //     width: '25%',
        //     tab: 'General',
        // },

        // {
        //     label: 'Tables',
        //     key: 'table_ids',
        //     type: 'select',
        //     placeholder: 'Select the tables',
        //     visible: true,
        //     width: '25%',
        //     options: response.Tables || [],
        //     tab: 'General',
        //     otherSearchKeys: ['name'],
        //     multiple: true,
        //     customSelectItem: (props) => {
        //         return <>
        //             <View row centerV spread>
        //                 <Text>{props.label}</Text>
        //                 <View marginL-5>
        //                     <Text tabHeading>{props.area?.name}</Text>
        //                 </View>
        //             </View>
        //         </>
        //     }
        // },

        // {
        //     label: 'Status',
        //     key: 'status',
        //     type: 'select',
        //     placeholder: 'Select the status',
        //     visible: true,
        //     width: '25%',
        //     options: [
        //         // { label: 'Pending', value: 'Pending' },
        //         { label: 'Confirmed', value: 'Confirmed' },
        //         { label: 'Cancelled', value: 'Cancelled' },
        //         // { label: 'Completed', value: 'Completed' },
        //     ],
        //     tab: 'General',
        //     value: 'Confirmed'
        // }

        {
            key: "room_selector",
            type: "custom",
            visible: true,
            width: "100%",
            tab: "General",
            component: ({ field, useForm }) => {
                const date = useForm((state) => state.formObject['date']);
                const end_date = useForm((state) => state.formObject['end_date']);
                const _id = useForm((state) => state.formObject['_id']);

                const setFormObject = useForm((state) => state.setFormObject);
                const setFormObjectArray = useForm((state) => state.setFormObjectArray);
                const getFormObject = useForm((state) => state.getFormObject);
                const hotel_room_ids = useForm((state) => state.formObject['hotel_room_ids']);

                const [rooms, setRooms] = useState([]);

                const getAvailableRooms = async () => {
                    if (!date || !end_date) return;

                    const response = await api.customRoute('HotelRooms/action', {
                        action: 'findAvailableRooms',
                        start_date: date,
                        end_date: end_date,
                        old_hotel_reservation_id: _id
                    });

                    setRooms(response.data || []);
                };

                useEffect(() => {
                    getAvailableRooms();
                }, [date, end_date]);

                if (!date || !end_date) return <View>
                    <Text>Please select the check in and check out dates to see the available rooms.</Text>
                </View>;

                return (
                    <View style={{
                        width: '100%'
                    }}>
                        {rooms.length > 0 ? <View style={{
                            width: '100%'
                        }}>
                            <View row spread style={{
                                width: '100%'
                            }}>
                                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                                    width: Platform.OS == 'web' ? '49%' : '100%'
                                }}>
                                    {Label({ label: 'Room Number' })}
                                </View>
                                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                                    width: Platform.OS == 'web' ? '49%' : '100%'
                                }}>
                                    {Label({ label: 'Price' })}
                                </View>
                                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                                    width: Platform.OS == 'web' ? '49%' : '100%'
                                }}>
                                    {Label({ label: '' })}
                                </View>
                            </View>
                            {rooms.map((room, i) => {
                                return (
                                    <View key={i} row spread marginB-10 style={{
                                        width: '100%'
                                    }}>
                                        <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                                            width: Platform.OS == 'web' ? '49%' : '100%'
                                        }}>
                                            <Text>{room.name}</Text>
                                        </View>
                                        <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                                            width: Platform.OS == 'web' ? '49%' : '100%'
                                        }}>
                                            <Text>{room.price}</Text>
                                        </View>
                                        <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                                            width: Platform.OS == 'web' ? '49%' : '100%'
                                        }}>
                                            <CustomCheckbox
                                                value={(hotel_room_ids || [])?.includes(room._id)}
                                                onChange={(value) => {
                                                    const new_hotel_room_ids = [...(hotel_room_ids || [])];

                                                    if (value) {
                                                        new_hotel_room_ids.push(room._id);
                                                    } else {
                                                        const index = new_hotel_room_ids.indexOf(room._id);
                                                        if (index > -1) {
                                                            new_hotel_room_ids.splice(index, 1);
                                                        }
                                                    }

                                                    setFormObject({
                                                        hotel_room_ids: new_hotel_room_ids
                                                    })
                                                }} />
                                        </View>
                                    </View>
                                );
                            })}
                        </View> : <View>
                            <Text>No rooms available for the selected dates.</Text>
                        </View>}
                    </View>
                );
            }
        }

    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const hotelReservationMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const hotelReservationMain = {
    fields: hotelReservationfields,
    mobileCard: null,
    customActions: (moduleName, navigationFn, route, row, setOpenModal, openCustomModal) => {
        let options = [

        ]

        options.push({
            label: 'Generate Invoice',
            key: 'generate_invoice',
            fn: (props) => {
                if (Platform.OS === 'web') {
                    setOpenModal({
                        ["Invoices"]: {
                            isVisible: true,
                            moduleName: "Invoices",
                            afterSaveEvent: 'reloadListing',
                            is_default_values: true,
                            contact_id: row.contact_id
                        }
                    })
                } else {
                    navigationFn('New', {
                        moduleName: "Invoices",
                        goBackAfterSave: true,
                        is_default_values: true,
                        contact_id: row.contact_id
                    });
                }
            }
        })

        options.push({
            label: 'Order Food',
            key: 'order_food',
            fn: (props) => {
                navigationFn('POS', {

                });
            }
        })

        options = [
            ...options.map((item) => {
                return { ...item, ignore_permission: true }
            }),
        ]

        return options;
    },
}