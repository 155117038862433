"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _RootStore = require("./RootStore");
Object.keys(_RootStore).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _RootStore[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _RootStore[key];
    }
  });
});
var _getRootStore = require("../../models/helpers/getRootStore");
Object.keys(_getRootStore).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getRootStore[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getRootStore[key];
    }
  });
});
var _useStores = require("../../models/helpers/useStores");
Object.keys(_useStores).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useStores[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useStores[key];
    }
  });
});
var _setupRootStore = require("../../models/helpers/setupRootStore");
Object.keys(_setupRootStore).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _setupRootStore[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _setupRootStore[key];
    }
  });
});