"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalStoreModel = exports.GlobalModalStore = void 0;
var _mobxStateTree = require("mobx-state-tree");
var _reactNative = require("react-native");
var _zustand = require("zustand");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var ModalStoreModel = exports.ModalStoreModel = _mobxStateTree.types.model("ModalStore").props({
  openModals: _mobxStateTree.types.optional(_mobxStateTree.types.frozen(), {}),
  modalHistory: _mobxStateTree.types.optional(_mobxStateTree.types.array(_mobxStateTree.types.string), []),
  openSomething: _mobxStateTree.types.optional(_mobxStateTree.types.frozen(), {})
}).views(function (store) {
  return {
    get getOpenModals() {
      return store.openModals;
    },
    get getModalHistory() {
      return store.modalHistory;
    },
    get getOpenSomething() {
      return store.openSomething;
    }
  };
}).actions(function (store) {
  return {
    setOpenModal: function setOpenModal(value) {
      store.openModals = _objectSpread(_objectSpread({}, store.openModals), value);
      // const oldHistory = store.modalHistory;
      // const moduleName = Object.keys(value)[0]

      // const moduleData = value[moduleName];

      // if (moduleData == null) {
      //   const index = oldHistory.indexOf(moduleName);
      //   if (index > -1) {
      //     oldHistory.splice(index, 1);
      //   }
      //   oldHistory.replace([...new Set(oldHistory)]);
      //   store.modalHistory = oldHistory;
      //   return;
      // }

      // const index = oldHistory.indexOf(moduleName);
      // if (index > -1) {
      //   oldHistory.splice(index, 1);
      // }

      // oldHistory.push(moduleName);

      // if (oldHistory.length > 10) {
      //   oldHistory.shift()
      // }

      // oldHistory.replace([...new Set(oldHistory)]);

      // store.modalHistory = oldHistory;
    },
    closeAllModals: function closeAllModals() {
      store.openModals = {};
    },
    setOpenSomething: function setOpenSomething(value) {
      store.openSomething = _objectSpread({}, value);
    }
  };
});
var GlobalModalStore = exports.GlobalModalStore = (0, _zustand.create)(function (set, get) {
  return {
    openModals: {},
    modalHistory: [],
    openSomething: {},
    setOpenModal: function setOpenModal(value) {
      set(function (state) {
        var oldHistory = _toConsumableArray(state.modalHistory);
        var moduleName = Object.keys(value)[0];
        var moduleData = value[moduleName];
        if (moduleData == null) {
          var _index = oldHistory.indexOf(moduleName);
          if (_index > -1) {
            oldHistory.splice(_index, 1);
          }
          oldHistory = _toConsumableArray(new Set(oldHistory));
          return {
            openModals: _objectSpread(_objectSpread({}, state.openModals), value),
            modalHistory: oldHistory
          };
        }
        var index = oldHistory.indexOf(moduleName);
        if (index > -1) {
          oldHistory.splice(index, 1);
        }
        oldHistory.push(moduleName);
        if (oldHistory.length > 10) {
          oldHistory.shift();
        }
        oldHistory = _toConsumableArray(new Set(oldHistory));
        var newOpenModals = _objectSpread(_objectSpread({}, state.openModals), value);
        return {
          openModals: newOpenModals,
          modalHistory: oldHistory
        };
      });
    }
  };
});