import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const areafields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '25%'
        },
        {
            label: 'Area Managers',
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Select Table Managers',
            visible: true,
            width: '25%',
            options: response.Employees,
            multiple: true,
        },
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const areaMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const areaMain = {
    fields: areafields,
    mobileCard: null
}