// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { QueryBuilder } from 'react-querybuilder';
import { QueryBuilderNative } from '@react-querybuilder/native';
import { Icon } from "react-native-eva-icons"
import { GlobalModalStore } from "../../../../../mycomponents/state/ModalStore"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"



const FieldSelector = (props) => {
    const options = props.options;
    return (
        <CustomSelect
            placeholder="Select a field"
            value={props.value}
            onChange={(value) => {
                props.handleOnChange(value);
            }}
            options={options}
        />
    );
};

const operatorSelector = (props) => {
    const options = props.options;

    return (
        <CustomSelect
            placeholder="Select an operator"
            value={props.value}
            onChange={(value) => {
                props.handleOnChange(value);
            }}
            options={options}
        />
    );
}


const OrganizationChartScreen = observer(function OrganizationChartScreen(props) {
    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const permissions = GlobalMasterStore((state) => state.permissions);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };


    useEffect(() => {

        api.customRoute("Employees/action", {
            action: "GetTeam",
            employee_id: "64958309a6583d596b2e518a"
        })

        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        const script3 = document.createElement('script');

        script1.src = 'https://d3js.org/d3.v7.min.js';

        script1.onload = function () {
            // d3 is now loaded and available

            script2.src = 'https://cdn.jsdelivr.net/npm/d3-org-chart@3.0.1-next.1';

            script2.onload = function () {
                // d3-org-chart is now loaded

                script3.src = 'https://cdn.jsdelivr.net/npm/d3-flextree@2.1.2/build/d3-flextree.js';

                script3.onload = function () {
                    var chart = null;

                    api.customRoute("Employees/action", {
                        action: "OrganizationChart",
                    }).then((response) => {

                        chart = new d3.OrgChart()
                            .nodeHeight((d) => 85 + 25)
                            .nodeWidth((d) => 220 + 2)
                            .childrenMargin((d) => 50)
                            .compactMarginBetween((d) => 35)
                            .compactMarginPair((d) => 30)
                            .neighbourMargin((a, b) => 20)
                            .nodeContent(function (d, i, arr, state) {
                                const color = '#FFFFFF';
                                const imageDiffVert = 25 + 2;
                                return '<div style="width:' + d.width + 'px;height:' + d.height + 'px;padding-top:' + (imageDiffVert - 2) + 'px;padding-left:1px;padding-right:1px">' +
                                    '<div style="font-family: \'Inter\', sans-serif;background-color:' + color + ';margin-left:-1px;width:' + (d.width - 2) + 'px;height:' + (d.height - imageDiffVert) + 'px;border-radius:10px;border:' + (d.data._highlighted || d.data._upToTheRootHighlighted ? '5px solid #E27396' : '1px solid #E4E2E9') + '">' +
                                    '<div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px">#' + d.data.id + '</div>' +
                                    '<div style="background-color:' + color + ';margin-top:' + (-imageDiffVert - 20) + 'px;margin-left:' + 15 + 'px;border-radius:100px;width:50px;height:50px;"></div>' +
                                    '<div style="margin-top:' + (-imageDiffVert - 20) + 'px;"><img src="' + d.data.image + '" style="margin-left:' + 20 + 'px;border-radius:100px;width:40px;height:40px;" /></div>' +
                                    '<div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px">' + d.data.name + '</div>' +
                                    '<div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;">' + d.data.position + '</div>' +
                                    '</div>' +
                                    '</div>';
                            })
                            .container('.chart-container')
                            .data(response.data)
                            .linkGroupArc(function linkValue({ source, target }) {
                                const linkGenerationFunc = d3
                                    .linkHorizontal()
                                    .x((d) => d.x)
                                    .y((d) => d.y);

                                return linkGenerationFunc({
                                    source: { x: source.x, y: source.y + 30 },
                                    target: target,
                                });
                            })
                            .render();

                        chart.connections([{ from: "64e32531591babb5d4d573cc", to: "64cbcbc920f41ebf08847e1b", label: "Also Reports To" }]).render()
                    })

                };

                document.body.appendChild(script3);

            };

            document.body.appendChild(script2);

        };

        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
        };
    }, []);

    return (
        <LayoutScreen>
            <View flex>
                <ScrollView>
                    <Card margin-20>
                        <View padding-15 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                            <View spread row>
                                <Text h1>Organization Chart</Text>
                            </View>
                        </View>

                        <div className="chart-container"></div>

                    </Card>
                </ScrollView>
            </View>
        </LayoutScreen>
    )
})

export default OrganizationChartScreen

const $container = {
    flex: 1,
}