import {
    DarkTheme,
    DefaultTheme,
    NavigationContainer,
    useNavigation,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { StackScreenProps, createStackNavigator } from "@react-navigation/stack"
import { observer } from "mobx-react-lite"
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { ActivityIndicator, DeviceEventEmitter, Dimensions, SafeAreaView, StatusBar, useColorScheme } from "react-native"
import Config from "./config"
import { RootStoreProvider, useStores } from "./mycomponents/state"
import ListingScreen from "./mycomponents/CRUD/ListingScreen"
import FormScreen from "./mycomponents/CRUD/FormScreen"
import EditScreen from "./mycomponents/CRUD/EditScreen"
import { View, Text, Card, TouchableOpacity, Image } from "react-native-ui-lib"
import ModalScreen from "./screens/MainScreens/ModalScreen"
import { Platform } from 'react-native';
import { BrowserRouter as RouterWeb, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MobileDrawer from "./components/MobileDrawer"
import { api, collectionsNames } from "./services/api"
import ReportScreen from "./mycomponents/CRUD/ReportScreen"
import ReportScreenSingle from "./mycomponents/CRUD/ReportScreenSingle"
import HomeScreen from "./screens/MainScreens/Custom/Internal_Pages/main/HomeScreen"
import PosScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/PosScreen"
import AccountingReports from "./screens/MainScreens/Custom/Internal_Pages/accounting/AccountingReports"
import OrganizationSettings from "./screens/MainScreens/Custom/Internal_Pages/main/OrganizationSettings"
import LoginScreen from "./screens/MainScreens/Custom/Internal_Pages/main/LoginScreen"
import ApprovalRequestScreen from "./screens/MainScreens/Custom/Internal_Pages/main/ApprovalRequests"
import { App, notification } from "antd"
import BarcodePrintingScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/BarcodePrintingScreen"
import ItemTracingScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/ItemTracingScreen"
import { GlobalMasterStore } from "./mycomponents/state/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import OrganizationChartScreen from "./screens/MainScreens/Custom/Internal_Pages/hrms/OrganizationChart"
import CustomModalProvider from "./mycomponents/CustomModalProvider"
import FaceAuth from "./screens/MainScreens/Custom/Internal_Pages/main/FaceAuth/FaceAuth"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import Home from "./screens/MainScreens/Custom/External_Pages/eComm/Home"
import Shop from "./screens/MainScreens/Custom/External_Pages/eComm/Shop"
import Product from "./screens/MainScreens/Custom/External_Pages/eComm/Product"
import LoginPhoneScreen from "./screens/MainScreens/Custom/Internal_Pages/main/LoginPhoneScreen"
import Checkout from "./screens/MainScreens/Custom/External_Pages/eComm/Checkout"
import { AutocompleteDropdownContextProvider } from 'react-native-autocomplete-dropdown'
import { Sidebar } from "./components/SideBar"
import ThankYou from "./screens/MainScreens/Custom/External_Pages/eComm/ThankYou"
import BookAppointment from "./screens/MainScreens/Custom/External_Pages/eComm/BookAppointment"
import TermsAndConditions from "./screens/MainScreens/Custom/External_Pages/eComm/TermsAndConditions"
import CancellationRefundPolicy from "./screens/MainScreens/Custom/External_Pages/eComm/CancellationRefundPolicy"
import ContactUs from "./screens/MainScreens/Custom/External_Pages/eComm/ContactUs"
import PrivacyPolicy from "./screens/MainScreens/Custom/External_Pages/eComm/PrivacyPolicy"
import ShippingDeliveryPolicy from "./screens/MainScreens/Custom/External_Pages/eComm/ShippingDeliveryPolicy"
import AboutUs from "./screens/MainScreens/Custom/External_Pages/eComm/AboutUs"
import { GlobalLoaderStore } from "./mycomponents/state/GlobalLoaderStore";
import TableScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/TableScreen"
import InKitchenScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/InKitchenScreen"
import AssetsScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/AssetsScreen"
import ResturantScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/RoomScreen"
import RoomScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/RoomScreen"
import * as Network from 'expo-network';
import { ToastProvider } from 'react-native-toast-notifications'
import { Realtime } from 'ably';

const exitRoutes = Config.exitRoutes

const Stack = createNativeStackNavigator()

const GlobalErrors = () => {
    const { message } = App.useApp();

    const showError = (error) => {

    }

    const setOrg = async () => {
        if (Platform.OS == 'web') {
            const urlParams = new URLSearchParams(window.location.search);
            const token = await AsyncStorage.getItem("token");
            if (urlParams.get("faizan_org123") && token) {
                api.setTokenToAPI(token, urlParams.get("faizan_org123"));
            }
        }
    }

    useEffect(() => {
        api.addErrorInterceptor(showError);
        //get query params 
        setOrg();
    }, [])

    return <></>;
};

const GlobalLoading = (props) => {
    const loading = GlobalLoaderStore((state) => state.loading);

    return loading ? <View style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999999999,
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <ActivityIndicator size="large" color="#fff" />
    </View> : null;
}

const Context = React.createContext({ name: 'Default' });

const AppStack = observer(function AppStack() {
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);
    //const employee = GlobalMasterStore((state) => state.employee);
    const setEmployee = GlobalMasterStore((state) => state.setEmployee);
    const setLocations = GlobalMasterStore((state) => state.setLocations);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const location_id = GlobalMasterStore((state) => state.globalData.location_id);
    const last_updated_at = GlobalMasterStore((state) => state.globalData.last_updated_at);
    const token = GlobalMasterStore((state) => state.token);
    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);
    const employee_id = GlobalMasterStore((state) => state.globalData.employee_id);
    const intervalRef = React.useRef(null);
    const interval2Ref = React.useRef(null);

    const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

    if (getSideBarPosition != 'top') {
        setSideBarPosition('top');
    }

    const [api2, contextHolder] = notification.useNotification();


    const [darkBg, setDarkBg] = useState(false);

    const myReloadFn = () => {
        window.location.reload();
    }

    useLayoutEffect(() => {
        DeviceEventEmitter.addListener('changeDarkBg', (data) => {
            setDarkBg(true);
        });

        if (Platform.OS == 'web') {
            const Mousetrap = require('mousetrap');

            Mousetrap.bind('esc esc esc esc esc', () => {
                myReloadFn()
            });

            Mousetrap.prototype.stopCallback = function (e, element, combo) {
                return false;
            }

            const $ = require('jquery');

            $("body").on("keydown", "input, textarea, select, button", function (e) {
                try {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        var self = $(this);
                        if (self.attr("aria-label") == "Primary Button") {
                            self.click();
                            return false;
                        }
                        var body = self.parents("body:eq(0)");
                        setTimeout(() => {
                            var focusable = body.find(`input,a,select,button,textarea,[aria-label="Primary Button"]`)
                            var ariaL = body.find(`[aria-label="Primary Button"]`)
                            if (self.attr("id")) {
                                self = $(`#${self.attr("id")}`);
                            }
                            var next = focusable.eq(focusable.index(self) + 1);
                            if (next.attr("aria-hidden") == "true") {
                                focusable.eq(0).focus();
                            } else if (next.attr("aria-label") == "Primary Button") {
                                next.click();
                            } else if (next.length) {
                                next.focus();
                            }
                        }, 0);
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
    }, []);

    useEffect(() => {
        const getIp = async () => {
            try {
                const ip = await Network.getIpAddressAsync();
                console.log(ip);
            } catch (e) {
                console.log(e);
            }
        }

        getIp();
    }, [])

    const getPermission = async () => {
        const res = await api.getPermissions({
            organization_id: organization_id,
            employee_id: employee_id
        })

        console.log(res.last_updated_at);

        // if (res.last_updated_at != last_updated_at || !last_updated_at || !res.permissions || res.permissions.length == 0 || !res.employee || !res.employee._id) {
        console.log("Permissions Updated");

        if (res.locations && res.locations.length > 0) {
            const locations = res.locations.map((location) => {
                return {
                    "_id": location._id,
                    "name": location.name,
                    "address": location.address,
                    "latitude": location.latitude,
                    "longitude": location.longitude,
                }
            })

            setLocations(locations)

            if (!location_id || !locations.find((location) => location._id == location_id)) {
                setGlobalData({ location_id: locations[0]?._id });
            }
        }

        const employee = {
            _id: res.employee?._id,
            name: res.employee?.name,
            email: res.employee?.email,
            contact_number: res.employee?.contact_number,
            user_id: res.employee?.user_id,
            role_id: res.employee?.role_id,
            employee_code: res.employee?.employee_code,
            account_id: res.employee?.account_id,
            image: res.employee?.image,
            enable_face_auth: res.employee?.enable_face_auth,
            face_auth_image_url: res.employee?.face_auth_image_url
        }

        setPermissions(res.permissions)
        setEmployee(employee)

        setGlobalData({ last_updated_at: res.last_updated_at });
        // }
    };

    const syncOfflineData = async () => {
        if (organization_id) {
            await api.customRoute("sync-online-offline-data", {
                org_id: organization_id,
            });
        }
    }

    useEffect(() => {
        getPermission();

        if (Platform.OS != 'web') {
            intervalRef.current = setInterval(() => {
                getPermission();
            }, 20000)
        }

        if (Platform.OS == 'web') {
            if (window.location.href.includes("3220")) {
                interval2Ref.current = setInterval(() => {
                    syncOfflineData();
                }, 15 * 60 * 1000)
            }
        }

        console.log("Organization ID", organization_id);

        // const ably = new Realtime({ key: 'x9wJ8g.wQTA_Q:VhLrbtIzPb8K_KMg6jMsFk1B2CUG2566pC8rAxkJVY4' });
        // const channel = ably.channels.get('orders-' + organization_id);

        // channel.subscribe('new-order', (message) => {
        //     api2.warning({
        //         message: `Notification`,
        //         description: `New Order Received for Table - ${message.data.table_number}`,
        //         placement: 'bottomRight',
        //         duration: 0,
        //     });
        // });

        return () => {
            // if (Platform.OS != 'web') {
            clearInterval(intervalRef.current);
            clearInterval(interval2Ref.current);
            // channel.unsubscribe();
            // }
        }
    }, [organization_id, employee_id, token]);

    const routes = React.useMemo(() => {
        return collectionsNames.map(collection => (
            <Route key={collection.name} path={`/${collection.name}`} Component={props => (
                <ListingScreen {...props} />
            )} />
        ))
    }, [collectionsNames]);

    const eCommHostnames = ["ikaki.inkapps.io", "ikaki.co.in", "www.ikaki.co.in"];

    const CheckAndRenderSideBarCardComponent = () => {
        if (eCommHostnames.includes(window.location.hostname)) {
            if (window.location.pathname.includes("/shop/") || window.location.pathname == "/") {
                return null;
            }
        }

        if (token) {
            return <SideBarCardComponent />;
        } else {
            return null;
        }
    };

    const titleCheckInterval = React.useRef(null);

    useEffect(() => {
        if (Platform.OS == 'web') {
            titleCheckInterval.current = setInterval(() => {
                if (document.title != "Success Suite") {
                    document.title = "Success Suite";
                    clearInterval(titleCheckInterval.current);
                }
            }, 50);
        }
    }, []);

    const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

    const MainApp = <View
        key={location_id || "default"}
        useSafeArea style={{
            flex: 1,
            paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
            position: 'relative',
        }}>

        <View style={{
            flex: 1,
            backgroundColor: darkBg ? '#242424' : '#f5f7f9',
            zIndex: 2
        }}>
            {Platform.OS == 'web' ? <RouterWeb>
                <App>
                    <View style={{
                        backgroundColor: darkBg ? '#242424' : '#f5f7f9',
                    }}>
                        <GlobalErrors />
                        <ModalScreen />

                        <View flex row={Platform.OS == 'web' && getSideBarPosition == 'left'} style={{
                            height: Dimensions.get('window').height - (getSideBarPosition == 'top' ? 0 : 0),
                            minHeight: Dimensions.get('window').height - (getSideBarPosition == 'top' ? 0 : 0),
                            maxHeight: Dimensions.get('window').height - (getSideBarPosition == 'top' ? 0 : 0),
                        }}>

                            <CheckAndRenderSideBarCardComponent />

                            <Routes>
                                <Route path="/home" element={<Home />} />
                                <Route path="/shop/product/:slug/:id" element={<Product />} />
                                <Route path="/shop/products" element={<Shop />} />
                                <Route path="/shop/checkout" element={<Checkout />} />
                                <Route path="/shop/thank-you" element={<ThankYou />} />

                                <Route path="/shop/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
                                <Route path="/shop/contact-us" element={<ContactUs />} />
                                <Route path="/shop/about-us" element={<AboutUs />} />
                                <Route path="/shop/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/shop/shipping-delivery-policy" element={<ShippingDeliveryPolicy />} />
                                <Route path="/shop/terms-and-conditions" element={<TermsAndConditions />} />

                                {token ? (<>

                                    <Route path="/dashboard" element={<HomeScreen />} />
                                    <Route path="/faceAuth" element={<FaceAuth />} />
                                    <Route path="/Assets" element={<AssetsScreen />} />
                                    <Route path="/pos" element={<PosScreen />} />
                                    <Route path="/table" element={<TableScreen />} />
                                    <Route path="/kitchen" element={<InKitchenScreen />} />
                                    <Route path="/room" element={<RoomScreen />} />
                                    <Route path={"AccountingReports"} element={<AccountingReports />} />
                                    <Route path={"HRMSReports"} element={<AccountingReports />} />
                                    <Route path={"TaskReports"} element={<AccountingReports />} />
                                    <Route path={"OrganizationSettings"} element={<OrganizationSettings />} />

                                    <Route path="/ApprovalRequests" element={<ApprovalRequestScreen />} />
                                    <Route path="/BarcodePrinting" element={<BarcodePrintingScreen />} />
                                    <Route path="/ItemTracing" element={<ItemTracingScreen />} />
                                    <Route path="/OrganizationChart" element={<OrganizationChartScreen />} />

                                    {routes}

                                    <Route path={"report/:moduleName"} element={<ReportScreenSingle />} />

                                    <Route path="/New/:moduleName" element={<EditScreen />} />
                                    <Route path="/Edit/:moduleName" element={<EditScreen />} />

                                    <Route path="*" element={eCommHostnames.includes(window.location.hostname) ? <Home /> : <HomeScreen />} />
                                </>) : (<>
                                    <Route path="/" element={eCommHostnames.includes(window.location.hostname) ? <Home /> : <LoginPhoneScreen />} />
                                    <Route path="/login" element={<LoginPhoneScreen />} />
                                    <Route path="/loginWithEmail" element={<LoginScreen />} />

                                    <Route path="*" element={<LoginPhoneScreen />} />
                                </>)}
                            </Routes>
                        </View>
                    </View>
                </App>
            </RouterWeb> :
                <>
                    <GlobalErrors />
                    {token ? (<MobileDrawer darkBg={darkBg} />) : null}
                    {/* <ModalScreen /> */}
                    <Stack.Navigator
                        screenOptions={{ headerShown: false, gestureEnabled: false }}
                        initialRouteName={token ? "dashboard" : "login"} // @demo remove-current-line
                    >
                        {token ? (
                            <>
                                <Stack.Screen name="dashboard" component={HomeScreen} />
                                <Stack.Screen name="faceAuth" component={FaceAuth} />
                                <Stack.Screen name="POS" component={PosScreen} />
                                <Stack.Screen name="/Assets" component={AssetsScreen} />
                                <Stack.Screen name="table" component={TableScreen} />
                                <Stack.Screen name="kitchen" component={InKitchenScreen} />
                                <Stack.Screen name="room" component={RoomScreen} />

                                <Stack.Screen name="ItemTracing" component={ItemTracingScreen} />

                                {collectionsNames.map((collection, key) => {
                                    return <Stack.Screen key={key.toString()} name={collection.name} component={ListingScreen} />
                                })}

                                <Stack.Screen name={"AccountingReports"} component={AccountingReports} />
                                <Stack.Screen name="New" component={FormScreen} />

                                {/* <Route path={"report/:moduleName"} element={<ReportScreenSingle />} /> */}
                                <Stack.Screen name={"report"} component={ReportScreenSingle} />
                            </>
                        ) : (
                            <>
                                <Stack.Screen name="login" component={LoginPhoneScreen} />
                                <Stack.Screen name="loginWithEmail" component={LoginScreen} />
                            </>
                        )}
                    </Stack.Navigator>
                </>}

        </View>
        <GlobalLoading />

    </View>

    return Platform.OS == 'web' ? <Context.Provider value={contextValue}>
        {contextHolder}
        <ToastProvider>
            <CustomModalProvider>
                <AutocompleteDropdownContextProvider>
                    {MainApp}
                </AutocompleteDropdownContextProvider>
            </CustomModalProvider>
        </ToastProvider>
    </Context.Provider> : <ToastProvider>
        <CustomModalProvider>
            <AutocompleteDropdownContextProvider>
                {MainApp}
            </AutocompleteDropdownContextProvider>
        </CustomModalProvider>
    </ToastProvider>;
})

const SideBarCardComponent = (props) => {
    const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

    let location = useLocation();
    const noDashboardPagesWeb = ["/home", "/shop/", "/login", "/loginWithEmail", "/faceAuth"];

    const [showSideBar, setShowSideBar] = useState(noDashboardPagesWeb.find((page) => location.pathname?.toLowerCase()?.includes(page?.toLowerCase())));

    useEffect(() => {
        if (Platform.OS == 'web') {
            if (noDashboardPagesWeb.find((page) => location.pathname?.toLowerCase()?.includes(page?.toLowerCase()))) {
                setShowSideBar(false);
            } else {
                setShowSideBar(true);
            }
        }
    }, [location]);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    return showSideBar ? <div style={{
        overflow: 'hidden',
        minHeight: getSideBarPosition == 'left' ? Dimensions.get('window').height - 40 : '50px',
    }}>
        <Card row={getSideBarPosition == 'top'} flex center={getSideBarPosition == 'top'} style={{
            width: isSideBarCollapsed ? 60 : getSideBarPosition == 'left' ? 250 : '100%',
            minWidth: isSideBarCollapsed ? 60 : getSideBarPosition == 'left' ? 250 : '100%',
            maxWidth: isSideBarCollapsed ? 60 : getSideBarPosition == 'left' ? 250 : '100%',
            borderRadius: getSideBarPosition == 'left' ? 10 : 0,
            margin: getSideBarPosition == 'left' ? 20 : 0,
            height: getSideBarPosition == 'left' ? Dimensions.get('window').height - 40 : '50px',
            minHeight: getSideBarPosition == 'left' ? Dimensions.get('window').height - 40 : '50px',
            maxHeight: getSideBarPosition == 'left' ? Dimensions.get('window').height - 40 : '50px',
            marginRight: 0,
            backgroundColor: getSideBarPosition == 'top' ? '#0a152f' : '#fff',
        }}>
            <View row marginH-20 style={{ minHeight: 26, marginTop: getSideBarPosition == 'left' ? 20 : 0 }}>
                {getSideBarPosition == 'left' && <TouchableOpacity onPress={() => {
                    toggleSideBarCollapsed();
                }}>
                    <Image source={{ uri: require('../assets/icons/menu.png') }} style={{ width: 20, height: 20, }} />
                </TouchableOpacity>}

                {!isSideBarCollapsed && <View flex centerV marginL-10>
                    <TouchableOpacity onPress={() => {
                        navigationFn("dashboard", {});
                    }}>
                        <Image source={{ uri: require('../assets/successSuitelogo2.png') }} style={{ width: 180, height: 32 }} resizeMode={'contain'} />
                    </TouchableOpacity>
                </View>}
            </View>

            <Sidebar />
        </Card>
    </div> : null;
}

export const AppNavigator = observer(function AppNavigator(props) {
    const colorScheme = useColorScheme()

    return (
        <NavigationContainer
            theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
            {...props}
        >
            <AppStack />
        </NavigationContainer>
    )
})
