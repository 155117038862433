import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

// webinar_id: { type: Types.ObjectId, ref: 'Webinars', required: true },
// webinar: { type: mongoose.Schema.Types.Mixed, default: {} },
// webinar_registration_id: { type: Number, required: false },
// webinar_slug: { type: String, required: false },
// name: { type: String, required: false },
// email: { type: String, required: false },
// institute: { type: String, required: false },
// designation: { type: String, required: false },
// phone: { type: String, required: false },
// billing_city: { type: String, required: false },
// billing_state: { type: String, required: false },
// billing_country: { type: String, required: false },
// date: { type: Date, required: false },

export const webinarRegistrationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Webinar',
            key: 'webinar_id',
            type: 'select',
            placeholder: 'Webinar',
            visible: true,
            width: '25%',
            tab: 'General',
            options: response.Webinars,
            optionLabel: 'name',
            optionValue: '_id',
            disabled: true
        },
        {
            label: 'Date of Registration',
            key: 'date',
            type: 'date',
            placeholder: 'Date',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Email',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Institute',
            key: 'institute',
            type: 'text',
            placeholder: 'Institute',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Designation',
            key: 'designation',
            type: 'text',
            placeholder: 'Designation',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Phone',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing City',
            key: 'billing_city',
            type: 'text',
            placeholder: 'Billing City',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing State',
            key: 'billing_state',
            type: 'text',
            placeholder: 'Billing State',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing Country',
            key: 'billing_country',
            type: 'text',
            placeholder: 'Billing Country',
            visible: true,
            width: '25%',
            tab: 'General',
        }
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const webinarRegistrationMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const webinarRegistrationMain = {
    fields: webinarRegistrationfields,
    mobileCard: null
}