// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState, ActivityIndicator, useContext } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, Alert, StyleSheet, DeviceEventEmitter } from "react-native"

import { api } from "../../services/api"
import { colors, spacing } from "../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Fader, Image, Button, TabController, FloatingButton, TouchableOpacity, Dialog, PanningProvider, Chip, ActionSheet } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomSelect, CustomTabsForModule, CustomTextField, CustomTextFieldWithScanner, DangerButton, FiltersComponent, PrimaryButton, SecondaryButton } from "../../mycomponents/DynamicForm"
import DynamicTable from "../../mycomponents/DynamicTable"
import { useNavigation } from '@react-navigation/native';
import Svg, { Path, G, Circle } from "react-native-svg";
import { LinearGradient } from 'expo-linear-gradient'
import { BoxShadow } from 'react-native-shadow'
import { useStores } from "../state";
import { useMatch, useNavigate, useRoutes } from "react-router-dom";
import { App, DatePicker, Dropdown } from "antd"
import { Icon } from "react-native-eva-icons"
import Calendar from '@toast-ui/react-calendar';
import { TZDate } from '@toast-ui/calendar';
import { GlobalModalStore } from "../state/ModalStore"
import { GlobalMasterStore } from "../state/PermissionStore"
import { CustomModalContext } from "../../mycomponents/CustomModalProvider"
import { useRef } from "react"
import AsyncStorage from "@react-native-async-storage/async-storage"
import moment from "moment"
import dayjs from "dayjs"

const CalendarComponent = (props) => {
  let navigation = useNavigation();
  const { message, modal } = App.useApp();

  let route = props?.route;
  const { openCustomModal } = useContext(CustomModalContext);
  const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
  const [reloadKey, setReloadKey] = useState(0);

  let navigate = null;
  let queryParams = { ...(props?.route?.params || {}) };

  if (Platform.OS === 'web') {
    navigate = useNavigate();
    let path = window?.location?.pathname;
    route = { name: path.split("/")[1] || 'Items' }

    const params = window.location.search.substring(1).split('&')
    params.map((param) => {
      const [key, value] = param.split('=')
      if (key) {
        queryParams[key] = value;
      }
    })
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === 'web') {
      let paramsString = "";
      if (params) {
        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
      navigate("/" + path + paramsString);
    } else {
      if (path.includes("report/")) {
        navigation.push("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.push(path, params);
      }
    }
  };

  const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
    DeviceEventEmitter.addListener("reloadCalendar", (event) => {
      if (event) {
        console.log("reloadCalendar");
        setReloadKey(Math.random());
        DeviceEventEmitter.removeAllListeners("reloadCalendar");
      }
    })

    let data = {
      [newModule]: {
        isVisible: true,
        moduleName: [newModule],
        viewOnly: false,
        afterSaveEvent: 'reloadCalendar',
        ignoreQueryParams: true,
        ...(params ? params : {})
      }
    };

    setOpenModal(data)
  }

  const viewModeOptions = [
    {
      title: 'Monthly',
      value: 'month',
    },
    {
      title: 'Weekly',
      value: 'week',
    },
    {
      title: 'Daily',
      value: 'day',
    },
  ];

  const addDate = (old, d) => {
    const newDate = new TZDate(old);
    newDate.setDate(newDate.getDate() + d);
    return newDate;
  }

  const calendarRef = React.useRef(null);
  const [selectedDateRangeText, setSelectedDateRangeText] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(addDate(null));
  const [selectedView, setSelectedView] = useState('week');

  const initialCalendars = [
    {
      id: '0',
      name: 'Meetings',
      backgroundColor: '#9e5fff',
      borderColor: '#9e5fff',
      dragBackgroundColor: '#9e5fff',
    },
    {
      id: '1',
      name: 'Courses',
      backgroundColor: '#00a9ff',
      borderColor: '#00a9ff',
      dragBackgroundColor: '#00a9ff',
    },
  ];

  const [initialEvents, setInitialEvents] = useState([]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      props.getEvents({
        start: moment(selectedStartDate).subtract(7, 'days').toDate(),
        end: moment(selectedEndDate).add(7, 'days').toDate(),
      }).then((res) => {

        const events = res.map((event) => {
          return {
            ...event,
            start: new TZDate(new Date(event.start)),
            end: new TZDate(new Date(event.end)),
          }
        });

        setInitialEvents(events);
      })
    }
  }, [selectedStartDate, selectedEndDate, reloadKey]);

  const getCalInstance = useCallback(() => calendarRef.current?.getInstance?.(), []);

  const updateRenderRangeText = useCallback(() => {
    const calInstance = getCalInstance();
    if (!calInstance) {
      setSelectedDateRangeText('');
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      return;
    }

    const viewName = calInstance.getViewName();
    const calDate = calInstance.getDate();
    const rangeStart = calInstance.getDateRangeStart();
    const rangeEnd = calInstance.getDateRangeEnd();

    let year = calDate.getFullYear();
    let month = calDate.getMonth() + 1;
    let date = calDate.getDate();
    let dateRangeText;

    switch (viewName) {
      case 'month': {
        dateRangeText = `${year}-${month}`;
        const startMoment = moment(dateRangeText + '-01');
        dateRangeText = startMoment.format('MMMM YYYY');
        setSelectedStartDate(startMoment.toDate());
        setSelectedEndDate(startMoment.endOf('month').toDate());
        break;
      }
      case 'week': {
        year = rangeStart.getFullYear();
        month = rangeStart.getMonth() + 1;
        date = rangeStart.getDate();

        const endYear = rangeEnd.getFullYear();

        const endMonth = rangeEnd.getMonth() + 1;
        const endDate = rangeEnd.getDate();

        const start = `${year}-${month < 10 ? '0' : ''}${month}-${date < 10 ? '0' : ''}${date}`;
        const end = `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDate < 10 ? '0' : ''
          }${endDate}`;

        // it should be moment.format('DD MMM YYYY')

        // parsing should be based in DD MMMM YY
        const startMoment = moment(start)
        const endMoment = moment(end)

        const startFormat = startMoment.format('DD MMM YYYY')
        const endFormat = endMoment.format('DD MMM YYYY')

        setSelectedStartDate(startMoment.toDate());
        setSelectedEndDate(endMoment.toDate());

        dateRangeText = `${startFormat} ~ ${endFormat}`;
        break;
      }
      default:
        dateRangeText = `${year}-${month}-${date}`;
        const startMoment = moment(dateRangeText)

        setSelectedStartDate(startMoment.startOf('day').toDate());
        setSelectedEndDate(startMoment.endOf('day').toDate());

        dateRangeText = startMoment.format('DD MMM YYYY')
    }

    setSelectedDateRangeText(dateRangeText);
  }, [getCalInstance]);

  useEffect(() => {
    updateRenderRangeText();
  }, [selectedView, updateRenderRangeText]);

  const onAfterRenderEvent = (res) => {
    console.group('onAfterRenderEvent');
    console.groupEnd();
  };

  const onBeforeDeleteEvent = (res) => {
    console.group('onBeforeDeleteEvent');
    console.groupEnd();

    const { id, calendarId } = res;

    getCalInstance().deleteEvent(id, calendarId);
  };

  const onChangeSelect = (ev) => {
    setSelectedView(ev);
  };

  const onClickDayName = (res) => {
    console.group('onClickDayName');
    console.groupEnd();
  };

  const onClickNavi = (ev) => {
    if ((ev.target).tagName === 'BUTTON') {
      const button = ev.target;
      const actionName = (button.getAttribute('data-action') ?? 'month').replace('move-', '');
      getCalInstance()[actionName]();
      updateRenderRangeText();
    }
  };

  const onClickEvent = (res) => {
    const event = res.event;
    console.log('onClickEvent', res);

    const { calendarId, id } = event;

    const clickedEvent = initialEvents.find((event) => event.id === id);

    console.log('clickedEvent', clickedEvent);

    if (clickedEvent?.moduleName) {
      OpenModalCustom({ newModule: clickedEvent.moduleName, params: { id: clickedEvent.id, isEdit: false, viewOnly: true } })
      // OpenModalCustom({ newModule: clickedEvent.moduleName, params: { id: clickedEvent.id, isEdit: true, viewOnly: false } })
    }
  };

  const onClickTimezonesCollapseBtn = (
    timezoneCollapsed
  ) => {
    console.group('onClickTimezonesCollapseBtn');
    console.groupEnd();

    const newTheme = {
      'week.daygridLeft.width': '100px',
      'week.timegridLeft.width': '100px',
    };

    getCalInstance().setTheme(newTheme);
  };

  const onBeforeUpdateEvent = (updateData) => {
    console.group('onBeforeUpdateEvent');

    console.groupEnd();

    const targetEvent = updateData.event;
    const changes = { ...updateData.changes };

    getCalInstance().updateEvent(targetEvent.id, targetEvent.calendarId, changes);
  };

  const onBeforeCreateEvent = (eventData) => {
    const event = {
      id: String(Math.random()),
      calendarId: eventData.calendarId || '',
      title: eventData.title,
      isAllday: eventData.isAllday,
      start: eventData.start,
      end: eventData.end,
      category: eventData.isAllday ? 'allday' : 'time',
      dueDateClass: '',
      location: eventData.location,
      state: eventData.state,
      isPrivate: eventData.isPrivate,
    };

    getCalInstance().createEvents([event]);
  };

  return <>
    <View row spread marginB-10 centerV>
      <View style={{
        width: 200
      }}>
        <CustomSelect
          onChange={onChangeSelect}
          value={selectedView}
          options={viewModeOptions.map((option, index) => ({
            label: option.title,
            value: option.value,
          }))}
        />
      </View>

      <View>
        <Text darkCardTitle>

          {selectedView == 'month' && <DatePicker
            picker="month"
            value={selectedStartDate ? dayjs(selectedStartDate) : null}
            onChange={(date, dateString) => {
              const startDate = date.toDate();
              const endDate = date.endOf('month').toDate();
              setSelectedStartDate(startDate);
              setSelectedEndDate(endDate);

              const calInstance = getCalInstance();
              if (calInstance) {
                calInstance.setDate(startDate);
              }

              setCalendarKey(prevKey => prevKey + 1);
            }}
          />}
          {selectedView == 'week' && <DatePicker
            picker="week"
            value={selectedStartDate ? dayjs(selectedStartDate) : null}
            onChange={(date, dateString) => {
              setSelectedStartDate(date.toDate());
              setSelectedEndDate(date.endOf('week').toDate());

              const calInstance = getCalInstance();
              if (calInstance) {
                calInstance.setDate(date.toDate());
              }

              setCalendarKey(prevKey => prevKey + 1);
            }}
          />}
          {selectedView == 'day' && <DatePicker
            picker="date"
            value={selectedStartDate ? dayjs(selectedStartDate) : null}
            onChange={(date, dateString) => {
              setSelectedStartDate(date.toDate());
              setSelectedEndDate(date.endOf('day').toDate());

              const calInstance = getCalInstance();
              if (calInstance) {
                calInstance.setDate(date.toDate());
              }

              setCalendarKey(prevKey => prevKey + 1);
            }}
          />}
        </Text>
      </View>


      <span>
        <button
          type="button"
          className="btn btn-default btn-sm move-today"
          data-action="move-today"
          onClick={onClickNavi}
        >
          Today
        </button>
        <button
          type="button"
          className="btn btn-default btn-sm move-day"
          data-action="move-prev"
          onClick={onClickNavi}
        >
          Prev
        </button>
        <button
          type="button"
          className="btn btn-default btn-sm move-day"
          data-action="move-next"
          onClick={onClickNavi}
        >
          Next
        </button>
      </span>

      {/* <View row>
                <SecondaryButton
                    style={{
                        marginRight: 10
                    }}
                    onPress={onClickNavi('move-today')}
                >
                    <Text>Today</Text>
                </SecondaryButton>
                <SecondaryButton
                    style={{
                        marginRight: 10
                    }}
                    onPress={onClickNavi('move-prev')}
                >
                    <Text>Prev</Text>
                </SecondaryButton>
                <SecondaryButton
                    onPress={onClickNavi('move-next')}
                >
                    <Text>Next</Text>
                </SecondaryButton>
            </View> */}
    </View>
    <div style={{
      position: 'relative'
    }}>
      <Calendar
        usageStatistics={false}
        key={reloadKey}
        isReadOnly={true}
        height={"calc(100vh - 210px)"}
        calendars={initialCalendars}
        month={{ startDayOfWeek: 1 }}
        eventView={['time']}
        events={initialEvents}
        template={{
          timegridDisplayPrimayTime: function (time) {
            var meridiem = time.hour < 12 ? 'am' : 'pm';

            return time.hour + ' ' + meridiem;
          },
          timegridDisplayTime: function (time) {
            var meridiem = time.hour < 12 ? 'am' : 'pm';

            return time.hour + ' ' + meridiem;
          },
          // time: function (schedule) {
          //     return (

          //     );
          // }
          // milestone(event) {
          //   return `<span style="color: #fff; background-color: ${event.backgroundColor};">${event.title}</span>`;
          // },
          // allday(event) {
          //   return `[All day] ${event.title}`;
          // },
        }}
        useDetailPopup={false}
        useFormPopup={false}
        view={selectedView}
        week={{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: false,
          eventView: true,
          taskView: false,
        }}
        ref={calendarRef}
        onAfterRenderEvent={onAfterRenderEvent}
        onBeforeDeleteEvent={onBeforeDeleteEvent}
        onClickDayname={onClickDayName}
        onClickEvent={onClickEvent}
        onClickTimezonesCollapseBtn={onClickTimezonesCollapseBtn}
        onBeforeUpdateEvent={onBeforeUpdateEvent}
        onBeforeCreateEvent={onBeforeCreateEvent}
      />
    </div>
  </>
};

export default CalendarComponent;