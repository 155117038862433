import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const broadcastNotificationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: "Send Via",
            key: 'send_via',
            type: 'select',
            placeholder: 'Select Send Via',
            visible: true,
            width: '100%',
            required: true,
            tab: 'General',
            options: [
                { label: 'Email', value: 'Email' },
                { label: 'SMS', value: 'SMS' },
                { label: 'Whatsapp', value: 'Whatsapp' },
            ],
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                })
            }
        },

        {
            label: 'Subject',
            key: 'email_subject',
            type: 'text',
            placeholder: 'Subject',
            visible: (connectedObj) => connectedObj.send_via === 'Email',
            width: '100%',
            tab: "General",
        },
        {
            label: 'Body',
            key: 'email_body',
            type: 'editor',
            placeholder: 'Content',
            visible: (connectedObj) => connectedObj.send_via === 'Email',
            width: '100%',
            tab: "General",
        },

        {
            label: 'Message',
            key: 'message',
            type: 'textarea',
            placeholder: 'Content',
            visible: (connectedObj) => connectedObj.send_via === 'SMS' || connectedObj.send_via === 'WhatsApp',
            width: '100%',
            tab: "General",
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const broadcastNotificationMain = {
    fields: broadcastNotificationfields,
    mobileCard: null
}