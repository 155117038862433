import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const moneyTransferfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
            value: new Date()
        },
        {
            label: 'From',
            key: 'from_id',
            type: 'select',
            placeholder: 'Select the Paid From',
            visible: true,
            width: '25%',
            options: response.accounts || [],
            autoFocus: true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const account = response.accounts.find((item) => item.value == value);

                setObj({
                    'from_name': account.label,
                    'from_account_id': account.account_id,
                    'from_employee_id': account.employee_id,
                })
            },
        },
        {
            label: 'To',
            key: 'to_id',
            type: 'select',
            placeholder: 'Select the account',
            visible: true,
            width: '25%',
            options: response.accounts || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const account = response.accounts.find((item) => item.value == value);

                setObj({
                    'to_name': account.label,
                    'to_account_id': account.account_id,
                    'to_employee_id': account.employee_id,
                })
            },
        },
        {
            label: 'Transaction ID',
            key: 'transaction_id',
            type: 'text',
            placeholder: 'Enter the Transaction ID',
            visible: true,
            width: '25%',
        },
        {
            label: 'Amount',
            key: 'amount',
            type: 'number',
            placeholder: 'Enter the amount',
            visible: true,
            width: '25%',
        },
        {
            label: 'Attachment',
            key: 'attachments',
            type: 'upload',
            placeholder: 'Upload the attachment',
            visible: true,
            width: '25%',
            mimeType: 'image/*',
            moduleName: moduleName,
            // multiple: true,
            value: "",
        }, {
            type: 'custom',
            visible: true,
            width: '100%',
            component: ({ field, useForm, tKey, tIndex }) => {
                const employee_id = globalData.employee_id;
                const to_id = useForm((state) => state.formObject.to_id);
                const from_id = useForm((state) => state.formObject.from_id);
                const setFormObject = useForm((state) => state.setFormObject);

                useEffect(() => {
                    const account = response.accounts.find((item) => item.employee_id == globalData.employee_id);

                    if (account) {
                        if (!to_id) {
                            setFormObject({
                                'to_id': account.value,
                                'to_name': account.label,
                                'to_account_id': account.account_id,
                                'to_employee_id': account.employee_id,
                            })
                        }

                        if (!from_id) {
                            setFormObject({
                                'from_id': account.value,
                                'from_name': account.label,
                                'from_account_id': account.account_id,
                                'from_employee_id': account.employee_id,
                            })
                        }
                    }
                }, [employee_id]);

                return <></>;
            }
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const moneyTransferMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const moneyTransferMain = {
    fields: moneyTransferfields,
    mobileCard: null
}
