import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const leadMeetingfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: "Lead",
            key: 'lead_id',
            type: 'select',
            placeholder: 'Select Lead',
            visible: view ? true : false,
            width: '100%',
            required: true,
            tab: 'Lead Meeting Information',
            options: response.Leads ? response.Leads.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: "Agent",
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Select Agent',
            visible: true,
            width: '100%',
            required: true,
            tab: 'Lead Meeting Information',
            multiple: true,
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : []
        },
        {
            label: 'Start Date Time',
            key: 'start_date_time',
            type: 'datetime',
            placeholder: 'Enter Start Date Time',
            visible: true,
            width: '100%',
            required: true,
            tab: 'Lead Meeting Information',
            value: new Date(Math.ceil(Date.now() / (15 * 60 * 1000)) * (15 * 60 * 1000)),
        },

        {
            label: 'Duration',
            key: 'duration',
            type: 'duration',
            duration_options: ["minutes", "hours"],
            placeholder: 'Enter Duration',
            visible: false,
            width: '100%',
            value: 60 * 60 * 1000,
            tab: 'Lead Meeting Information',
        },

        {
            label: 'Meeting Status',
            key: 'meeting_status',
            type: 'select',
            placeholder: 'Select Meeting Status',
            visible: true,
            width: '100%',
            required: true,
            tab: 'Lead Meeting Information',
            options: [
                { label: 'Pending', value: 'Pending' },
                { label: 'Completed', value: 'Completed' },
                { label: 'Client Cancelled', value: 'Client Cancelled' },
                { label: 'Staff Cancelled', value: 'Staff Cancelled' },
                { label: 'Client No Show', value: 'Client No Show' },
            ],
            value: 'Pending',
        },
        {
            label: 'Meeting Type',
            key: 'meeting_type',
            type: 'select',
            placeholder: 'Select Meeting Type',
            visible: true,
            width: '100%',
            required: true,
            tab: 'Lead Meeting Information',
            options: [
                { label: 'In Person', value: 'In Person' },
                { label: 'Online', value: 'Online' },
                { label: 'Phone Call', value: 'Phone Call' },
            ],
            value: 'In Person',
        },
        {
            label: 'Meeting Notes',
            key: 'meeting_notes',
            type: 'editor',
            placeholder: 'Enter Meeting Notes',
            visible: true,
            width: '100%',
            tab: 'Lead Meeting Information',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const leadMeetingMain = {
    fields: leadMeetingfields,
    mobileCard: null
}