import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";

export const webinarfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const RegistrationComponent = ({ field, useForm, tKey, tIndex }) => {
        const webinar_id = useForm((state) => state.formObject['_id']);
        const name = useForm((state) => state.formObject['name']);

        const props = {
            moduleName: 'WebinarRegistrations',
            hideWrapper: true,
            extraFilters: { webinar_id: webinar_id ?? null },
        }

        const title = `Registrations for ${name}`;

        return <View w-100 flex>
            <ListingScreen {...props} title={title} default_values={{
                webinar_id: webinar_id,
                is_default_values: true
            }} />
        </View>
    }


    const formFields = [
        {
            label: "Date",
            key: "date",
            type: "date",
            placeholder: "Date",
            visible: true,
            width: "25%",
            tab: "General",
        },
        {
            label: "Start Time",
            key: "start_time",
            type: "time",
            placeholder: "Start Time",
            visible: true,
            width: "25%",
            tab: "General",
        },
        {
            label: 'End Time',
            key: 'end_time',
            type: 'time',
            placeholder: 'End Time',
            visible: true,
            width: '25%',
            tab: "General",
        },
        {
            label: 'Title',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '75%',
            tab: "General",
        },
        {
            label: 'Registeration Fee',
            key: 'registration_fee',
            type: 'number',
            placeholder: 'Registeration Fee',
            visible: true,
            width: '25%',
            tab: "General",
        },
        {
            label: 'Content',
            key: 'content',
            type: 'editor',
            placeholder: 'Content',
            visible: true,
            width: '100%',
            tab: "General",
        },
        {
            label: 'Speaker Name',
            key: 'speaker',
            type: 'text',
            placeholder: 'Speaker',
            visible: true,
            width: '25%',
            tab: "General",
        },
        {
            label: 'Webinar Link',
            key: 'webinar_link',
            type: 'text',
            placeholder: 'Webinar Link',
            visible: true,
            width: '75%',
            tab: "General",
        },
        {
            label: 'Speaker Image',
            key: 'speaker_image',
            type: 'upload',
            placeholder: 'Speaker Image',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: "Webinars",
            tab: "General",
        },
        {
            label: 'Banner Image',
            key: 'banner_image',
            type: 'upload',
            placeholder: 'Banner Image',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: "Webinars",
            tab: "General",
        },
        {
            label: '',
            type: 'custom',
            visible: (connectedObject) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Registrations',
            component: RegistrationComponent
        },
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const webinarMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const webinarMain = {
    fields: webinarfields,
    mobileCard: null
}