import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, StyleSheet, Platform, Dimensions } from 'react-native';
import { Colors, View, Text, ExpandableSection, Button, Image } from 'react-native-ui-lib';
import { Link, useNavigate } from 'react-router-dom';
import { useStores } from '../mycomponents/state';
import { observer } from 'mobx-react-lite';
import { Icon } from 'react-native-eva-icons';
import { api } from '../services/api';
import { GlobalMasterStore } from '../mycomponents/state/PermissionStore';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ScrollView } from 'react-native-gesture-handler';
import { StatusBar } from 'react-native';
import { getCustomSidebarMenu } from './custom_sidebars';
import { Dropdown } from 'antd';
import Svg, { Path } from "react-native-svg"
import { CustomModal, CustomSelect, CustomTextField, CustomUpload, Label, PrimaryButton } from '../mycomponents/DynamicForm';
import { App } from 'antd';

const HomeIconComponent = (props) => <Svg
    style={{
        width: 16,
        height: 16,
    }}
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    overflow="hidden"
    {...props}
>
    <Path d="M661.333 810.667h149.334V422.059L512 213.376l-298.581 208.47-.086 388.607v.214h149.334v-128a128 128 0 01128-128h42.666a128 128 0 01128 128v128zM164.565 351.872L463.232 143.36c29.312-20.48 68.267-20.48 97.536 0l298.667 208.512c22.912 16 36.565 42.24 36.565 70.187v388.394A85.419 85.419 0 01810.667 896H213.333C166.187 896 128 857.685 128 810.453V422.06c0-27.99 13.653-54.187 36.565-70.187zM576 810.667v-128A42.667 42.667 0 00533.333 640h-42.666A42.667 42.667 0 00448 682.667v128h128z" />
</Svg>;

const MenuIconComponent = (props) => {
    return (
        <Svg
            style={{
                width: 16,
                height: 16,
                verticalAlign: "middle"
            }}
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M844.8 883.2h-256c-19.2 0-38.4-19.2-38.4-38.4v-256c0-19.2 19.2-38.4 38.4-38.4h256c19.2 0 38.4 19.2 38.4 38.4v256c0 19.2-19.2 38.4-38.4 38.4zm0-403.2h-256c-19.2 0-38.4-19.2-38.4-38.4v-256c0-19.2 19.2-38.4 38.4-38.4h256c19.2 0 38.4 19.2 38.4 38.4v256c0 19.2-19.2 38.4-38.4 38.4zM435.2 883.2h-256c-19.2 0-38.4-19.2-38.4-38.4v-256c0-19.2 19.2-38.4 38.4-38.4h256c19.2 0 38.4 19.2 38.4 38.4v256c6.4 19.2-12.8 38.4-38.4 38.4zm0-403.2h-256c-19.2 0-38.4-19.2-38.4-38.4v-256c0-19.2 19.2-38.4 38.4-38.4h256c19.2 0 38.4 19.2 38.4 38.4v256c6.4 19.2-12.8 38.4-38.4 38.4z"
                fill={props.fill ?? "#272636"}
            />
        </Svg>
    )
}

const POSNewIconComponent = (props) => {
    return <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={15}
        fill="none"
        viewBox='0 0 31 30'
    >
        <Path
            fill="#fff"
            d="M24.5 24a3 3 0 1 1-3 3c0-1.665 1.335-3 3-3ZM.5 0h4.905l1.41 3H29a1.5 1.5 0 0 1 1.5 1.5c0 .255-.075.51-.18.75l-5.37 9.705a3.016 3.016 0 0 1-2.625 1.545H11.15L9.8 18.945l-.045.18a.375.375 0 0 0 .375.375H27.5v3h-18a3 3 0 0 1-3-3c0-.525.135-1.02.36-1.44l2.04-3.675L3.5 3h-3V0Zm9 24a3 3 0 1 1-3 3c0-1.665 1.335-3 3-3ZM23 13.5 27.17 6H8.21l3.54 7.5H23Z"
        />
    </Svg>;
}

const ProjectsComponent = (props) => {
    const { modalStore: { setOpenSomething, getOpenSomething, getModalHistory }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition, closeSideBar } } = useStores();
    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const [projects, setProjects] = React.useState([]);
    const [expandedItem, setExpandedItem] = React.useState(null);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
        closeSideBar();
    };

    const getProjects = async () => {
        const { data } = await api.get("Projects", {
            page: 1,
            limit: 100,
            sortColumn: 'name',
            sortDirection: 'asc',
            filters: [],
            tabFilter: [],
            search: '',
            groupBy: null,
        });

        setProjects(data?.data || []);
    }

    useEffect(() => {
        getProjects();
    }, []);

    return <View flex centerV>
        {/* {projects.map((project, index) => <ExpandableSection
            key={index.toString()}
            expanded={expandedItem == type + index}
            onPress={() => {
                if (expandedItem == type + index) {
                    setExpandedItem(null);
                } else {
                    setExpandedItem(type + index);
                }
            }}
            sectionHeader={<View flex centerV marginB-5>
                <TouchableOpacity style={[styles.MenuHead, {}, isSideBarCollapsed ? { marginRight: 5 } : {}]} onPress={() => {
                    if (expandedItem == type + index) {
                        setExpandedItem(null);
                    } else {
                        setExpandedItem(type + index);
                    }
                }}>
                    <View style={{ width: 20, height: 20, backgroundColor: '#e6f4ff', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                        <Text darkCardTitle style={{ fontSize: 9, color: '#2783cc', letterSpacing: 0.6 }}>
                            {project.name.substring(0, 2).toUpperCase()}
                        </Text>
                    </View>
                    {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{project.name}</Text>}
                </TouchableOpacity>
            </View>}
        >
            {project.lists.map((list, listIndex) => {
                return <TouchableOpacity
                    onPress={() => {
                        navigationFn("Tasks", {
                            project_id: project._id,
                            list_id: list._id,
                            is_default_values: true,
                        });
                    }}
                    key={listIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 40 }}>

                    <Text style={{ flex: 1 }}>{list.name}</Text>
                </TouchableOpacity>
            })}
        </ExpandableSection>)} */}
    </View>;
};

export const Sidebar = observer(function Sidebar() {
    const {
        modalStore: {
            setOpenSomething, getOpenSomething, getModalHistory
        },
        layoutStore: {
            isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition, getCurrentTab, setCurrentTab, closeSideBar
        }
    } = useStores();

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const employee = GlobalMasterStore((state) => state.employee);
    const setEmployee = GlobalMasterStore((state) => state.setEmployee);
    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);
    const location_id = GlobalMasterStore((state) => state.globalData.location_id);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const locations = GlobalMasterStore((state) => state.locations);

    const [expandedItem, setExpandedItem] = React.useState(null);
    const [showGYM, setShowGYM] = React.useState(false);
    const { message, modal } = App.useApp();

    // const [showHRMS, setShowHRMS] = React.useState(getCurrentTab === 'HRMS');
    // const [showAccounting, setShowAccounting] = React.useState(getCurrentTab === 'Accounting');
    // const [showTasks, setShowTasks] = React.useState(getCurrentTab === 'Tasks');
    // const [showMasters, setShowMasters] = React.useState(getCurrentTab === 'Masters');
    // const [showApprovals, setShowApprovals] = React.useState(getCurrentTab === 'Approvals');

    const [showHRMS, setShowHRMS] = React.useState(true);
    const [showAccounting, setShowAccounting] = React.useState(true);
    const [showTasks, setShowTasks] = React.useState(true);
    const [showMasters, setShowMasters] = React.useState(true);
    const [showApprovals, setShowApprovals] = React.useState(true);

    const [hrmsMenu, setHrmsMenu] = React.useState([]);
    const [accountingMenu, setAccountingMenu] = React.useState([]);
    const [taskMenu, setTaskMenu] = React.useState([]);
    const [masterMenu, setMasterMenu] = React.useState([]);
    const [approvalsMenu, setApprovalsMenu] = React.useState([]);
    const [showNotifications, setShowNotifications] = React.useState(false);
    const [showSettings, setShowSettings] = React.useState(false);

    const [customSidebarMenu, setCustomSidebarMenu] = React.useState([]);

    const [employeeData, setEmployeeData] = useState();
    const [notifications, setNotifications] = React.useState([]);

    const updateMenu = () => {
        const hrmsMenuItems = [
            {
                "label": "Employees", "icon": "Employees", "children": [
                    { "label": "Employees", "icon": "Employees", "link": "Employees", "children": null },
                    { "label": "Shifts", "icon": "Shifts", "link": "Shifts", "children": null },
                    // { "label": "ShiftSchedulers", "icon": "ShiftSchedulers", "link": "ShiftSchedulers", "children": null },
                ]
            },
            {
                "label": "Attendance", "icon": "Leave", "children": [
                    { "label": "By Date", "link": "report/AttendanceByDate", "children": null },
                    { "label": "By Employee", "link": "report/AttendanceByEmployee", "children": null },
                ]
            },
            // {
            //     "label": "Leave", "icon": "Leave", "children": [
            //         { "label": "Leave Requests", "icon": "LeaveRequests", "link": "LeaveRequests", "children": null },
            //         { "label": "Leave Categories", "icon": "LeaveCategories", "link": "LeaveCategories", "children": null },
            //     ]
            // },
            // { "label": "Holidays", "icon": "Holidays", "link": "Holidays", "children": null },
            // {
            //     "label": "Recruitement", "icon": "interview", "children": [
            //         { "label": "Jobs", "icon": "Jobs", "link": "Jobs", "children": null },
            //         { "label": "JobApplicants", "icon": "JobApplicants", "link": "JobApplicants", "children": null },
            //     ]
            // },
            // {
            //     "label": "Reimbursement", "icon": "Payrolls", "children": [
            //         { "label": "Advances", "link": "ReimbursementAdvances", "children": null },
            //         { "label": "Claims", "link": "ReimbursementClaims", "children": null },
            //         { "label": "Claim Categories", "link": "ReimbursementCategories", "children": null },
            //     ]
            // },
            // {
            //     "label": "Payroll", "icon": "Payrolls", "children": [
            //         { "label": "Payrolls", "icon": "Payrolls", "link": "Payrolls", "children": null },
            //         { "label": "PayrollSalaryItems", "icon": "PayrollSalaryItems", "link": "PayrollSalaryItems", "children": null },
            //         { "label": "PayrollStatutoryComponents", "icon": "PayrollStatutoryComponents", "link": "PayrollStatutoryComponents", "children": null },
            //     ]
            // },
            // {
            //     "label": "Training", "icon": "Training", "link": "Courses", "children": null
            // }
            // {
            //     "label": "Masters", "icon": "Master", "children": [
            //         { "label": "Departments", "icon": "Departments", "link": "Departments", "children": null },
            //         { "label": "Designations", "icon": "Designations", "link": "Designations", "children": null },
            //         { "label": "Locations", "icon": "WorkLocations", "link": "Locations", "children": null },
            //         { "label": "Teams", "icon": "Teams", "link": "Teams", "children": null },
            //         { "label": "Roles", "icon": "Roles", "link": "Roles", "children": null },
            //     ]
            // },
        ];

        let accountingMenuItems = [
            {
                "label": "Finance", "icon": "Payrolls",
                "children": [
                    { "label": "Financial Summary", "link": "report/FinancialSummary", "children": null },
                    { "label": "Money Transfers", "icon": "MoneyTransfers", "link": "MoneyTransfers", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null },
                ]
            },
            // { "label": "Assets", "icon": "items", "link": "Assets", },
            {
                "label": "Items", "icon": "Items",
                "children": [
                    { "label": "Items", "icon": "items", "link": "Items", },
                    // { "label": "ItemCategories", "icon": "categories", "link": "Categories", "params": { "is_default_values": true, "type": "item" } },
                    { "label": "Item Adjustments", "icon": "ItemAdjustments", "link": "ItemAdjustments", "children": null },
                    { "label": "Bill Of Materials", "icon": "BillOfMaterials", "link": "BillOfMaterials", "children": null },
                    // { "label": "Group Items", "icon": "items", "link": "ItemGroups" },
                    // { "label": "Item Transfers", "icon": "Transfers", "link": "Transfers", "children": null },
                    // { "label": "Item Traceability", "icon": "Transfers", "link": "Transfers", "children": null },
                    // { "label": "Item Tracing", "icon": "Transfers", "link": "ItemTracing", "children": null },
                    { "label": "Print Barcode", "icon": "BarcodePrinting", "link": "BarcodePrinting", "children": null },
                ]
            }, {
                "label": "Contacts", "icon": "3d-contact", "link": "Contacts",
                // "children": [
                //     { "label": "Contacts", "icon": "contacts", "link": "Contacts", },
                // { "label": "Appointments", "icon": "contacts", "link": "Appointments", },
                // { "label": "Subscriptions", "icon": "contacts", "link": "Subscriptions", },
                // { "label": "Price Lists", "icon": "PriceLists", "link": "PriceLists" }
                // ]
            },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Table Managment", "icon": "POS", "link": "table", "children": null },
                    { "label": "Leads", "icon": "Leads", "link": "Leads", "children": null },
                    { "label": "BroadcastNotifications", "icon": "BroadcastNotifications", "link": "BroadcastNotifications", "children": null },
                    { "label": "Quick POS", "icon": "POS", "link": "POS", "children": null },
                    { "label": "Quotations", "icon": "Quotations", "link": "Quotations", "children": null },
                    { "label": "Sales Orders", "icon": "SalesOrders", "link": "SalesOrders", "children": null },
                    { "label": "Delivery Orders", "icon": "DeliveryOrders", "link": "DeliveryOrders", "children": null },
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null },
                    // { "label": "Counter Master", "icon": "Counters", "link": "Counters", "children": null },
                ]
            },
            {
                "label": "Purchases", "icon": "3d-Purchase",
                "children": [
                    { "label": "Purchase Orders", "icon": "PurchaseOrders", "link": "PurchaseOrders", "children": null },
                    { "label": "Purchase Bills", "icon": "Bills", "link": "Bills", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null },
                    { "label": "Debit Notes", "icon": "PurchaseReturns", "link": "PurchaseReturns", "children": null },
                ]
            },
            {
                "label": "Accounting", "icon": "accounts",
                "children": [
                    { "label": "Accounts", "icon": "Accounts", "link": "Accounts", "children": null },
                    // { "label": "Manual Journals", "icon": "ManualJournals", "link": "ManualJournals", "children": null },
                    { "label": "Tcs", "icon": "Tcs", "link": "Tcs", "children": null },
                    { "label": "Taxes", "icon": "Taxes", "link": "Taxes", "children": null },
                    { "label": "TaxGroups", "icon": "TaxGroups", "link": "TaxGroups", "children": null },
                ]
            },
            // { "label": "Expenses", "icon": "no-money", "link": "Expenses", "children": null },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
        ]

        if (organization_id == '25') {
            accountingMenuItems = [
                ...accountingMenuItems,
                {
                    "label": "Videos", "icon": "3d-contact", "link": "Videos",
                },
                {
                    "label": "Webinars", "icon": "3d-contact", "link": "Webinars",
                },
                {
                    "label": "WebinarRegistrations", "icon": "3d-contact", "link": "WebinarRegistrations",
                }
            ]
        }

        const taskMenuItems = [
            { "label": "Manage Projects", "icon": "ProjectList", "link": "Projects", "children": null },
        ];

        const masterMenuItems = [
            {
                "label": "Company Master", "icon": "Master",
                "children": [
                    // { "label": "Positions", "link": "Positions", "children": null },
                    { "label": "Departments", "link": "Departments", "children": null },
                    { "label": "Designations", "link": "Designations", "children": null },
                    { "label": "Locations", "link": "Locations", "children": null },
                    // { "label": "Teams", "link": "Teams", "children": null },
                    { "label": "Roles and Permissions", "link": "Roles", "children": null },
                ]
            },
        ]

        const approvalsMenuItems = [
            {
                "label": "Approval", "icon": "Project",
                "children": [
                    { "label": "Approval Rules", "icon": "", "link": "ApprovalRules", "children": null },
                    { "label": "Approval Work Flow", "icon": "", "link": "ApprovalWorkflows", "children": null },
                ]
            },
        ]

        const customMenuItems = getCustomSidebarMenu(organization_id) || [];

        function filterBasedOnPermissions(menuItems) {
            return menuItems.filter(item => {
                if (item.children) {
                    item.children = filterBasedOnPermissions(item.children);
                    return item.children.length;
                } else {
                    return permissions[item.link] && permissions[item.link].view;
                    // return true;
                }
            });
        }

        const filteredHrmsMenuItems = filterBasedOnPermissions(hrmsMenuItems);
        setHrmsMenu(filteredHrmsMenuItems);

        const filteredAccountingMenuItems = filterBasedOnPermissions(accountingMenuItems);
        setAccountingMenu(filteredAccountingMenuItems);

        const filteredTaskMenuItems = filterBasedOnPermissions(taskMenuItems);
        setTaskMenu(filteredTaskMenuItems);

        const filteredMasterMenuItems = filterBasedOnPermissions(masterMenuItems);
        setMasterMenu(filteredMasterMenuItems);

        const filteredApprovalsMenuItems = filterBasedOnPermissions(approvalsMenuItems);
        setApprovalsMenu(filteredApprovalsMenuItems);

        const filteredCustomMenuItems = filterBasedOnPermissions(customMenuItems);
        setCustomSidebarMenu(filteredCustomMenuItems);
    }

    useEffect(() => {
        updateMenu();
    }, [permissions]);

    const gymMenu = [
        {
            "label": "Products", "icon": "Items",
            "children": [
                { "label": "Products", "icon": "items", "link": "Items", },
                // { "label": "Group Items", "icon": "items", "link": "ItemGroups" },
                { "label": "Stock Adjustments", "icon": "ItemAdjustments", "link": "ItemAdjustments", "children": null },
                { "label": "Stock Transfers", "icon": "Transfers", "link": "Transfers", "children": null },
                { "label": "Locations", "icon": "Locations", "link": "Locations", "children": null },
                { "label": "Categories", "icon": "categories", "link": "Categories", "params": { "is_default_values": true, "type": "item" } },
            ]
        },
        {
            "label": "Plans", "icon": "gymplans", "link": "Plans",
        },
        {
            "label": "Members", "icon": "3d-contact",
            "children": [
                { "label": "Members", "icon": "contacts", "link": "Contacts", },
                { "label": "Categories", "icon": "categories", "link": "Categories", "params": { "is_default_values": true, "type": "contact" } }
            ]
        },
        { "label": "Workout Plans", "icon": "gym", "link": "WorkoutPlans", "children": null },
        { "label": "Diet Plans", "icon": "DietPlan", "link": "DietPlans", "children": null },
        { "label": "Classes", "icon": "yoga", "link": "GymClasses", "children": null },
        { "label": "Equipments", "icon": "bike", "link": "Equipments", "children": null },
        { "label": "Exercises", "icon": "dumbell", "link": "Exercises", "children": null },
    ];

    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
            closeSideBar();
        }
    };

    useEffect(() => {
        if (Platform.OS === 'web') {
            let path = window?.location?.pathname;
            if (path) {
                path = path.replace("/", "");

                let item = approvalsMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Approvals" + hrmsMenu.indexOf(item));
                }

                item = hrmsMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("HRMS" + hrmsMenu.indexOf(item));
                }

                item = accountingMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Accounting" + accountingMenu.indexOf(item));
                }

                item = taskMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Projects & Tasks" + taskMenu.indexOf(item));
                }

                item = masterMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Company Master" + masterMenu.indexOf(item));
                }
            }
        } else {
            let path = nativeRoute?.routes?.[0]?.state?.routes?.[0]?.state?.routes?.[0]?.state?.routes?.[0]?.name;
            if (path) {
                path = path.replace("/", "");
                if (path) {
                    let item = hrmsMenu.find(item => item.link === path);
                    if (item) {
                        setExpandedItem(hrmsMenu.indexOf(item));
                    }
                }
            }
        }
    }, [nativeRoute, window?.location?.pathname]);

    const renderMenu = (menu, type) => {
        return menu.map((item, index) => {
            return <ExpandableSection
                key={index.toString()}
                expanded={expandedItem == type + index}
                onPress={() => {
                    if (expandedItem == type + index) {
                        setExpandedItem(null);
                    } else {
                        setExpandedItem(type + index);
                        if (item.link) {
                            navigationFn(item.link, {});
                        }
                    }
                }}
                sectionHeader={<View flex centerV marginB-0>
                    {!item.link ?
                        <View style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}]}>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }} style={{ width: 20, height: 20 }} />
                            {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{item.label}</Text>}
                        </View> :
                        Platform.OS === 'web' ? <Link to={item.link} >
                            <View style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}, isSideBarCollapsed ? { marginRight: 5 } : {}]}>
                                {item.icon ? <Image source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }} style={{ width: 20, height: 20 }} /> : item.iconLink ? <Image source={{ uri: item.iconLink }} style={{ width: 20, height: 20 }} /> : <></>}
                                {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{item.label}</Text>}
                            </View>
                        </Link> :
                            <TouchableOpacity style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}, isSideBarCollapsed ? { marginRight: 5 } : {}]} onPress={() => {
                                navigationFn(item.link, {});
                            }}>
                                {item.icon ? <Image source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }} style={{ width: 20, height: 20 }} /> : item.iconLink ? <Image source={{ uri: item.iconLink }} style={{ width: 20, height: 20 }} /> : <></>}
                                {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{item.label}</Text>}
                            </TouchableOpacity>
                    }
                </View>}
            >
                {item.children && item.children.map((child, childIndex) => {
                    return <View style={{ paddingLeft: 20 }} key={childIndex.toString()}>
                        {/* <TouchableOpacity
                            onPress={() => {
                                navigationFn(child.link, child.params);
                            }}
                            key={childIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            <Text style={{ flex: 1 }}>{child.label}</Text>
                        </TouchableOpacity> */}
                        {Platform.OS === 'web' ? <Link key={childIndex.toString()} to={child.link}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                                <Text style={{ flex: 1 }}>{child.label}</Text>
                            </View>
                        </Link> : <TouchableOpacity
                            onPress={() => {
                                navigationFn(child.link, child.params);
                            }}
                            key={childIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            <Text style={{ flex: 1 }}>{child.label}</Text>
                        </TouchableOpacity>}
                    </View>
                })}

                {item.custom && <item.custom />}
            </ExpandableSection>
            // } else if (getSideBarPosition == 'top' || Platform.OS != 'web') {
            //     // we need to show everything in menu kind of like a dropdown menu on top and then multiple submenu items we can use antd menu for this
            //     return <View style={{ flexDirection: 'column', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>

            //     </View>;
            // }
        })
    }

    const mainHeadings = (name) => {
        return <View row flex centerV spread>
            <Text tabHeading>{name}</Text>
            <Icon name={showHRMS ? 'chevron-up' : 'chevron-down'} width={16} height={16} fill='#979899' />
        </View>;
    }


    const getEmployeeData = async () => {
        const { data } = await api.getSingle("Employees", {
            _id: employee._id,
            converting: false,
            isClone: false,
            isEdit: true,
            platform: Platform.OS
        });

        const employeeData = {
            _id: data?._id,
            name: data?.name,
            email: data?.email,
            contact_number: data?.contact_number,
            user_id: data?.user_id,
            role_id: data?.role_id,
            employee_code: data?.employee_code,
            account_id: data?.account_id,
            image: data?.image,
            enable_face_auth: data?.enable_face_auth,
            face_auth_image_url: data?.face_auth_image_url,
        }

        setEmployee(employeeData);
        setEmployeeData(data);
    }

    useEffect(() => {
        //getEmployeeData();
    }, []);

    return (<View
        useSafeArea
        style={{
            // height: Platform.OS == 'web' ? '90%' : Dimensions.get('window').height,
            flex: 1,
        }}
        row={getSideBarPosition != 'left' && Platform.OS == 'web'}
    >
        <ScrollView
            style={{
                // backgroundColor: 'pink',
                // height: 300,
                // maxHeight: Dimensions.get('screen').height - (40 + (StatusBar.currentHeight || 20)),
            }}
        >
            <View row={getSideBarPosition != 'left' && Platform.OS == 'web'}>
                {/* <View>
                    <TouchableOpacity
                        onPress={() => {
                            navigationFn("dashboard", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            <View marginH-10>
                                <HomeIconComponent color="white" />
                            </View>
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }} color='white'>Home</Text>}
                        </View>
                    </TouchableOpacity>
                </View> */}

                {/* <View>
                    <TouchableOpacity
                        onPress={() => {
                            navigationFn("dashboard", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-bell.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }}>Notifications</Text>}
                        </View>
                    </TouchableOpacity>
                </View> */}

                {/* <ExpandableSection
                    expanded={showGYM}
                    onPress={() => {
                        setShowGYM(!showGYM);
                        setShowHRMS(false);
                        setShowAccounting(false);
                        setShowTasks(false);
                    }}
                    sectionHeader={<>
                        <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                        <View row centerV padding-10 style={showHRMS ? { backgroundColor: '#f7f7f7' } : {}}>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-HR.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && mainHeadings("GYM Management")}
                        </View>
                    </>}
                >
                    <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                        {renderMenu(gymMenu)}
                    </View>
                </ExpandableSection> */}

                {customSidebarMenu.length > 0 ? <>
                    {renderMenu(customSidebarMenu, "HRMS")}
                </> : <>
                    {/* {approvalsMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showApprovals}
                        onPress={() => {
                            if (!showApprovals) {
                                setCurrentTab("Approvals");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowApprovals(!showApprovals);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowAccounting(false);
                            // setShowTasks(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showTasks ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/Project.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Approvals")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(approvalsMenu, "Approvals")}
                        </View>
                    </ExpandableSection>}

                    {hrmsMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showHRMS}
                        onPress={() => {
                            if (!showHRMS) {
                                setCurrentTab("HRMS");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowHRMS(!showHRMS);
                            // setShowGYM(false);
                            // setShowAccounting(false);
                            // setShowTasks(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showHRMS ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-HR.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("HRMS")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(hrmsMenu, "HRMS")}
                        </View>
                    </ExpandableSection>}

                    {accountingMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showAccounting}
                        onPress={() => {
                            if (!showAccounting) {
                                setCurrentTab("Accounting");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowAccounting(!showAccounting);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowTasks(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showAccounting ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/AccountingMain.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Accounting")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(accountingMenu, "Accounting")}
                        </View>
                    </ExpandableSection>}

                    {taskMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showTasks}
                        onPress={() => {
                            if (!showTasks) {
                                setCurrentTab("Tasks");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowTasks(!showTasks);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowAccounting(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showTasks ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/Project.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Projects & Tasks")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(taskMenu, "Projects & Tasks")}
                            <ProjectsComponent />
                        </View>
                    </ExpandableSection>}

                    {masterMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showMasters}
                        onPress={() => {
                            if (!showMasters) {
                                setCurrentTab("Masters");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowMasters(!showMasters);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowAccounting(false);
                            // setShowTasks(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showAccounting ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/Master.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Company Master")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(masterMenu, "Company Master")}
                        </View>
                    </ExpandableSection>} */}

                    {(getSideBarPosition == 'left' || Platform.OS != 'web') && renderMenu(approvalsMenu, "Approvals")}
                    {(getSideBarPosition == 'left' || Platform.OS != 'web') && renderMenu(hrmsMenu, "HRMS")}
                    {(getSideBarPosition == 'left' || Platform.OS != 'web') && renderMenu(accountingMenu, "Accounting")}
                    {(getSideBarPosition == 'left' || Platform.OS != 'web') && renderMenu(masterMenu, "Company Master")}

                    {(getSideBarPosition != 'left' && Platform.OS == 'web') && <>
                        <Dropdown
                            trigger={["click"]}
                            menu={{
                                theme: 'dark',
                                mode: "inline",
                                items: [
                                    ...[...approvalsMenu, ...hrmsMenu, ...accountingMenu, ...masterMenu].map((item, index) => {
                                        return {
                                            key: "hrms" + index.toString(),
                                            label: (
                                                <Link to={item.link}>
                                                    {item.label}
                                                </Link>
                                            ),
                                            children: item.children && item.children.map((child, childIndex) => {
                                                return {
                                                    key: "hrms" + index.toString() + childIndex.toString(),
                                                    label: (
                                                        <Link to={child.link}>
                                                            {child.label}
                                                        </Link>
                                                    ),
                                                }
                                            })
                                        }
                                    })
                                ],
                            }}>
                            <View row centerV padding-10>
                                <View marginH-10>
                                    <MenuIconComponent color="white" fill="white" />
                                </View>
                                <Text color='white'>
                                    Menu
                                </Text>
                            </View>
                        </Dropdown>

                        <>
                            <TouchableOpacity onPress={() => {
                                navigationFn("table", {});
                            }}>
                                <View row centerV padding-10>
                                    <View marginH-10 style={{
                                        filter: 'invert(1)'
                                    }}>
                                        <Image source={{ uri: "https://cdn-icons-png.flaticon.com/128/7002/7002179.png" }}
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </View>
                                    <Text color='white'>
                                        On Hold Orders
                                    </Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
                                navigationFn("POS", {});
                            }}>
                                <View row centerV padding-10>
                                    <View marginH-10 style={{
                                        filter: 'invert(1)'
                                    }}>
                                        <Image source={{ uri: "https://cdn-icons-png.flaticon.com/128/6713/6713723.png" }}
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </View>
                                    <Text color='white'>
                                        New Order
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </>
                    </>}
                </>}
            </View>
        </ScrollView>
        <View row spread centerV style={{
            position: 'relative',
            height: 40,
            maxHeight: 40,
            marginBottom: Platform.OS == 'android' ? 10 : 0,
        }}>

            {Platform.OS == 'web' && <View center>
                <TouchableOpacity onPress={() => {
                    window.location.reload();
                }}>
                    <View row>
                        <Icon name='refresh-outline' width={20} height={20} fill='white' style={{ marginLeft: 10, marginRight: 8 }} />
                    </View>
                </TouchableOpacity>
            </View>}
            {Platform.OS == 'web' && <View center>
                <TouchableOpacity onPress={() => {
                    setShowSettings(true);
                }}>
                    <View row>
                        <Icon name='settings-outline' width={20} height={20} fill='white' style={{ marginLeft: 10, marginRight: 8 }} />
                    </View>
                </TouchableOpacity>
                <CustomModal
                    visible={showSettings}
                    onClose={() => {
                        setShowSettings(false);
                    }}
                    title="Settings"
                    width={400}
                    drawer={true}
                >
                    <View padding-20>

                        {Label({ label: "Profile Picture" })}

                        <View left style={{
                            marginTop: 10
                        }}>
                            <CustomUpload
                                value={employeeData?.image}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, image: value });
                                }}
                                placeholder="Select Image"
                                label="Select Image"
                                moduleName={"Employees"}
                                width="100%"
                                mimeType="image/*"
                            />
                        </View>

                        <View marginT-10>
                            {Label({ label: "Name" })}
                            <CustomTextField
                                value={employeeData?.name}
                                onChange={(value) => {
                                    setEmployeeData({
                                        ...employeeData,
                                        name: value,
                                    });
                                }}
                            />

                        </View>

                        <View marginT-10>
                            {Label({ label: "Email" })}
                            <CustomTextField
                                value={employeeData?.email}
                                onChange={(value) => {
                                    setEmployeeData({
                                        ...employeeData,
                                        email: value,
                                    });
                                }}
                            />
                        </View>

                        <View marginT-10>
                            {Label({ label: "Phone" })}
                            <CustomTextField
                                value={employeeData?.contact_number}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, contact_number: value });
                                }}
                                placeholder="Phone"
                                label="Phone"
                                width="100%"
                                showCountryCode={true}
                            />
                        </View>

                        <PrimaryButton
                            style={{
                                marginTop: 20
                            }}
                            label="Save"
                            onPress={async () => {
                                if (!employeeData?.name) {
                                    if (Platform.OS === 'web') {
                                        message.error("Name is required");
                                    } else {
                                        Alert.alert("Name is required");
                                    }
                                    return;
                                }

                                if (!employeeData?.email) {
                                    if (Platform.OS === 'web') {
                                        message.error("Email is required");
                                    } else {
                                        Alert.alert("Email is required");
                                    }
                                    return;
                                }

                                const res = await api.update("Employees", employeeData, employee._id);

                                if (res.message) {
                                    if (Platform.OS === 'web') {
                                        message.success(res.message);
                                    } else {
                                        Alert.alert(res.message);
                                    }
                                }

                                getEmployeeData();
                            }}
                        />
                    </View>
                </CustomModal>
            </View>}
            {Platform.OS == 'web' && <View center>
                <TouchableOpacity onPress={() => {
                    setShowNotifications(true);
                }}>
                    <View row>
                        <Icon name='bell-outline' width={20} height={20} fill='white' style={{ marginLeft: 10, marginRight: 8 }} />
                    </View>
                </TouchableOpacity>
                <CustomModal
                    visible={showNotifications}
                    onClose={() => {
                        setShowNotifications(false);
                    }}
                    title="Notifications"
                    width={400}
                    drawer={true}
                >
                    <View padding-20>
                        {(notifications && notifications.length > 0) ? <View>

                        </View> : <Text>No Notifications</Text>}
                    </View>
                </CustomModal>
            </View>}
            <View>
                {Platform.OS != 'web' && <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>}
                <TouchableOpacity
                    onPress={async () => {
                        if (Platform.OS != 'web') {
                            await AsyncStorage.removeItem('token');
                            await AsyncStorage.removeItem('globalData');
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('globalData');
                        }

                        setToken(null);
                        navigationFn("login", {});
                    }}
                    style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                    <View row>
                        <Image source={{ uri: `https://inkapps.pages.dev/icons/logout.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                    </View>
                </TouchableOpacity>
            </View>
            <View flex right>
                <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                <View>
                    {employee?.name == 'SYSTEM ADMIN' && <TouchableOpacity
                        onPress={() => {
                            navigationFn("OrganizationSettings", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }}>Organization Settings</Text>}

                            <Image source={{ uri: `https://inkapps.pages.dev/icons/org_settings.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                        </View>
                    </TouchableOpacity>}
                </View>
            </View>
            {Platform.OS == 'web' && <View marginR-10 center style={{
                width: 200,
                height: 30,
                overflow: 'hidden',
                borderRadius: 4,
                backgroundColor: 'white',
            }}>
                <CustomSelect
                    value={location_id}
                    onChange={(value) => {
                        setGlobalData({ location_id: value });
                    }}
                    placeholder="Select Locations"
                    width="100%"
                    options={(locations || []).map(location => {
                        return {
                            label: location.name,
                            value: location._id,
                        }
                    })}
                />
            </View>}
        </View>
    </View>);
})

const styles = StyleSheet.create({
    MenuText: { marginLeft: 8, fontSize: 14, color: '#415669', letterSpacing: 0.6 },
    MenuHead: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        marginRight: 15,
        marginTop: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        paddingVertical: 8,
    },
    highlightMenuItem: {
        backgroundColor: 'rgb(247, 247, 247)',
    },
});