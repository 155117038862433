import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { CustomSelectItemForContacts } from "../accountancy/transaction";

// contact_id: { type: Types.ObjectId, required: true, ref: 'Contacts' },
// contact: { type: new mongoose.Schema({ _id: { type: mongoose.Schema.Types.ObjectId }, name: { type: String }, email: { type: String }, phone: { type: String } }), default: {} },
// pax: { type: Number, required: true },
// date: { type: Date, required: true },
// duration: { type: Number, required: true },
// table_ids: [{ type: Types.ObjectId, required: true, ref: 'Tables' }],
// tables: [{ type: new mongoose.Schema({ _id: { type: mongoose.Schema.Types.ObjectId }, name: { type: String }, capacity: { type: Number } }), default: {} }],

// status: { type: String },

export const reservationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: true,
            width: '25%',
            options: response.Contacts || [],
            tab: 'General',
            creatableAction: (props) => {
                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
                        is_default_values: true,
                        phone: props.search,
                        is_supplier: false,
                    } : {})
                };
                OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
            },
            customSelectItem: CustomSelectItemForContacts,
            otherSearchKeys: ['phone', 'email'],
            autoFocus: true,
        },

        {
            label: 'Phone',
            key: 'contact___phone',
            type: 'text',
            placeholder: 'Enter the number of people',
            visible: view ? true : false,
            width: '25%',
            tab: 'General',
        },

        {
            label: 'Pax',
            key: 'pax',
            type: 'number',
            placeholder: 'Enter the number of people',
            visible: true,
            width: '25%',
            tab: 'General',
        },

        {
            label: 'Date',
            key: 'date',
            type: 'datetime',
            placeholder: 'Select the date and time',
            visible: true,
            width: '25%',
            tab: 'General',
        },

        // {
        //     label: 'Duration',
        //     key: 'duration',
        //     type: 'duration',
        //     placeholder: 'Enter the duration',
        //     visible: false,
        //     width: '25%',
        //     tab: 'General',
        // },

        {
            label: 'Tables',
            key: 'table_ids',
            type: 'select',
            placeholder: 'Select the tables',
            visible: true,
            width: '25%',
            options: response.Tables || [],
            tab: 'General',
            otherSearchKeys: ['name'],
            multiple: true,
            customSelectItem: (props) => {
                return <>
                    <View row centerV spread>
                        <Text>{props.label}</Text>
                        <View marginL-5>
                            <Text tabHeading>{props.area?.name}</Text>
                        </View>
                    </View>
                </>
            }
        },

        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select the status',
            visible: true,
            width: '25%',
            options: [
                // { label: 'Pending', value: 'Pending' },
                { label: 'Confirmed', value: 'Confirmed' },
                { label: 'Cancelled', value: 'Cancelled' },
                // { label: 'Completed', value: 'Completed' },
            ],
            tab: 'General',
            value: 'Confirmed'
        }

    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const reservationMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const reservationMain = {
    fields: reservationfields,
    mobileCard: null
}