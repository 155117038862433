// 
import { observer } from "mobx-react-lite"
import React, { useRef, FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { QueryBuilder } from 'react-querybuilder';
import { QueryBuilderNative } from '@react-querybuilder/native';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';
import { App } from 'antd';
import ReportScreenSingle from "../../../../../mycomponents/CRUD/ReportScreenSingle"
import { Icon } from "react-native-eva-icons"
import { MainScannerScreenComponent } from "../../../../../mycomponents/CRUD/ListingScreen"

const ItemTracingScreen = observer(function ItemTracingScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const [showScanner, setShowScanner] = useState(false);
    const [scannerValue, setScannerValue] = useState(null);
    const [reportKey, setReportKey] = useState(Math.random());

    const { message } = App.useApp();

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const MainWrapper = useCallback(({ children }) => {
        return Platform.OS == 'web' ? <>
            <Card margin-20>
                {children}
            </Card>
        </> : <>
            {children}
        </>
    }, [])

    return (
        <LayoutScreen>
            <View flex>
                <MainWrapper>
                    <View padding-15 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                        <View spread row>
                            <Text h1>Item Tracing</Text>
                            {/* <View>
                                <TouchableOpacity onPress={() => {
                                    setShowScanner(true);
                                }}>
                                    <View center row>
                                        <Image source={{
                                            uri: 'https://cdn-icons-png.flaticon.com/128/9492/9492647.png',
                                        }} style={{ width: 16, height: 16, marginRight: 10 }} />
                                        <Text darkCardTitle>Scan To Record</Text>
                                    </View>
                                </TouchableOpacity>
                            </View> */}
                        </View>
                    </View>


                    <View padding-20 flex>
                        <ReportScreenSingle
                            moduleName={"AvailableSerialNumbers"}
                            hideWrapper={true}
                            noMono={true}
                            hideHeader={true}
                            key={reportKey}
                            route={{
                                params: {
                                    name: "AvailableSerialNumbers",
                                    hideHeader: true,
                                    hideWrapper: true,
                                    noMono: true,
                                }
                            }}
                        />
                    </View>
                </MainWrapper>
            </View>
        </LayoutScreen>
    )
})

export default ItemTracingScreen

const $container = {
    flex: 1,
}