import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { QRCodeSVG } from 'qrcode.react';
import { Platform } from "react-native";

export const tablefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '25%'
        },
        {
            label: 'Capacity',
            key: 'capacity',
            type: 'number',
            placeholder: 'Capacity',
            visible: true,
            width: '25%'
        },
        {
            label: 'Area',
            key: 'area_id',
            type: 'select',
            placeholder: 'Area',
            visible: true,
            width: '25%',
            options: response.Areas,
            creatableAction: (props) => {
                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
                        is_default_values: true,
                        name: props.search,
                    } : {})
                };
                OpenModalCustom({ newModule: "Areas", field_key: 'area_id', params });
            },
        },
        {
            label: 'Table Managers',
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Select Table Managers',
            visible: true,
            width: '25%',
            options: response.Employees,
            multiple: true,
        },
        {
            type: "custom",
            key: "table_image",
            visible: (connectedObj) => connectedObj._id ? true : false,
            width: '100%',
            component: ({ field, useForm, tKey, tIndex, viewOnly }) => {
                const _id = useForm((state) => state.formObject._id);
                const name = useForm((state) => state.formObject.name);

                return <View>
                    {Platform.OS == 'web' && _id && <QRCodeSVG value={"https://restro-lime.vercel.app?tableId=" + _id + "&tableName= " + name} />}
                </View>;
            }
        }
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const tableMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const tableMain = {
    fields: tablefields,
    mobileCard: null
}