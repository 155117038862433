import React, { useEffect } from "react"
import { StatusBar } from 'expo-status-bar';
import { Alert, Platform, StyleSheet, Text, TextInput, View } from 'react-native';
import * as SplashScreen from "expo-splash-screen"
import { Assets, Image, Spacings, ThemeManager, Typography } from 'react-native-ui-lib';
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import { useFonts } from "expo-font"
import { customFontsToLoad } from "./app/theme";
import { useInitialRootStore } from "./app/mycomponents/state"
import { GlobalMasterStore } from "./app/mycomponents/state/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { api, collectionsNames } from "./app/services/api"
import { AppNavigator } from "./app/AppNavigator";
import { LogBox } from "react-native"
import 'react-native-gesture-handler';
import { GestureHandlerRootView } from "react-native-gesture-handler";


// prettier-ignore
LogBox.ignoreLogs([
  /\\(exported as '|':420\\)|PlatformColor/,
  "Require cycle:",
  'Warning:',
  "WARNING",
  "WARN"
])

SplashScreen.preventAutoHideAsync()

Assets.loadAssetsGroup('icons', {
  heart: require('./assets/icons/heart.png'),
  drag: require('./assets/icons/drag.png'),
  cf: require('./assets/icons/svg_cf.svg'),
});

Assets.loadAssetsGroup('images', {
  IkakiFooter: require('./assets/images/IkakiFooter.png'),
  webFooterNw: require('./assets/images/webFooterNw.png'),
});

Assets.loadAssetsGroup('sidebarIcons', {
  heart: require('./assets/icons/heart.png'),
  drag: require('./assets/icons/drag.png'),
  cf: require('./assets/icons/svg_cf.svg'),
});

ThemeManager.setComponentTheme('View', {
  pointer: {
    cursor: 'pointer',
  },
});

ThemeManager.setComponentTheme('Button', {
  size: 'small',
  borderRadius: 4,
  backgroundColor: '#1084f8',
  style: { paddingTop: 5, paddingBottom: 8, paddingLeft: 30, paddingRight: 30 },
});

Typography.loadTypographies({
  h1: { fontSize: 18, fontWeight: '500', lineHeight: 28, color: 'rgb(57, 65, 70)', fontFamily: 'SourceSansProBold' },
  h2: { fontSize: 22, fontWeight: '300', lineHeight: 28 },
  tabHeading: { fontSize: 13, fontWeight: '500', textTransform: 'uppercase', lineHeight: 16, color: '#979899', letterSpacing: 0.3 },
  value: { fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#324054', letterSpacing: 0.3 },
  formlabel: { flex: 1, flexWrap: 'wrap', fontSize: 14, letterSpacing: 0.4, fontWeight: "500", color: "#000000", lineHeight: 18, fontFamily: 'SourceSansProSemiBold' },
  SourceSansProRegular: { fontFamily: 'SourceSansProRegular' },
  lightCardTitle: { color: '#878686' },
  darkCardTitle: { fontFamily: 'SourceSansProSemiBold' },
  darkCardTitleBig: { fontFamily: 'SourceSansProBold', fontSize: 16 },
  CormoRegular: { fontFamily: 'CormorantGaramond_400Regular' },
  monoLight: { fontFamily: 'JetBrainsMonoLight' },
  monoRegular: { fontFamily: 'JetBrainsMonoRegular' },
  monoMedium: { fontFamily: 'JetBrainsMonoMedium' },
  monoSemiBold: { fontFamily: 'JetBrainsMonoSemiBold' },
  monoBold: { fontFamily: 'JetBrainsMonoBold' },
  monoTableBody: { fontFamily: 'JetBrainsMonoRegular', fontSize: 12 },
  monoTableHeading: { fontFamily: 'JetBrainsMonoExtraBold', fontSize: 11 },
  link: { color: '#1084f8' },

  PoppinsExtraLight: { fontFamily: 'PoppinsExtraLight' },
  PoppinsLight: { fontFamily: 'PoppinsLight' },
  Sen_400Regular: { fontFamily: 'Sen_400Regular' },
  Sen_400Regular: { fontFamily: 'Sen_400Regular' },
  Sen_500Medium: { fontFamily: 'Sen_500Medium' },
  Sen_600SemiBold: { fontFamily: 'Sen_600SemiBold' },
  Sen_700Bold: { fontFamily: 'Sen_700Bold' },
  Sen_800ExtraBold: { fontFamily: 'Sen_800ExtraBold' }
});

ThemeManager.setComponentTheme('Text', {
  SourceSansProRegular: true,
});

export default function App() {

  Text.defaultProps = Text.defaultProps || {};
  Text.defaultProps.allowFontScaling = false;
  TextInput.defaultProps = TextInput.defaultProps || {};
  TextInput.defaultProps.allowFontScaling = false;

  const hideSplashScreen = SplashScreen.hideAsync;
  const [areFontsLoaded] = useFonts(customFontsToLoad)

  const token = GlobalMasterStore((state) => state.token);
  const setToken = GlobalMasterStore((state) => state.setToken);
  const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

  const { rehydrated } = useInitialRootStore(async () => {
    // async function onFetchUpdateAsync() {
    //   try {
    //     const update = await Updates.checkForUpdateAsync();

    //     if (update.isAvailable) {
    //       Alert.alert(
    //         'Update available',
    //         'An update is available. Would you like to update the app?',
    //         [
    //           { text: 'Cancel', style: 'cancel' },
    //           {
    //             text: 'OK', onPress: async () => {
    //               await Updates.fetchUpdateAsync();
    //               await Updates.reloadAsync();
    //             }
    //           },
    //         ],
    //         { cancelable: false }
    //       );
    //       // await Updates.fetchUpdateAsync();
    //       // await Updates.reloadAsync();
    //     }
    //   } catch (error) {
    //     console.log(`Error fetching latest Expo update: ${error}`);
    //   }
    // }
    // if (Platform.OS != 'web') {
    //   await onFetchUpdateAsync();
    // }

    let storedToken = await AsyncStorage.getItem('token');
    let globalData = await AsyncStorage.getItem('globalData');
    let permissions = await AsyncStorage.getItem('permissions');
    let employee = await AsyncStorage.getItem('employee');
    let locations = await AsyncStorage.getItem('locations');

    if (globalData) {
      try {
        globalData = (JSON.parse(globalData));
      } catch (e) {

      }
    }

    if (storedToken && globalData) {
      setToken(storedToken)
      api.setTokenToAPI(storedToken, globalData.organization_id);

      setGlobalData(globalData);
    } else {
      setToken(null);
      api.setTokenToAPI(null, null);
      setGlobalData(null);
    }

    if (permissions) {
      try {
        permissions = (JSON.parse(permissions));
      } catch (e) {

      }
    }

    if (employee) {
      try {
        employee = (JSON.parse(employee));
      } catch (e) {

      }
    }

    if (locations) {
      try {
        locations = (JSON.parse(locations));
      } catch (e) {

      }
    }

    setTimeout(hideSplashScreen, 1000)
  })

  if (!rehydrated || !areFontsLoaded) return null

  return (
    Platform.OS != 'web' ? <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <StatusBar style="auto" />
        <AppNavigator />
      </SafeAreaProvider>
    </GestureHandlerRootView> : <AppNavigator />
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
