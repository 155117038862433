import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const shiftfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
        },
        {
            label: 'Start Time',
            key: 'start_time',
            type: 'time',
            placeholder: 'Enter the start time',
            visible: true,
            width: '25%',
        },
        {
            label: 'End Time',
            key: 'end_time',
            type: 'time',
            placeholder: 'Enter the end time',
            visible: true,
            width: '25%',
        },
        {
            label: 'Flexibility',
            key: 'flexibility',
            type: 'number',
            placeholder: 'Enter the flexibility',
            visible: true,
            width: '25%',
            value: 15,
        },
        {
            label: 'Flexibility Type',
            key: 'flexibility_type',
            type: 'select',
            placeholder: 'Select the flexibility type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Minutes', value: 'minutes' },
                { label: 'Hours', value: 'hours' },
            ],
            value: 'minutes',
        },
        {
            label: 'Rest Days',
            key: 'rest_days',
            type: 'table',
            placeholder: 'Enter the batch numbers',
            visible: true,
            width: '100%',
            editable: true,
            columns: [
                {
                    "field": "day",
                    "accessor": "day",
                    "label": "Day",
                    "Header": "Day",
                    "editable": true,
                    "type": "select",
                    "visible": true,
                    "order": 1,
                    "options": [
                        { label: 'Sunday', value: 'sunday' },
                        { label: 'Monday', value: 'monday' },
                        { label: 'Tuesday', value: 'tuesday' },
                        { label: 'Wednesday', value: 'wednesday' },
                        { label: 'Thursday', value: 'thursday' },
                        { label: 'Friday', value: 'friday' },
                        { label: 'Saturday', value: 'saturday' },
                    ],
                },
                {
                    "field": "weeks",
                    "accessor": "weeks",
                    "label": "Weeks",
                    "Header": "Weeks",
                    "editable": true,
                    "type": "select",
                    "visible": true,
                    "order": 2,
                    "options": [
                        { label: 'All Weeks', value: 'all_weeks' },
                        { label: 'First', value: 'first' },
                        { label: 'Second', value: 'second' },
                        { label: 'Third', value: 'third' },
                        { label: 'Fourth', value: 'fourth' },
                        { label: 'Fifth', value: 'fifth' }
                    ],
                    multiple: true,
                },
            ],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },
        addButton('Add Rest Day', 'add_rest_day', 'rest_days'),
        {
            label: 'Half Day',
            key: 'half_days',
            type: 'table',
            placeholder: 'Enter the half day',
            visible: true,
            width: '100%',
            columns: [
                {
                    "field": "day",
                    "accessor": "day",
                    "label": "Day",
                    "Header": "Day",
                    "editable": true,
                    "type": "select",
                    "visible": true,
                    "order": 1,
                    "options": [
                        { label: 'Sunday', value: 'sunday' },
                        { label: 'Monday', value: 'monday' },
                        { label: 'Tuesday', value: 'tuesday' },
                        { label: 'Wednesday', value: 'wednesday' },
                        { label: 'Thursday', value: 'thursday' },
                        { label: 'Friday', value: 'friday' },
                        { label: 'Saturday', value: 'saturday' },
                    ],
                },
                {
                    "field": "weeks",
                    "accessor": "weeks",
                    "label": "Weeks",
                    "Header": "Weeks",
                    "editable": true,
                    "type": "select",
                    "visible": true,
                    "order": 2,
                    "options": [
                        { label: 'All Weeks', value: 'all_weeks' },
                        { label: 'First', value: 'first' },
                        { label: 'Second', value: 'second' },
                        { label: 'Third', value: 'third' },
                        { label: 'Fourth', value: 'fourth' },
                        { label: 'Fifth', value: 'fifth' }
                    ],
                    multiple: true,
                },
                {
                    "field": "start_time",
                    "accessor": "start_time",
                    "label": "Start Time",
                    "Header": "Start Time",
                    "editable": true,
                    "type": "time",
                    "visible": true,
                    "order": 3,
                },
                {
                    "field": "end_time",
                    "accessor": "end_time",
                    "label": "End Time",
                    "Header": "End Time",
                    "editable": true,
                    "type": "time",
                    "visible": true,
                    "order": 4,
                }
            ],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },
        addButton('Add Half Day', 'add_half_day', 'half_days'),
        {
            label: 'Breaks',
            key: 'breaks',
            type: 'table',
            placeholder: 'Enter the breaks',
            visible: true,
            width: '100%',
            columns: [
                {
                    "field": "day",
                    "accessor": "day",
                    "label": "Day",
                    "Header": "Day",
                    "editable": true,
                    "type": "select",
                    "visible": true,
                    "order": 1,
                    "options": [
                        { label: 'All Days', value: 'all days' },
                        { label: 'Sunday', value: 'sunday' },
                        { label: 'Monday', value: 'monday' },
                        { label: 'Tuesday', value: 'tuesday' },
                        { label: 'Wednesday', value: 'wednesday' },
                        { label: 'Thursday', value: 'thursday' },
                        { label: 'Friday', value: 'friday' },
                        { label: 'Saturday', value: 'saturday' },
                    ],
                },
                {
                    "field": "weeks",
                    "accessor": "weeks",
                    "label": "Weeks",
                    "Header": "Weeks",
                    "editable": true,
                    "type": "select",
                    "visible": true,
                    "order": 2,
                    "options": [
                        { label: 'All Weeks', value: 'all_weeks' },
                        { label: 'First', value: 'first' },
                        { label: 'Second', value: 'second' },
                        { label: 'Third', value: 'third' },
                        { label: 'Fourth', value: 'fourth' },
                        { label: 'Fifth', value: 'fifth' }
                    ],
                    multiple: true,
                },
                {
                    "field": "start_time",
                    "accessor": "start_time",
                    "label": "Start Time",
                    "Header": "Start Time",
                    "editable": true,
                    "type": "time",
                    "visible": true,
                    "order": 3,
                },
                {
                    "field": "end_time",
                    "accessor": "end_time",
                    "label": "End Time",
                    "Header": "End Time",
                    "editable": true,
                    "type": "time",
                    "visible": true,
                    "order": 4,
                }
            ],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },
        addButton('Add Break', 'add_break', 'breaks'),
    ];

    const defaults = response.defaults;

    if (defaults) {
        for (let key in defaults) {
            const field = formFields.find(field => field.key == key);
            if (field) {
                field.value = defaults[key];
            }
        }
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const shiftMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const shiftMain = {
    fields: shiftfields,
    mobileCard: null
}

export function addButton(text, key, mainKey, defaultData = {}, tab = null, visible = true) {
    let btn = {
        label: '',
        key: key,
        type: 'button',
        visible: visible,
        span: 24,
        text: text,
        onClick: (props) => {
            const newId = new Date().getTime();
            const newDate = new Date();

            const { form, updateField } = props;
            let mainData = form[mainKey] || [];
            let newDefaultData = { ...defaultData };

            if (newDefaultData._id == "GENERATED_ID") {
                newDefaultData._id = newId;
            }

            if (newDefaultData.date == "GENERATED_DATE") {
                newDefaultData.date = newDate;
            }

            if (!mainData) {
                mainData = [{ ...newDefaultData }];
            } else {
                mainData = [...mainData, { ...newDefaultData }];
            }

            updateField(mainKey, mainData, form);
        },
        buttonProps: {
            style: {
                marginTop: 5,
                backgroundColor: 'transparent',
            },
            labelStyle: {
                color: 'rgb(112, 112, 112)',
                textTransform: 'uppercase',
                fontSize: 11,
                letterSpacing: 0.8,
                fontWeight: 600,
            },
            iconSource: {
                uri: 'https://static.thenounproject.com/png/1975497-200.png',
            },
            iconStyle: {
                width: 15,
                height: 15,
                marginRight: 5,
                opacity: 0.8,
            },
        }
    };

    if (tab) {
        btn.tab = tab;
    }

    return btn;
}
