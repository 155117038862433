import React, { useEffect, useMemo, useRef, useState } from "react";
import { View, Card, Text, TouchableOpacity } from "react-native-ui-lib";
import { Col, CustomCheckbox, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../mycomponents/DynamicForm";
import { ScrollView, StyleSheet } from "react-native";
import { Icon } from "react-native-eva-icons";
import DynamicTable from "../../mycomponents/DynamicTable";
import { UNIT_OF_MEASUREMENTS } from "../../services/api";
import moment from "moment";
import { Slider } from "antd";


const ACTIVE_STEP_WIDTH = 30;
const INACTIVE_STEP_WIDTH = 30;

const CustomWizard = ({ steps, activeStep, setActiveStep }) => {
    const scrollViewRef = useRef(null);

    const stepPositions = useMemo(() => {
        let accumulator = 0;
        return steps.map((_, index) => {
            const position = accumulator;
            accumulator += index === activeStep ? ACTIVE_STEP_WIDTH : INACTIVE_STEP_WIDTH;
            return position;
        });
    }, [steps, activeStep]);

    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({
                x: stepPositions[activeStep],
                animated: true,
            });
        }
    }, [activeStep, stepPositions]);

    const renderStep = (step, index) => {
        const isActive = index === activeStep;
        const isDone = index < activeStep || step.status === 'done';

        console.log("step.status", step.status);

        return (
            <TouchableOpacity
                key={index}
                style={[
                    styles.step,
                ]}
                onPress={() => setActiveStep(index)}
            >
                <View
                    style={[
                        styles.step,
                    ]}
                >
                    <View row centerV style={[
                        styles.iconContainer
                    ]}>
                        <View backgroundColor={step.status == 'in_progress' ? '#0e86fe' : step.status == 'completed' ? '#0e86fe' : index <= activeStep ? '#0e86fe' : "#c3d1eb"
                        } br100 padding-4>
                            {step.status != 'in_progress' && step.status != 'completed' && <Text center style={{
                                width: 16,
                                height: 16,
                                fontSize: 12,
                                fontFamily: 'SourceSansProSemiBold',
                                color: 'white',
                            }}>{index + 1}</Text>}

                            {step.status == 'in_progress' && <Icon name="activity-outline" fill="white" width={16} height={16} />}
                            {step.status == 'completed' && <Icon name="checkmark-outline" fill="white" width={16} height={16} />}
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.lineContainer}>
                <View
                    style={[
                        styles.line,
                        styles.backgroundLine
                    ]}
                />
                <View
                    style={[
                        styles.line,
                        {
                            backgroundColor: '#0e86fe',
                            width: `${((activeStep) / (steps.length - 1)) * 100}%`,
                            zIndex: 1,
                        },
                    ]}
                />
            </View>

            <ScrollView
                ref={scrollViewRef}
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={styles.scrollContent}
            >
                {steps.map(renderStep)}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    lineContainer: {
        position: 'relative',
        width: '100%',
        height: 4, // Adjust as needed
        top: '50%',
    },
    line: {
        position: 'absolute',
        height: '100%',
        left: 0,
        top: 0,
    },
    backgroundLine: {
        backgroundColor: '#c3d1eb', // Faded color
        width: '100%',
        zIndex: 0,
    },
    container: {
        height: 60,
        position: 'relative',
    },
    scrollContent: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    step: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeStepText: {
        fontWeight: 'bold',
    },
    iconContainer: {

    },
    stepNumber: {
        fontSize: 16,
    },
});




export const productionOrderfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const inputcolumns = [
        {
            "field": "item_id",
            "label": "Item",
            "editable": true,
            "type": "select",
            "visible": true,
            "order": 1,
            "Header": "Item",
            "accessor": "item_id",
            "placeholder": "Select the item",
            "options": response.Items || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = value?.value || value;
                const item = response.Items.find((item) => item._id == item_id);

                const newItems = [...getObj().input_materials];

                newItems[tIndex].item_id = item_id;
                newItems[tIndex].unit = item.unit;
                newItems[tIndex].quantity = 1;
                newItems[tIndex].unit_quantity = 1;
                newItems[tIndex].purchase_rate = item.purchase_rate;


                const obj = getObj();
                const steps = obj.steps;

                const newSteps = steps.map((step, index) => {
                    if (index == obj.activeStep) {
                        return {
                            ...step,
                            input_materials: newItems,
                        }
                    } else {
                        return step;
                    }
                });

                setObj({ input_materials: newItems, steps: newSteps });

                const formObject = getObj();
                const unit = item.unit;
                const original_unit = item.unit;
                const unit_quantity = 1;

                console.log({ formObject, unit, original_unit, unit_quantity });

                updateMappedItems({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "unit_quantity",
            "label": "Estimated Quantity",
            "editable": false,
            "type": "number",
            "visible": true,
            "order": 2,
            "Header": "Estimated Quantity",
            "accessor": "unit_quantity",
            "placeholder": "Enter the quantity",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = getObj().input_materials[tIndex].item_id;
                const item = response.Items.find((item) => item._id == item_id);

                const formObject = getObj();
                const unit = getObj().input_materials[tIndex].unit;
                const original_unit = item.unit;
                const unit_quantity = value;

                updateMappedItems({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "actual_quantity",
            "label": "Actual Quantity Used",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 2,
            "Header": "Actual Quantity Used",
            "accessor": "actual_quantity",
            "placeholder": "Enter the quantity",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = getObj().input_materials[tIndex].item_id;
                const item = response.Items.find((item) => item._id == item_id);

                const formObject = getObj();
                const unit = getObj().input_materials[tIndex].unit;
                const original_unit = item.unit;
                const unit_quantity = value;

                updateMappedItems({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "custom_unit",
            "label": "Unit",
            "editable": true,
            "type": "custom",
            "visible": true,
            "order": 3,
            "Header": "Unit",
            "accessor": "unit",
            "placeholder": "Enter the unit",
            "component": ({ field, tKey, tIndex, useForm, viewOnly }) => {
                const formObject = useForm((state) => state.formObject);
                const item_id = formObject?.['input_materials']?.[tIndex]?.item_id;
                const item = response?.Items?.find((item) => item._id == item_id);
                const original_unit = item?.unit;
                const unit = formObject?.['input_materials']?.[tIndex]?.unit;
                const unit_quantity = formObject?.['input_materials']?.[tIndex]?.unit_quantity;

                const setObj = useForm((state) => state.setFormObject);
                const getObj = useForm((state) => state.getFormObject);

                const unitValue = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

                if (!item || !unitValue) {
                    return <View>
                        {/* <Text>-</Text> */}
                    </View>
                }

                let options = [];

                if (unitValue?.base_unit) {
                    const all_units = UNIT_OF_MEASUREMENTS.filter((el) => el.base_unit == unitValue.base_unit);
                    options = all_units.map((el) => ({ label: (el.code + " (" + el.name + ")"), value: el.code })) || [];
                } else {
                    options = [{ label: (unitValue.code + " (" + unitValue.name + ")"), value: unitValue.code }];
                }


                return <View>
                    {viewOnly ? <Text>{unit}</Text> :
                        <CustomSelect
                            value={unit}
                            onChange={(value) => {
                                const newItems = [...getObj().input_materials];
                                newItems[tIndex].unit = value;

                                const obj = getObj();
                                const steps = obj.steps;

                                const newSteps = steps.map((step, index) => {
                                    if (index == obj.activeStep) {
                                        return {
                                            ...step,
                                            input_materials: newItems,
                                        }
                                    } else {
                                        return step;
                                    }
                                });

                                setObj({ input_materials: newItems, steps: newSteps });

                                updateMappedItems({ setObj, unit: value, original_unit, unit_quantity, tIndex, getObj, formObject });
                            }}
                            options={options}
                        />
                    }
                </View>
            },
        },
        {
            label: (props) => null,
            accessor: 'actions',
            field: 'actions',
            Header: (props) => null,
        }
    ];

    const outputcolumns = [
        {
            "field": "item_id",
            "label": "Item",
            "editable": true,
            "type": "select",
            "visible": true,
            "order": 1,
            "Header": "Item",
            "accessor": "item_id",
            "placeholder": "Select the item",
            "options": response.Items || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = value?.value || value;
                const item = response.Items.find((item) => item._id == item_id);

                const newItems = [...getObj().output_materials];

                newItems[tIndex].item_id = item_id;
                newItems[tIndex].unit = item.unit;
                newItems[tIndex].quantity = 1;
                newItems[tIndex].unit_quantity = 1;
                newItems[tIndex].purchase_rate = item.purchase_rate;


                const obj = getObj();
                const steps = obj.steps;

                const newSteps = steps.map((step, index) => {
                    if (index == obj.activeStep) {
                        return {
                            ...step,
                            output_materials: newItems,
                        }
                    } else {
                        return step;
                    }
                });

                setObj({ output_materials: newItems, steps: newSteps });

                const formObject = getObj();
                const unit = item.unit;
                const original_unit = item.unit;
                const unit_quantity = 1;

                console.log({ formObject, unit, original_unit, unit_quantity });

                updateMappedItemsOutput({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "unit_quantity",
            "label": "Estimated Quantity",
            "editable": false,
            "type": "number",
            "visible": true,
            "order": 2,
            "Header": "Estimated Quantity",
            "accessor": "unit_quantity",
            "placeholder": "Enter the quantity",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = getObj().output_materials[tIndex].item_id;
                const item = response.Items.find((item) => item._id == item_id);

                const formObject = getObj();
                const unit = getObj().output_materials[tIndex].unit;
                const original_unit = item.unit;
                const unit_quantity = value;

                updateMappedItemsOutput({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "actual_quantity",
            "label": "Actual Quantity Produced",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 2,
            "Header": "Actual Quantity Produced",
            "accessor": "actual_quantity",
            "placeholder": "Enter the quantity",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item_id = getObj().output_materials[tIndex].item_id;
                const item = response.Items.find((item) => item._id == item_id);

                const formObject = getObj();
                const unit = getObj().output_materials[tIndex].unit;
                const original_unit = item.unit;
                const unit_quantity = value;

                updateMappedItemsOutput({ setObj, unit: unit, original_unit, unit_quantity, tIndex, getObj, formObject });
            },
        },
        {
            "field": "custom_unit",
            "label": "Unit",
            "editable": true,
            "type": "custom",
            "visible": true,
            "order": 3,
            "Header": "Unit",
            "accessor": "unit",
            "placeholder": "Enter the unit",
            "component": ({ field, tKey, tIndex, useForm, viewOnly }) => {
                const formObject = useForm((state) => state.formObject);
                const item_id = formObject?.['output_materials']?.[tIndex]?.item_id;
                const item = response?.Items?.find((item) => item._id == item_id);
                const original_unit = item?.unit;
                const unit = formObject?.['output_materials']?.[tIndex]?.unit;
                const unit_quantity = formObject?.['output_materials']?.[tIndex]?.unit_quantity;

                const setObj = useForm((state) => state.setFormObject);
                const getObj = useForm((state) => state.getFormObject);

                const unitValue = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

                if (!item || !unitValue) {
                    return <View>
                        {/* <Text>-</Text> */}
                    </View>
                }

                let options = [];

                if (unitValue?.base_unit) {
                    const all_units = UNIT_OF_MEASUREMENTS.filter((el) => el.base_unit == unitValue.base_unit);
                    options = all_units.map((el) => ({ label: (el.code + " (" + el.name + ")"), value: el.code })) || [];
                } else {
                    options = [{ label: (unitValue.code + " (" + unitValue.name + ")"), value: unitValue.code }];
                }


                return <View>
                    {viewOnly ? <Text>{unit}</Text> :
                        <CustomSelect
                            value={unit}
                            onChange={(value) => {
                                const newItems = [...getObj().output_materials];
                                newItems[tIndex].unit = value;

                                const obj = getObj();
                                const steps = obj.steps;

                                const newSteps = steps.map((step, index) => {
                                    if (index == obj.activeStep) {
                                        return {
                                            ...step,
                                            output_materials: newItems,
                                        }
                                    } else {
                                        return step;
                                    }
                                });

                                setObj({ output_materials: newItems, steps: newSteps });

                                updateMappedItemsOutput({ setObj, unit: value, original_unit, unit_quantity, tIndex, getObj, formObject });
                            }}
                            options={options}
                        />
                    }
                </View>
            },
        },
        {
            label: (props) => null,
            accessor: 'actions',
            field: 'actions',
            Header: (props) => null,
        }
    ];

    const LevelsComponent = ({ field, useForm, tKey, tIndex }) => {
        const obj = useForm((state) => state.formObject);
        const getObj = useForm((state) => state.getFormObject);
        const setObj = useForm((state) => state.setFormObject);
        const activeStep = obj.activeStep || 0;
        const input_materials = obj.input_materials || [];
        const output_materials = obj.output_materials || [];
        const [showEditProgressModal, setShowEditProgressModal] = useState(false);
        const [reloadKey, setReloadKey] = useState(0);

        const steps = obj.steps || [{
            name: 'Process 1',
            incharge: '',
            duration: 0,
            duration_unit: 'days',
            input_materials: [],
            output_materials: [],
        }];

        const step = steps[activeStep] || {};

        const quantity = obj.quantity || 1;

        const setSteps = (steps) => {
            setObj({
                steps: steps
            });

            console.log({ steps });
        }

        const setActiveStep = (newStep) => {
            setObj({
                activeStep: newStep
            });
        }

        useEffect(() => {
            const activeStepData = steps[0];
            const input_materials = activeStepData.input_materials || [];
            const output_materials = activeStepData.output_materials || [];
            setObj({
                input_materials: input_materials,
                output_materials: output_materials,
            });
        }, [obj.updateVisiblity]);

        return (obj.production_workflow_id && view) ? <Card padding-20 style={{
            width: '100%',
        }}>
            <View style={{ marginBottom: 20 }} flex>
                <CustomWizard steps={steps} activeStep={activeStep} setActiveStep={(newStep) => {
                    setActiveStep(newStep);
                    const activeStepData = steps[newStep];
                    const input_materials = activeStepData.input_materials || [];
                    const output_materials = activeStepData.output_materials || [];
                    setObj({
                        input_materials: input_materials,
                        output_materials: output_materials,
                    });
                }} />

                {steps[activeStep] ? <View style={{ marginTop: 20 }} flex>
                    <Row>
                        <Col width={"25%"}>
                            {Label({ label: 'Process Name' })}
                            <Text value>
                                {steps[activeStep].name}
                            </Text>
                        </Col>
                        <Col width={"25%"}>
                            {Label({ label: 'Process Incharge' })}
                            <Text value>
                                {response.Employees.find((employee) => employee._id == step.incharge)?.name ?? ' - '}
                            </Text>
                        </Col>
                        <Col width={"25%"}>
                            {Label({ label: 'EST Duration' })}
                            <View row>
                                <Text value>
                                    {step.duration} {" "}
                                </Text>
                                <Text value>
                                    {step.duration_unit}
                                </Text>
                            </View>
                        </Col>
                    </Row>

                    <View row marginB-10>
                        {quantity > 200 ? <View style={{
                            backgroundColor: '#ffdada',
                            borderRadius: 7,
                            padding: 10,
                        }}>
                            <Text style={{ color: '#a30000', fontFamily: 'SourceSansProSemiBold' }}>Warning: you will be short of stock for the following items, please purchase them ASAP!</Text>
                            {(steps[activeStep].input_materials || []).map((item) => {
                                if (item.unit_quantity > 200) {
                                    return <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>{
                                        response.Items.find((el) => el._id == item.item_id)?.name
                                    } - {
                                            // random number between 100 and 300 
                                            quantity
                                        }</Text>
                                }
                            })}
                        </View> : activeStep > 2 ? <View style={{
                            backgroundColor: '#ffdada',
                            borderRadius: 7,
                            padding: 10,
                        }}>
                            <Text style={{ color: '#a30000', fontFamily: 'SourceSansProSemiBold' }}>Warning: you will be short of stock for the following items, please purchase them ASAP!</Text>
                            {(steps[activeStep].input_materials || []).map((item) => {
                                if (item.unit_quantity > 200) {
                                    return <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>{
                                        response.Items.find((el) => el._id == item.item_id)?.name
                                    } - {
                                            // random number between 100 and 300 
                                            Math.floor(0 + quantity)
                                        }</Text>
                                }
                            })}
                        </View> : null}
                    </View>

                    <View marginB-20>
                        <PrimaryButton label="Record Progress" onPress={() => {
                            // update actual and unit quantity based on the quantity used in step progress for input and output materials
                            const progress = JSON.parse(JSON.stringify(steps[activeStep].progress || []));

                            let input_materials = JSON.parse(JSON.stringify(steps[activeStep].input_materials || []));
                            let output_materials = JSON.parse(JSON.stringify(steps[activeStep].output_materials || []));

                            console.log({ progress, input_materials, output_materials });

                            if (progress && progress.length > 0) {
                                for (const obj of progress) {
                                    const input_materials_progress = obj.input_materials || [];
                                    const output_materials_progress = obj.output_materials || [];

                                    for (const item of input_materials_progress) {
                                        const index = input_materials.findIndex((el) => el.item_id == item.item_id);

                                        if (index > -1) {
                                            input_materials[index].quantity_used = (item.actual_quantity || 0) + (input_materials[index].quantity_used || 0);
                                        }
                                    }

                                    for (const item of output_materials_progress) {
                                        const index = output_materials.findIndex((el) => el.item_id == item.item_id);

                                        if (index > -1) {
                                            output_materials[index].quantity_used += (item.actual_quantity || 0) + (output_materials[index].quantity_used || 0);
                                        }
                                    }
                                }
                            }

                            console.log({ input_materials, output_materials });

                            let newInputMaterials = [];

                            for (const item of input_materials) {
                                let newItem = { ...item };
                                item.unit_quantity = item.unit_quantity - (item.quantity_used || 0);
                                item.actual_quantity = item.unit_quantity;
                                newInputMaterials.push(newItem);
                            }

                            let newOutputMaterials = [];

                            for (const item of output_materials) {
                                let newItem = { ...item };
                                item.unit_quantity = item.unit_quantity - (item.quantity_used || 0);
                                item.actual_quantity = item.unit_quantity;
                                newOutputMaterials.push(newItem);
                            }

                            console.log({ input_materials, output_materials });
                            setObj({
                                input_materials: JSON.parse(JSON.stringify(newInputMaterials)),
                                output_materials: JSON.parse(JSON.stringify(newOutputMaterials)),
                            });

                            setShowEditProgressModal(true);

                            setReloadKey(Math.random());
                            setTimeout(() => {
                                setReloadKey(Math.random());
                            }, 100);
                        }} />
                    </View>

                    {step.progress && step.progress.length > 0 ? <Row>
                        <Col width={"50%"}>
                            <Text h1>Input Materials and Costings</Text>

                            {step.progress.map((progress, index) => {
                                return <>
                                    <View row centerV spread marginV-10 style={{ width: '100%' }}>
                                        <Text h2 marginB-10 style={{ fontSize: 16 }}>{moment(progress.date).format('DD MMM YYYY hh:mm A')}</Text>
                                    </View>

                                    <DynamicTable
                                        data={progress.input_materials}
                                        columns={inputcolumns.map((column) => {
                                            if (column.field == 'item_id') {
                                                return {
                                                    ...column,
                                                    accessor: 'item_name',
                                                    field: 'item_name',
                                                };
                                            }
                                            if (column.field == 'custom_unit') {
                                                return {
                                                    ...column,
                                                    accessor: 'unit',
                                                    field: 'unit',
                                                }
                                            }
                                            return { ...column, editable: false };
                                        }).filter((column) => {
                                            if (column.field == 'unit_quantity') {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        })}
                                        editable={false}
                                        updateData={(data) => {

                                        }}
                                    //useForm={useForm}
                                    //tableKey={'input_materials'}
                                    />
                                </>
                            })}

                        </Col>

                        <Col width={"50%"}>
                            <Text h1>Output Products</Text>

                            {step.progress.map((progress, index) => {
                                return <>
                                    <View row centerV spread marginV-10 style={{ width: '100%' }}>
                                        <Text h2 marginB-10 style={{ fontSize: 16 }}>{moment(progress.date).format('DD MMM YYYY hh:mm A')}</Text>
                                    </View>

                                    <DynamicTable
                                        data={progress.output_materials}
                                        columns={outputcolumns.map((column) => {
                                            if (column.field == 'item_id') {
                                                return {
                                                    ...column,
                                                    accessor: 'item_name',
                                                    field: 'item_name',
                                                };
                                            }
                                            if (column.field == 'custom_unit') {
                                                return {
                                                    ...column,
                                                    accessor: 'unit',
                                                    field: 'unit',
                                                }
                                            }
                                            return { ...column, editable: false };
                                        }).filter((column) => {
                                            if (column.field == 'unit_quantity') {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        })}
                                        editable={false}
                                        updateData={(data) => {

                                        }}
                                    //useForm={useForm}
                                    //tableKey={'output_materials'}
                                    />
                                </>
                            })}

                        </Col>
                    </Row> : null}

                    <CustomModal visible={showEditProgressModal} onClose={() => setShowEditProgressModal(false)}>
                        <Text h1 marginB-10>Record Progress</Text>

                        <Slider defaultValue={0}
                            onChange={(value) => {
                                const newInputMaterials = input_materials.map((item) => {
                                    return {
                                        ...item,
                                        actual_quantity: item.unit_quantity * value / 100,
                                    }
                                });

                                const newOutputMaterials = output_materials.map((item) => {
                                    return {
                                        ...item,
                                        actual_quantity: item.unit_quantity * value / 100,
                                    }
                                });

                                setObj({
                                    input_materials: newInputMaterials,
                                    output_materials: newOutputMaterials,
                                });
                            }}
                        />

                        <Row key={reloadKey}>
                            <Col width={"100%"}>
                                <Text h1 marginB-10>Input Materials and Costings</Text>
                                <DynamicTable
                                    data={input_materials.filter((column) => {
                                        if (column.field == 'unit_quantity') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    })}
                                    // columns={inputcolumns}
                                    // replace unit_quantity with new_unit_quantity and actual_quantity with new_actual_quantity
                                    columns={inputcolumns}
                                    editable={true}
                                    updateData={(data) => {

                                    }}
                                    actions={true}
                                    actionFunctions={(row) => ([{
                                        label: 'Delete',
                                        key: 'delete',
                                        fn: ({ data, updateData, row, row_index }) => {
                                            let newData = [...data];
                                            newData.splice(row_index, 1);
                                            setObj({ input_materials: newData });

                                            const newSteps = steps.map((step, index) => {
                                                if (index == activeStep) {
                                                    return {
                                                        ...step,
                                                        input_materials: newData,
                                                    }
                                                } else {
                                                    return step;
                                                }
                                            });
                                            setSteps(newSteps);
                                        }
                                    }])}
                                    useForm={useForm}
                                    tableKey={'input_materials'}
                                />

                                <View marginT-20>
                                    <SecondaryButton
                                        label="Add Input Material/Costing"
                                        onPress={() => {
                                            let data = [...input_materials];
                                            data.push({
                                                quantity: 1,
                                            });

                                            setObj({
                                                input_materials: data
                                            });

                                            const newSteps = steps.map((step, index) => {
                                                if (index == activeStep) {
                                                    return {
                                                        ...step,
                                                        input_materials: data,
                                                    }
                                                } else {
                                                    return step;
                                                }
                                            });
                                            setSteps(newSteps);
                                        }}
                                    />
                                </View>
                            </Col>

                            <Col width={"100%"}>
                                {/* {Label({ label: 'Output Products' })} */}
                                <Text Text h1 marginB-10>Output Products</Text>

                                <DynamicTable
                                    data={output_materials.filter((column) => {
                                        if (column.field == 'unit_quantity') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    })}
                                    // columns={outputcolumns}
                                    // replace unit_quantity with new_unit_quantity and actual_quantity with new_actual_quantity
                                    columns={outputcolumns}
                                    editable={true}
                                    updateData={(data) => {

                                    }}
                                    actions={true}
                                    actionFunctions={(row) => ([{
                                        label: 'Delete',
                                        key: 'delete',
                                        fn: ({ data, updateData, row, row_index }) => {
                                            let newData = [...data];
                                            newData.splice(row_index, 1);
                                            setObj({ output_materials: newData });

                                            const newSteps = steps.map((step, index) => {
                                                if (index == activeStep) {
                                                    return {
                                                        ...step,
                                                        output_materials: newData,
                                                    }
                                                } else {
                                                    return step;
                                                }
                                            });
                                            setSteps(newSteps);
                                        }
                                    }])}
                                    useForm={useForm}
                                    tableKey={'output_materials'}
                                />

                                <View marginT-20>
                                    <SecondaryButton
                                        label="Add Output Product"
                                        onPress={() => {
                                            let data = [...output_materials];
                                            data.push({
                                                quantity: 1,
                                            });

                                            setObj({
                                                output_materials: data
                                            });

                                            const newSteps = steps.map((step, index) => {
                                                if (index == activeStep) {
                                                    return {
                                                        ...step,
                                                        output_materials: data,
                                                    }
                                                } else {
                                                    return step;
                                                }
                                            });
                                            setSteps(newSteps);
                                        }}
                                    />
                                </View>
                            </Col>
                        </Row>

                        <Row row>
                            <View marginR-10>
                                <PrimaryButton label="Save" onPress={() => {
                                    setShowEditProgressModal(false);
                                    steps[activeStep].status = 'completed';
                                    const progress = steps[activeStep].progress || [];

                                    let new_input_materials = JSON.parse(JSON.stringify(input_materials));
                                    let new_output_materials = JSON.parse(JSON.stringify(output_materials));

                                    new_input_materials = new_input_materials.map((item) => {
                                        const item_name = response.Items.find((el) => el._id == item.item_id)?.name;
                                        const unit_name = UNIT_OF_MEASUREMENTS.find((el) => el.code == item.unit)?.label;

                                        const progress_quantity = progress.reduce((acc, el) => {
                                            const itemNew = el.input_materials.find((el) => el.item_id == item.item_id);
                                            return acc + (itemNew?.actual_quantity || 0);
                                        }, 0);

                                        item.actual_quantity = Math.abs(progress_quantity - item.actual_quantity);

                                        return {
                                            ...item,
                                            item_name,
                                            unit_name,
                                        }
                                    });

                                    new_output_materials = new_output_materials.map((item) => {
                                        const item_name = response.Items.find((el) => el._id == item.item_id)?.name;
                                        const unit_name = UNIT_OF_MEASUREMENTS.find((el) => el.code == item.unit)?.label;

                                        const progress_quantity = progress.reduce((acc, el) => {
                                            const itemNew = el.output_materials.find((el) => el.item_id == item.item_id);
                                            return acc + (itemNew?.actual_quantity || 0);
                                        }, 0);

                                        item.actual_quantity = Math.abs(progress_quantity - item.actual_quantity);

                                        return {
                                            ...item,
                                            item_name,
                                            unit_name,
                                        }
                                    });

                                    const newProgress = [...progress, {
                                        date: new Date(),
                                        input_materials: JSON.parse(JSON.stringify(new_input_materials)),
                                        output_materials: JSON.parse(JSON.stringify(new_output_materials)),
                                    }];

                                    steps[activeStep].progress = newProgress;
                                    steps[activeStep].input_materials = JSON.parse(JSON.stringify(new_input_materials));
                                    steps[activeStep].output_materials = JSON.parse(JSON.stringify(new_output_materials));

                                    setSteps([...steps]);
                                }} />
                            </View>

                            <SecondaryButton label="Cancel" onPress={() => {
                                setShowEditProgressModal(false);
                            }} />
                        </Row>

                    </CustomModal>

                </View> : null}
            </View>
        </Card> : null;
    }

    const formFields = [
        {
            label: 'Worflow',
            key: 'production_workflow_id',
            type: 'select',
            placeholder: 'Select the workflow',
            options: response.ProductionWorkflows || [],
            visible: true,
            width: '25%',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const workflow = response.ProductionWorkflows.find((workflow) => workflow._id == value);
                const quantity = getObj().quantity || 1;

                if (workflow) {
                    // const newSteps = workflow.steps.map((step) => {
                    //     step.actual_duration = step.duration * quantity;
                    //     step.actual_duration_unit = step.duration_unit;

                    //     step.input_materials = (step.input_materials || []).map((item) => {
                    //         item.quantity = item.quantity * quantity;
                    //         item.unit_quantity = item.quantity * quantity;
                    //         item.actual_quantity = item.unit_quantity * quantity;
                    //         return item;
                    //     });

                    //     step.output_materials = (step.output_materials || []).map((item) => {
                    //         item.quantity = item.quantity * quantity;
                    //         item.unit_quantity = item.quantity * quantity;
                    //         item.actual_quantity = item.unit_quantity * quantity;
                    //         return item;
                    //     });

                    //     return step;
                    // });
                    const newSteps = [];
                    const oldSteps = [...workflow.steps];

                    for (const step of oldSteps) {
                        const newStep = {
                            ...step,
                            actual_duration: step.duration * quantity,
                            actual_duration_unit: step.duration_unit,
                        };

                        const newInputMaterials = [];
                        const oldInputMaterials = [...(step.input_materials || [])];

                        for (const item of oldInputMaterials) {
                            const newItem = {
                                ...item,
                                quantity: item.quantity * quantity,
                                unit_quantity: item.quantity * quantity,
                                actual_quantity: item.unit_quantity * quantity,
                            };

                            newInputMaterials.push(newItem);
                        }

                        const newOutputMaterials = [];
                        const oldOutputMaterials = [...(step.output_materials || [])];

                        for (const item of oldOutputMaterials) {
                            const newItem = {
                                ...item,
                                quantity: item.quantity * quantity,
                                unit_quantity: item.quantity * quantity,
                                actual_quantity: item.unit_quantity * quantity,
                            };

                            newOutputMaterials.push(newItem);
                        }

                        newStep.input_materials = newInputMaterials;
                        newStep.output_materials = newOutputMaterials;

                        newSteps.push(newStep);
                    }

                    setObj({
                        steps: newSteps,
                        activeStep: 0,
                        updateVisiblity: Math.random()
                    });
                }
            }
        },
        {
            label: "Quantity",
            key: "quantity",
            type: "number",
            visible: true,
            width: '25%',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const workflow = response.ProductionWorkflows.find((workflow) => workflow._id == getObj().production_workflow_id);
                const quantity = value;

                if (workflow) {
                    const newSteps = [];
                    const oldSteps = [...workflow.steps];

                    for (const step of oldSteps) {
                        const newStep = {
                            ...step,
                            actual_duration: step.duration * quantity,
                            actual_duration_unit: step.duration_unit,
                        };

                        const newInputMaterials = [];
                        const oldInputMaterials = [...(step.input_materials || [])];

                        for (const item of oldInputMaterials) {
                            const newItem = {
                                ...item,
                                quantity: item.quantity * quantity,
                                unit_quantity: item.quantity * quantity,
                                actual_quantity: item.unit_quantity * quantity,
                            };

                            newInputMaterials.push(newItem);
                        }

                        const newOutputMaterials = [];
                        const oldOutputMaterials = [...(step.output_materials || [])];

                        for (const item of oldOutputMaterials) {
                            const newItem = {
                                ...item,
                                quantity: item.quantity * quantity,
                                unit_quantity: item.quantity * quantity,
                                actual_quantity: item.unit_quantity * quantity,
                            };

                            newOutputMaterials.push(newItem);
                        }

                        newStep.input_materials = newInputMaterials;
                        newStep.output_materials = newOutputMaterials;

                        newSteps.push(newStep);
                    }

                    setObj({
                        steps: newSteps,
                        activeStep: 0,
                        updateVisiblity: Math.random()
                    });
                }
            }
        },
        {
            label: "Start Date & Time",
            key: "start_date",
            type: "datetime",
            visible: true,
            width: '25%',
        },
        {
            label: "Estimated End Date & Time",
            key: "end_date",
            type: "datetime",
            visible: view ? true : false,
            width: '25%',
        },
        {
            label: '',
            key: 'levels',
            type: 'custom',
            component: LevelsComponent,
            visible: true,
            width: '100%',
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const productionOrderMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const productionOrderMain = {
    fields: productionOrderfields,
    mobileCard: null
}

const updateMappedItems = ({ setObj, unit, original_unit, unit_quantity, tIndex, getObj, formObject }) => {
    if (unit && original_unit && unit_quantity) {
        const originalUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == original_unit);
        const selectedUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

        if (originalUnitData && selectedUnitData && originalUnitData.base_unit === selectedUnitData.base_unit) {
            // Convert to base unit, then to original unit
            const baseUnitQuantity = unit_quantity * selectedUnitData.conversion_factor;
            const originalUnitQuantity = baseUnitQuantity / originalUnitData.conversion_factor;

            // Update the formObject with the new quantity
            setObj({
                input_materials: formObject.input_materials.map((item, index) =>
                    index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                )
            });

            const obj = getObj();
            const steps = obj.steps;

            const newSteps = steps.map((step, index) => {
                if (index == obj.activeStep) {
                    return {
                        ...step,
                        input_materials: formObject.input_materials.map((item, index) =>
                            index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                        ),
                    }
                } else {
                    return step;
                }
            });

            setObj({ steps: newSteps });
        }
    }
}

const updateMappedItemsOutput = ({ setObj, unit, original_unit, unit_quantity, tIndex, getObj, formObject }) => {
    if (unit && original_unit && unit_quantity) {
        const originalUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == original_unit);
        const selectedUnitData = UNIT_OF_MEASUREMENTS.find((el) => el.code == unit);

        if (originalUnitData && selectedUnitData && originalUnitData.base_unit === selectedUnitData.base_unit) {
            // Convert to base unit, then to original unit
            const baseUnitQuantity = unit_quantity * selectedUnitData.conversion_factor;
            const originalUnitQuantity = baseUnitQuantity / originalUnitData.conversion_factor;

            // Update the formObject with the new quantity
            setObj({
                output_materials: formObject.output_materials.map((item, index) =>
                    index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                )
            });

            const obj = getObj();
            const steps = obj.steps;

            const newSteps = steps.map((step, index) => {
                if (index == obj.activeStep) {
                    return {
                        ...step,
                        output_materials: formObject.output_materials.map((item, index) =>
                            index == tIndex ? { ...item, quantity: originalUnitQuantity } : item
                        ),
                    }
                } else {
                    return step;
                }
            });

            setObj({ steps: newSteps });
        }
    }
}