// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, Alert } from "react-native"
import { api } from "../services/api"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, KeyboardAwareScrollView, TouchableOpacity, Dialog, PanningProvider, Incubator } from 'react-native-ui-lib';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStores } from "../mycomponents/state"
import { Sidebar } from "./SideBar"
import { Icon } from "react-native-eva-icons"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { GlobalMasterStore } from "../mycomponents/state/PermissionStore"

const MobileDrawer = observer(function MobileDrawer(props) {
    const { layoutStore: { sideBarOpen, toggleSideBarOpen, closeSideBar } } = useStores();

    const navigation = useNavigation();
    const [screenName, setScreenName] = useState(null);
    const [name, setName] = useState(null);

    const setToken = GlobalMasterStore((state) => state.setToken);
    const globalData = GlobalMasterStore((state) => state.globalData);

    useEffect(() => {
        const organization_id = globalData?.organization_id;
        const organizations = globalData?.organizations;
        const organization = organizations?.find((o) => o.id == organization_id);
        setName(organization?.name);
    }, [globalData]);

    const navigationFn = (path, params) => {
        navigation.navigate(path, params);
    }

    useEffect(() => {
        return () => {
            if (sideBarOpen) {
                closeSideBar();
            }
        }
    }, []);

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('state', (e) => {
            if (e.data.state?.routes?.length > 0) {
                setScreenName(e.data.state.routes[e.data.state.routes.length - 1].name);
            }
        });

        return () => unsubscribe();
    }, [navigation]);

    return (<><View useSafeArea backgroundColor="red" style={{
        // marginTop: Platform.OS == 'android' ? 15 : 0,
        // flex: 1,
    }}>
        <Incubator.Dialog
            left
            height={18000}
            visible={sideBarOpen}
            onDismiss={() => {
                closeSideBar();
            }}
            direction={PanningProvider.Directions.LEFT}
            containerStyle={{
                height: 18000,
                maxHeight: 18000,
                // maxHeight: Dimensions.get('screen').height,
                flex: 4,
                width: Dimensions.get('screen').width * 0.85,
            }}
            overlayBackgroundColor="transparent"
        >
            <Sidebar />
        </Incubator.Dialog>

        <View row spread centerV padding-10 style={{
            backgroundColor: props.darkBg ? "#242424" : 'white',
        }}>
            <View style={{
                width: 100
            }}>
                <TouchableOpacity padding-10 onPress={() => {
                    toggleSideBarOpen();
                }}>
                    <Icon name="menu-outline" width={16} height={16} fill={props.darkBg ? "white" : "black"} />
                </TouchableOpacity>
            </View>


            <Text text90 style={{
                color: props.darkBg ? "white" : "black",
            }}> {name}  </Text>

            <View right style={{
                width: 100
            }}>
                {screenName != 'dashboard' ? <TouchableOpacity padding-10 onPress={() => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigationFn("dashboard", {});
                    }
                }}>
                    <View row centerV>
                        <View marginR-5>
                            <Icon name="arrow-back-outline" width={16} height={16} fill={props.darkBg ? "white" : "black"} />
                        </View>
                        <Text style={{
                            color: props.darkBg ? "white" : "black",
                        }}>
                            Back
                        </Text>
                    </View>
                </TouchableOpacity> : <TouchableOpacity padding-10 onPress={async () => {
                    if (Platform.OS != 'web') {
                        await AsyncStorage.removeItem('token');
                        await AsyncStorage.removeItem('globalData');
                    } else {
                        localStorage.removeItem('token');
                        localStorage.removeItem('globalData');
                    }
                    setToken(null);
                    navigationFn("login", {});
                }}>
                    <View row centerV>
                        <View marginR-5>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/logout.png` }} style={{ width: 20, height: 20, marginLeft: 10 }} />
                        </View>
                    </View>
                </TouchableOpacity>}
            </View>
        </View>
    </View>
    </>);
});

export default MobileDrawer;