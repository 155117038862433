import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TouchableOpacity, DeviceEventEmitter } from "react-native"

import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { GlobalMasterStore } from "../../../../../../mycomponents/state/PermissionStore"
import { GlobalModalStore } from "../../../../../../mycomponents/state/ModalStore"

const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]

const items = [
    {
        id: 1,
        group: 1,
        title: 'item 1',
        start_time: moment(),
        end_time: moment().add(1, 'hour')
    },
    {
        id: 2,
        group: 2,
        title: 'item 2',
        start_time: moment().add(-0.5, 'hour'),
        end_time: moment().add(0.5, 'hour')
    },
    {
        id: 3,
        group: 1,
        title: 'item 3',
        start_time: moment().add(2, 'hour'),
        end_time: moment().add(3, 'hour')
    }
]

const RoomTimelineComponents = ({ rooms, getEvents, defaultTimeStart, defaultTimeEnd }) => {
    const [items, setItems] = useState([])

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    const getAllEvents = async () => {
        const events = await getEvents({
            start: moment().add(-1, 'month').toISOString(),
            end: moment().add(3, 'month').toISOString()
        });

        console.log(events);

        // setItems(events.map(event => ({
        //     id: event._id,
        //     group: event.room._id,
        //     title: event.title,
        //     start_time: moment(event.start),
        //     end_time: moment(event.end)
        // })))

        let finalItems = [];

        for (const event of events) {
            let hotel_room_ids = event.hotel_room_ids; // one event can have multiple rooms

            for (const hotel_room_id of hotel_room_ids) {
                finalItems.push({
                    id: event._id + "," + hotel_room_id,
                    group: hotel_room_id,
                    title: event.contact?.name,
                    start_time: moment(event.date),
                    end_time: moment(event.end_date),
                    canMove: false,
                    canResize: false,
                    itemProps: {
                        style: {
                            background: '#dc3f4d',
                            borderRadius: 4,
                            borderWidth: 0,
                            borderStyle: 'none',
                        }
                    }
                })
            }
        }

        console.log(finalItems);

        setItems(finalItems);
    }

    const goToEdit = (id, viewOnly = false) => {
        if (Platform.OS === 'web') {
            setOpenModal({
                ["HotelReservations"]: {
                    isVisible: true,
                    moduleName: "HotelReservations",
                    id: id,
                    isEdit: viewOnly ? false : true,
                    viewOnly: viewOnly,
                    afterSaveEvent: 'reloadListing',
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "HotelReservations",
                isEdit: true,
                id: id,
                goBackAfterSave: true
            });
        }
    }

    useEffect(() => {
        if (rooms.length > 0)
            getAllEvents();

        DeviceEventEmitter.addListener('reloadListing', () => {
            getAllEvents();
        });

        return () => {
            DeviceEventEmitter.removeAllListeners('reloadListing');
        }
    }, [rooms])

    return rooms?.length ? <Timeline
        groups={rooms.map(room => ({ id: room._id, title: room.name + " - " + room.hotel_area?.name }))} // room.hotel_area.name
        items={items}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        traditionalZoom={true}
        onItemSelect={(itemId) => {
            console.log(itemId);
            const [reservation_id, hotel_room_id] = itemId.split(',');

            console.log(reservation_id, hotel_room_id);
            goToEdit(reservation_id, true)
        }}
    /> : null
}


export default RoomTimelineComponents
