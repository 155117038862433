import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ReportScreenSingle from "../../mycomponents/CRUD/ReportScreenSingle";

export const accountfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const LedgerAccountComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const account_id = useForm((state) => state.formObject['_id']);

        return <View flex>
            <ReportScreenSingle
                moduleName={"Ledger"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'account_id',
                    quickFilterValue: account_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'account_id',
                        quickFilterValue: account_id,
                        hideQuickFilter: true,
                        name: "Ledger",
                    }
                }}
            />
        </View>
    });

    let formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Enter the description',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Type',
            key: 'type',
            type: 'select',
            placeholder: 'Select the type',
            visible: true,
            width: '25%',
            options: response.AccountTypes,
            onChange: (props) => {
                const map = {
                    'Cash/Bank': 'Asset',
                    'Cost Of Goods Sold': 'Expense',
                    'Equity': 'Equity',
                    'Expense': 'Expense',
                    'Fixed Asset': 'Asset',
                    'Investment': 'Asset',
                    'Income': 'Income',
                    'Long Term Liability': 'Liability',
                    'Other Current Asset': 'Asset',
                    'Other Current Liability': 'Liability',
                    'Other Expense': 'Expense',
                    'Other Liability': 'Liability',
                    'Purchases': 'Expense',
                    'Sales': 'Income',
                    'Stock': 'Asset',
                }

                props.setObj({
                    base_type: map[props.value]
                })
            },
            tab: 'General',
        },
        {
            label: 'Base Type',
            key: 'base_type',
            type: 'select',
            placeholder: 'Select the base type',
            visible: false,
            width: '25%',
            options: response.AccountBaseTypes,
            tab: 'General',
        },
        // {
        //     label: 'Opening Balance',
        //     key: 'opening_balance',
        //     type: 'number',
        //     placeholder: 'Enter the opening balance',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        // },
    ];

    if (view) {
        formFields = [{
            label: '',
            type: 'custom',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Statement',
            component: LedgerAccountComponent
        }, ...formFields]
    }


    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const accountMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const accountMain = {
    fields: accountfields,
    mobileCard: null
}