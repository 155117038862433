import * as React from 'react';
import {
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    Platform,
    Dimensions,
    Alert,
} from 'react-native';
import { api } from '../../../../../services/api';
import { App } from "antd"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GlobalMasterStore } from '../../../../../mycomponents/state/PermissionStore';
import { Col, CustomModal, CustomTextField, Label, PrimaryButton, Row, SecondaryButton } from '../../../../../mycomponents/DynamicForm';
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { useNavigate, useParams } from "react-router-dom"
import { View } from 'react-native-ui-lib';
import { FlashList } from '@shopify/flash-list';
import { ScrollView } from 'react-native-gesture-handler';

export default function LoginPhoneScreen() {
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [otpSent, setOtpSent] = React.useState();
    const [verificationCode, setVerificationCode] = React.useState();
    const { message, modal } = App.useApp();
    const [attemptingLogin, setAttemptingLogin] = React.useState(false);
    const [cooldownSeconds, setCooldownSeconds] = React.useState(0);

    const setToken = GlobalMasterStore((state) => state.setToken);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    let navigation = useNavigation();
    let navigate = null;

    const [organizations, setOrganizations] = React.useState([]);
    const [showSelectOrganizationModal, setShowSelectOrganizationModal] = React.useState(false);

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    React.useEffect(() => {
        let timer;
        if (cooldownSeconds > 0) {
            timer = setInterval(() => {
                setCooldownSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [cooldownSeconds]);

    return (
        <View centerH style={{ width: '100%', padding: 20, paddingTop: 50, height: Dimensions.get('window').height }}>
            <Row style={Platform.OS == 'web' ? { maxWidth: 500, marginHorizontal: 'auto' } : {}}>
                <Col width='100%'>
                    {Label({ label: "Enter phone number" })}
                    {<CustomTextField
                        style={{ marginVertical: 10, fontSize: 17 }}
                        placeholder="9999988888"
                        showCountryCode={true}
                        autoFocus
                        autoCompleteType="tel"
                        keyboardType="phone-pad"
                        textContentType="telephoneNumber"
                        value={phoneNumber}
                        onChange={(value) => {
                            console.log("value", value);
                            setPhoneNumber(value)
                        }}
                    />}

                    <PrimaryButton style={{
                        marginTop: 10,
                        width: '100%',
                    }}
                        label={cooldownSeconds > 0 ? `Resend in ${cooldownSeconds}s` : "Send Verification Code"}
                        disabled={!phoneNumber || cooldownSeconds > 0}
                        onPress={async () => {
                            try {
                                setAttemptingLogin(true);
                                const res = await api.login({
                                    phone_number: phoneNumber,
                                    no_check: true,
                                    "3345A": true,
                                });

                                console.log("res", res);

                                if (res.message) {
                                    if (Platform.OS === 'web') {
                                        message.success(res.message);
                                    } else {
                                        Alert.alert(res.message);
                                    }

                                    if (res.success) {
                                        setOtpSent(true);
                                        setCooldownSeconds(30);
                                    }
                                } else {
                                    if (Platform.OS === 'web') {
                                        message.success("Something went wrong");
                                    } else {
                                        Alert.alert("Something went wrong");
                                    }
                                }
                                setAttemptingLogin(false);
                            } catch (err) {
                                if (Platform.OS === 'web') {
                                    message.error(`Error: ${err.message}`);
                                } else {
                                    Alert.alert(`Error: ${err.message}`);
                                }
                                setAttemptingLogin(false);
                            }
                        }} />

                    {!!otpSent && <View style={{ marginTop: 10, width: '100%' }}>
                        {Label({ label: "Enter Verification code" })}
                        {<CustomTextField
                            style={{ width: '100%', marginVertical: 10, fontSize: 17 }}
                            placeholder="123456"
                            value={verificationCode}
                            onChange={(value) => {
                                setVerificationCode(value)
                            }}
                        />}
                        <PrimaryButton
                            style={{ marginTop: 10, width: '100%' }}
                            label="Confirm Verification Code"
                            disabled={attemptingLogin}
                            onPress={async () => {
                                try {
                                    setAttemptingLogin(true);
                                    const res = await api.login({
                                        code: verificationCode,
                                        phone_number: phoneNumber,
                                        no_check: true,
                                        // "3345A": true,
                                    });

                                    if (res.message) {
                                        if (Platform.OS === 'web') {
                                            message.success(res.message);
                                        } else {
                                            Alert.alert(res.message);
                                        }

                                        if (res.success) {
                                            const organizations = res.user.organizations;

                                            if (organizations.length == 1) {
                                                await AsyncStorage.setItem('token', res.token);
                                                const user_id = res.user._id;

                                                api.setTokenToAPI(res.token, organizations[0].id);

                                                setToken(res.token);
                                                setGlobalData({
                                                    user_id: user_id,
                                                    organizations: organizations,
                                                    organization_id: organizations[0].id,
                                                    employee_id: organizations[0].employee_id,
                                                    // user: res.user,
                                                })

                                                navigationFn("dashboard");
                                            } else {
                                                setOrganizations(organizations);
                                                setShowSelectOrganizationModal(true);
                                            }
                                        }
                                    } else {
                                        if (Platform.OS === 'web') {
                                            message.success("Something went wrong");
                                        } else {
                                            Alert.alert("Something went wrong");
                                        }
                                    }
                                    setAttemptingLogin(false);
                                } catch (err) {
                                    if (Platform.OS === 'web') {
                                        message.error(`Error: ${err.message}`);
                                    } else {
                                        Alert.alert(`Error: ${err.message}`);
                                    }
                                    setAttemptingLogin(false);
                                }
                            }}
                        />
                    </View>}

                    <View center marginV-10 style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}>
                        <View style={{
                            backgroundColor: "rgb(245, 247, 249)"
                        }}>
                            <Text style={{
                                letterSpacing: 1,
                                fontFamily: 'SourceSansProSemiBold'
                            }}>OR</Text>
                        </View>
                        <View style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 9,
                            bottom: 0,
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: 1,
                            backgroundColor: "#c0c0c059",
                        }}>


                        </View>
                    </View>

                    <SecondaryButton
                        style={{ marginTop: 10, width: '100%' }}
                        label="Login with Email and Password"
                        onPress={() => {
                            navigationFn("loginWithEmail")
                        }}
                    />
                </Col>
            </Row>

            <CustomModal width={'500px'} visible={showSelectOrganizationModal} onClose={() => {
                setShowSelectOrganizationModal(false);
            }}>
                <View center>
                    <View centerH style={{
                        width: "100%",
                        maxWidth: 500,
                    }}>
                        <View style={{
                            width: "100%",
                        }}>
                            <Text style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                            }}>Select Organization</Text>
                        </View>
                        <View marginT-10 style={{
                            width: "100%",
                        }}>
                            <ScrollView style={{
                                width: "100%",
                                height: Dimensions.get('window').height - 200,
                            }}>
                                {organizations.map((item, index) => {
                                    return <View marginT-5 key={index.toString()}>
                                        <SecondaryButton label={item.name} onPress={async () => {
                                            const res = await api.generateTokenByUserId({
                                                user_id: item.user_id,
                                                organization_id: item.id,
                                            });

                                            if (res.success) {
                                                await AsyncStorage.setItem('token', res.token);
                                                const user_id = item.user_id;

                                                api.setTokenToAPI(res.token, item.id);

                                                setToken(res.token);
                                                setGlobalData({
                                                    user_id: user_id,
                                                    organizations: organizations,
                                                    organization_id: item.id,
                                                    employee_id: item.employee_id,
                                                    // user: res.user,
                                                })

                                                navigationFn("dashboard");
                                                return;
                                            }

                                            if (res.message) {
                                                if (Platform.OS === 'web') {
                                                    message.success(res.message);
                                                } else {
                                                    Alert.alert(res.message);
                                                }
                                            } else {
                                                if (Platform.OS === 'web') {
                                                    message.success("Something went wrong");
                                                } else {
                                                    Alert.alert("Something went wrong");
                                                }
                                            }
                                        }} />
                                    </View>
                                })}
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </CustomModal>
        </View>
    );
}

