export const getCustomSidebarMenu = (id) => {
    const sidebarMap = {
        "15": [
            {
                "label": "Employees", "icon": "Employees", "children": [
                    { "label": "Employees", "icon": "Employees", "link": "Employees", "children": null },
                    { "label": "Shifts", "icon": "Shifts", "link": "Shifts", "children": null }
                ]
            },
            {
                "label": "Attendance", "icon": "Leave", "children": [
                    { "label": "By Date", "link": "report/AttendanceByDate", "children": null },
                    { "label": "By Employee", "link": "report/AttendanceByEmployee", "children": null }
                ]
            },
            {
                "label": "Finance", "icon": "Payrolls",
                "children": [
                    { "label": "Financial Summary", "link": "report/FinancialSummary", "children": null },
                    { "label": "Money Transfers", "icon": "MoneyTransfers", "link": "MoneyTransfers", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null }
                ]
            },
            {
                "label": "Items", "icon": "Items",
                "children": [
                    { "label": "Items", "icon": "items", "link": "Items" },
                    { "label": "Item Transfers", "icon": "Transfers", "link": "Transfers", "children": null },
                    { "label": "Item Tracing", "icon": "Transfers", "link": "ItemTracing", "children": null },
                    { "label": "Print Barcode", "icon": "BarcodePrinting", "link": "BarcodePrinting", "children": null }
                ]
            }, {
                "label": "Contacts", "icon": "3d-contact",
                "children": [
                    { "label": "Contacts", "icon": "contacts", "link": "Contacts" }
                ]
            },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Quick POS", "icon": "POS", "link": "POS", "children": null },
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null }
                ]
            },
            {
                "label": "Purchases", "icon": "3d-Purchase",
                "children": [
                    { "label": "Purchase Bills", "icon": "Bills", "link": "Bills", "children": null },
                    { "label": "Debit Notes", "icon": "PurchaseReturns", "link": "PurchaseReturns", "children": null }
                ]
            },
            { "label": "Accounts", "icon": "accounts", "link": "Accounts", "children": null },
            { "label": "Expenses", "icon": "no-money", "link": "Expenses", "children": null },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
            {
                "label": "Master", "icon": "Master", "children": [
                    { "label": "Locations", "link": "Locations", "children": null },
                    { "label": "Roles and Permissions", "link": "Roles", "children": null }
                ]
            },
        ],
        "16": [
            {
                "label": "Finance", "icon": "Payrolls",
                "children": [
                    // { "label": "Financial Summary", "link": "report/FinancialSummary", "children": null },
                    { "label": "Money Transfers", "icon": "MoneyTransfers", "link": "MoneyTransfers", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null },
                ]
            },
            {
                "label": "Items", "icon": "Items", "link": "Items"
            },
            { "label": "Contacts", "icon": "3d-contact", "link": "Contacts" },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null }
                ]
            },
            {
                "label": "Purchases", "icon": "3d-Purchase",
                "children": [
                    { "label": "Purchase Bills", "icon": "Bills", "link": "Bills", "children": null },
                    { "label": "Debit Notes", "icon": "PurchaseReturns", "link": "PurchaseReturns", "children": null }
                ]
            },
            {
                "label": "Accounting", "icon": "accounts",
                "children": [
                    { "label": "Accounts", "icon": "Accounts", "link": "Accounts", "children": null },
                    { "label": "Manual Journals", "icon": "ManualJournals", "link": "ManualJournals", "children": null },
                ]
            },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
        ],
        "17": [
            {
                "label": "Finance", "icon": "Payrolls",
                "children": [
                    // { "label": "Financial Summary", "link": "report/FinancialSummary", "children": null },
                    { "label": "Money Transfers", "icon": "MoneyTransfers", "link": "MoneyTransfers", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null },
                ]
            },
            {
                "label": "Items", "icon": "Items", "link": "Items"
            },
            { "label": "Contacts", "icon": "3d-contact", "link": "Contacts" },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null }
                ]
            },
            {
                "label": "Purchases", "icon": "3d-Purchase",
                "children": [
                    { "label": "Purchase Bills", "icon": "Bills", "link": "Bills", "children": null },
                    { "label": "Debit Notes", "icon": "PurchaseReturns", "link": "PurchaseReturns", "children": null }
                ]
            },
            {
                "label": "Accounting", "icon": "accounts",
                "children": [
                    { "label": "Accounts", "icon": "Accounts", "link": "Accounts", "children": null },
                    { "label": "Manual Journals", "icon": "ManualJournals", "link": "ManualJournals", "children": null },
                ]
            },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
        ],
        "18": [
            {
                "label": "Items", "icon": "Items", "link": "Items"
            },
            {
                "label": "Contacts", "icon": "3d-contact", "link": "Contacts"
            },
            {
                "label": "Finance", "icon": "Payrolls",
                "children": [
                    // { "label": "Financial Summary", "link": "report/FinancialSummary", "children": null },
                    // { "label": "Money Transfers", "icon": "MoneyTransfers", "link": "MoneyTransfers", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null },
                ]
            },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Quick POS", "icon": "POS", "link": "POS", "children": null },
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null }
                ]
            },
            {
                "label": "Purchases", "icon": "3d-Purchase",
                "children": [
                    { "label": "Purchase Bills", "icon": "Bills", "link": "Bills", "children": null },
                    { "label": "Debit Notes", "icon": "PurchaseReturns", "link": "PurchaseReturns", "children": null }
                ]
            },
            {
                "label": "Accounting", "icon": "accounts",
                "children": [
                    { "label": "Accounts", "icon": "Accounts", "link": "Accounts", "children": null },
                    { "label": "Manual Journals", "icon": "ManualJournals", "link": "ManualJournals", "children": null },
                ]
            },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
        ],
        "19": [
            {
                "label": "Employees", "icon": "Employees", "children": [
                    { "label": "Employees", "icon": "Employees", "link": "Employees", "children": null },
                    { "label": "Shifts", "icon": "Shifts", "link": "Shifts", "children": null }
                ]
            },
            {
                "label": "Items", "icon": "Items", "link": "Items"
            },
            { "label": "Contacts", "icon": "3d-contact", "link": "Contacts" },
            { "label": "Appointments", "iconLink": "https://cdn-icons-png.flaticon.com/512/3634/3634820.png", "link": "Appointments" },
            { "label": "Subscriptions", "iconLink": "https://cdn-icons-png.flaticon.com/512/1260/1260204.png", "link": "Subscriptions" },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null }
                ]
            },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
        ]
    };

    return sidebarMap[id];
}