import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { QRCodeSVG } from 'qrcode.react';
import { Platform } from "react-native";

export const hotelRoomfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '25%'
        },
        {
            label: 'Price',
            key: 'price',
            type: 'number',
            placeholder: 'Price',
            visible: true,
            width: '25%'
        },
        {
            label: 'Area',
            key: 'hotel_area_id',
            type: 'select',
            placeholder: 'Area',
            visible: true,
            width: '25%',
            options: response.HotelAreas,
            creatableAction: (props) => {
                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
                        is_default_values: true,
                        name: props.search,
                    } : {})
                };
                OpenModalCustom({ newModule: "HotelAreas", field_key: 'hotel_area_id', params });
            },
        },
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const hotelRoomMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const hotelRoomMain = {
    fields: hotelRoomfields,
    mobileCard: null
}