// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl, DeviceEventEmitter } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { create } from 'zustand';
import { invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { App } from 'antd';

const isDesktop = Dimensions.get('window').width >= 768;

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

const Checkout = (props) => {
    const { message, modal } = App.useApp();

    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("India");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [apartment, setApartment] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [paymentType, setPaymentType] = useState("Razorpay");

    let navigation = useNavigation();
    let navigate = null;

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);

    const [products, setProducts] = useState([]);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {

                    navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getModuleCreateData({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getModuleCreateData({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }

    const moduleName = "Invoices";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const [response, setResponse] = useState(null);

    const getModuleCreateData = async (values = null) => {
        const response = await api.getCreateData(moduleName, params);
        setResponse(response);
        setProducts(response?.Items || []);

        let form = null;

        if (params?.is_default_values) {
            const data = { ...params };
            delete data.is_default_values;
            delete data.moduleName;
            form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data));
        } else {
            form = fields(response, params?.viewOnly, moduleName, OpenModalCustom, null);
        }

        if (values) {
            form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, values));
        }

        const newObject = api.formToObject(form);
        setFormObject(newObject);
    };

    useEffect(() => {
        getModuleCreateData({ disable_auto_line_item: true });
    }, []);

    const updateFormObject = () => {
        const line_items = formObject.line_items || [];

        for (const cart_item of cart) {
            const index = line_items.findIndex((item) => item.product == cart_item._id);
            const item = products.find((item) => item._id == cart_item._id);

            const TaxGroups = response.TaxGroups || []
            const tax_group = TaxGroups.find((tax_group) => tax_group._id === item.tax_group_id)

            if (index == -1) {
                line_items.push({
                    "item_id": item._id,
                    "item_name": item.name,
                    "hsn_or_sac": item.hsn_or_sac,
                    "description": item.description,
                    "account_id": item.sales_account_id,
                    "track_inventory": item.track_inventory,
                    "track_serial_number": item.track_serial_number,
                    "track_batch_number": item.track_batch_number,
                    "usage_unit": item.usage_unit,
                    "tax_group_id": item.tax_group_id,
                    "tax_group": tax_group,
                    "quantity": cart_item.quantity,
                    "item_price": item.sales_rate || 0,
                    "item_gross_total": item.sales_rate || 0,
                    "item_total": item.sales_rate || 0,
                    "discount_percentage": 0,
                    "discount_amount": 0,
                    "status": 'Pending',
                    "serial_numbers": [],
                });
            } else {
                line_items[index].quantity = cart_item.quantity;
            }
        }

        setFormObject({
            line_items: line_items,
            shipping_charge: 100,
        });

        invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response);
    }

    useEffect(() => {
        if (response) {
            updateFormObject();
        }
    }, [cart, response]);

    const total_order_amount = cart && cart.length > 0 && cart.reduce((a, b) => a + ((b.sales_rate || 0) * (b.quantity || 1)), 0) + 100;

    const PlaceOrder = async (payment_details) => {
        api.customRoute("shop/placeOrder", {
            customer: {
                name: name,
                email: email,
                phone: phone,
            },
            shipping_address: {
                address: address,
                apartment: apartment,
                city: city,
                state: state,
                pincode: pincode,
            },
            items: cart.map((product) => ({
                product: product._id,
                quantity: product.quantity,
            })),
            payment_details: payment_details,
            invoice_details: getFormObject(),
        }).then((response) => {
            message.success("Order placed successfully");
            setCart([]);
            localStorage.setItem("checkoutData", JSON.stringify({
                customer_name: name,
                invoice_number: response.invoice_number,
            }));
            navigationFn("shop/thank-you");
        }).catch((error) => {
            message.error(error?.response?.data?.message || error?.message || "Something went wrong");
        });
    }

    const PaymentInitate = async () => {
        api.customRoute("shop/generateOrderIdRazorPay", {
            items: cart.map((product) => ({
                product: product._id,
                quantity: product.quantity,
            })),
            amount: total_order_amount * 100,
            currency: "INR",
        }).then((response) => {
            const options = {
                "key": "rzp_live_7xiIqL7c5X9Zwe",
                "amount": total_order_amount * 100,
                "currency": "INR",
                "name": "IKAKI",
                "order_id": response.orderId,
                "handler": function (response) {
                    console.log(response.razorpay_payment_id);
                    console.log(response.razorpay_order_id);
                    console.log(response.razorpay_signature)
                    PlaceOrder({
                        payment_mode: "razorpay",
                        response: response,
                    })
                },
                "prefill": {
                    "name": name,
                    "email": email,
                    "contact": phone
                },
                "theme": {
                    "color": "#cab78d"
                }
            };
            console.log(options);

            const rzp1 = new Razorpay(options);

            rzp1.on('payment.failed', function (response) {
                console.log(response.error.code);
                console.log(response.error.description);
                console.log(response.error.source);
                console.log(response.error.step);
                console.log(response.error.reason);
                console.log(response.error.metadata.order_id);
                console.log(response.error.metadata.payment_id);
            });

            rzp1.open();
        }).catch((error) => {
            console.log(error);
        });
    }

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                {/* <Header /> */}
                <Row>
                    <Col width={isDesktop ? "50%" : '100%'} style={{
                        padding: isDesktop ? 60 : 30,
                    }}>
                        <View style={{
                            width: '100%',
                        }}>
                            <View>
                                <Text style={{
                                    fontSize: 30,
                                    fontFamily: 'Sen_700Bold',
                                    color: '#000',
                                }}>Checkout</Text>
                            </View>
                        </View>

                        <View style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 20,
                            paddingBottom: 15,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <View>
                                <Text style={{
                                    fontSize: 16,
                                    fontFamily: 'Sen_700Bold',
                                    color: '#000',
                                }}>
                                    Contact
                                </Text>
                            </View>
                            {/* <View>Have an account? Log in</View> */}
                        </View>
                        <View style={{
                            paddingBottom: 15,
                            width: '100%',
                        }}>
                            {/* {Label({ label: "Email" })} */}

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                paddingBottom: 15,
                            }}>
                                <View paddingR-10 style={{
                                    width: '50%',
                                }}>
                                    <CustomTextField
                                        placeholder={"Name"}
                                        value={name}
                                        onChange={(value) => {
                                            setName(value);
                                        }} />
                                </View>
                                <View style={{
                                    width: '50%',
                                }}>
                                    <CustomTextField
                                        placeholder={"Email"}
                                        value={email}
                                        onChange={(value) => {
                                            setEmail(value);
                                        }} />
                                </View>
                            </View>

                            <CustomTextField
                                placeholder={"Phone Number"}
                                value={phone}
                                onChange={(value) => {
                                    setPhone(value);
                                }}
                                showCountryCode
                            />
                        </View>

                        <View style={{
                            paddingBottom: 15,
                        }}>
                            <Text style={{
                                fontSize: 16,
                                fontFamily: 'Sen_700Bold',
                                color: '#000',
                            }}>
                                Shipping Address
                            </Text>
                        </View>

                        {/* <View style={{
                            width: '100%',
                        }}>
                            <View paddingB-15 style={{
                                width: '100%',
                            }}>
                                <CustomSelect
                                    placeholder={"Country"}
                                    options={[]}
                                    value={state}
                                    onChange={(value) => {
                                        setState(value);
                                    }} />
                            </View>
                        </View> */}

                        <View style={{
                            width: '100%',
                            paddingBottom: 15,
                        }}>
                            <CustomTextField
                                placeholder={"Pincode"}
                                value={pincode} onChange={(value) => {
                                    setPincode(value);
                                }} />
                        </View>


                        <View paddingB-15 style={{
                            width: '100%',
                        }}>
                            <CustomTextField
                                placeholder={"Address"}
                                value={address}
                                onChange={(value) => {
                                    setAddress(value);
                                }} />
                        </View>

                        <View paddingB-15 style={{
                            width: '100%',
                        }}>
                            <CustomTextField
                                placeholder={"Apartment, suite, etc"}
                                value={apartment} onChange={(value) => {
                                    setApartment(value);
                                }} />
                        </View>

                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            <View paddingR-10 style={{
                                width: '50%',
                            }}>
                                <CustomTextField
                                    placeholder={"City"}
                                    value={city} onChange={(value) => {
                                        setCity(value);
                                    }} />
                            </View>

                            <View paddingR-10 style={{
                                width: '50%',
                            }}>
                                <CustomSelect
                                    placeholder={"State"}
                                    options={STATES}
                                    value={state}
                                    onChange={(value) => {
                                        setState(value);
                                    }} />
                            </View>

                        </View>

                    </Col>

                    <Col width={isDesktop ? "50%" : '100%'} style={{
                    }}>
                        <>
                            {!cart || cart?.length == 0 ? <View style={isDesktop ? {
                                height: Dimensions.get('window').height,
                                paddingTop: 60,
                                backgroundColor: '#F9F5F2',
                                borderLeft: '1px solid #cab78d',
                                width: '100%',
                                padding: 60,
                                marginBottom: 20,

                            } : {
                                borderLeft: 'none',
                                padding: 30,
                            }}><View style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                            }}>
                                    <Text style={{
                                        fontSize: 14,
                                        fontFamily: 'Sen_700Bold',
                                        color: '#414223',
                                    }}>No Items in cart</Text>
                                </View>

                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/products");
                                }}
                                    style={{
                                        backgroundColor: '#cab78d',
                                        color: '#000',
                                        padding: 10,
                                        width: '100%',
                                        alignItems: 'center',
                                        paddingVertical: 10,
                                        paddingHorizontal: 30,
                                        marginTop: 20
                                    }}
                                >
                                    <Text>
                                        CONTINUE SHOPPING
                                    </Text>
                                </TouchableOpacity>
                            </View> : <View style={isDesktop ? {
                                height: Dimensions.get('window').height,
                                paddingTop: 60,
                                backgroundColor: '#F9F5F2',
                                borderLeft: '1px solid #cab78d',
                                width: '100%',
                                padding: 60,
                                marginBottom: 20,

                            } : {
                                borderLeft: 'none',
                                padding: 30,
                            }}>

                                {/* {CartComponent(cart, setCart)} */}
                                <CartComponent cart={cart} setCart={setCart} />

                                <View style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: 10,
                                }}>
                                    <Text style={{
                                        fontSize: 14,
                                        color: '#000',
                                    }}>Shipping</Text>
                                    <Text style={{
                                        fontSize: 14,
                                        color: '#000',
                                    }}>Rs. 100.00</Text>
                                </View>

                                {formObject?.tax_map && Object.keys(formObject?.tax_map).map((key, i) => formObject?.tax_map[key] >= 0 ? <View key={i} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: 10,
                                }}>
                                    <Text style={{
                                        fontSize: 14,
                                        color: '#000',
                                    }}>{key}</Text>
                                    <Text style={{
                                        fontSize: 14,
                                        color: '#000',
                                    }}>Rs. {(formObject?.tax_map[key]).toFixed(2)}</Text>
                                </View> : null)}

                                <View style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <Text style={{
                                        fontSize: 16,
                                        color: '#000',
                                        fontFamily: 'Sen_700Bold',
                                    }}>Total</Text>
                                    <Text style={{
                                        fontSize: 16,
                                        color: '#000',
                                        fontFamily: 'Sen_700Bold',
                                    }}>Rs. {total_order_amount}</Text>
                                </View>

                                <View style={{
                                    width: '100%',
                                    height: 1,
                                    backgroundColor: '#cab78d',
                                    marginTop: 15,
                                }}></View>

                                <View style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}>
                                    <Text style={{
                                        fontSize: 16,
                                        fontFamily: 'Sen_700Bold',
                                        color: '#000',
                                    }}>
                                        Payment Method
                                    </Text>
                                </View>

                                <TouchableOpacity
                                    onPress={() => {
                                        setPaymentType("COD");
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingBottom: 10,
                                    }}>
                                    <Text style={{
                                        fontSize: 14,
                                        color: '#000',
                                    }}>COD</Text>

                                    <View marginL-10 style={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        borderColor: "rgb(202, 183, 141)",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        {paymentType == 'COD' ? <Icon name='checkmark-outline' width={12} height={12} fill={"rgb(113, 99, 68)"} /> : null}
                                    </View>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    onPress={() => {
                                        setPaymentType("Razorpay");
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingBottom: 10,
                                    }}>
                                    <Text style={{
                                        fontSize: 14,
                                        color: '#000',
                                    }}>Card/UPI/Net Banking</Text>

                                    <View marginL-10 style={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        borderColor: "rgb(202, 183, 141)",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        {paymentType == 'Razorpay' ? <Icon name='checkmark-outline' width={12} height={12} fill={"rgb(113, 99, 68)"} /> : null}
                                    </View>
                                </TouchableOpacity>

                                <View style={{
                                    width: '100%',
                                    paddingTop: 20,
                                }}>
                                    <TouchableOpacity onPress={() => {
                                        if (!name) {
                                            message.error("Name is required");
                                            return;
                                        } else if (!phone) {
                                            message.error("Phone is required");
                                            return;
                                        } else if (!address) {
                                            message.error("Address is required");
                                            return;
                                        } else if (!city) {
                                            message.error("City is required");
                                            return;
                                        } else if (!state) {
                                            message.error("State is required");
                                            return;
                                        } else if (!pincode) {
                                            message.error("Pincode is required");
                                            return;
                                        }

                                        if (paymentType == "COD") {
                                            PlaceOrder({
                                                payment_mode: "cod",
                                            })
                                        } else {
                                            PaymentInitate();
                                        }
                                    }}
                                        style={{
                                            backgroundColor: '#cab78d',
                                            color: '#000',
                                            padding: 10,
                                            width: '100%',
                                            alignItems: 'center',
                                            paddingVertical: 10,
                                            paddingHorizontal: 30,
                                        }}
                                    >
                                        <Text color="white">BUY IT NOW</Text>
                                    </TouchableOpacity>
                                </View>

                            </View>}
                        </>
                    </Col>
                </Row>
                {/* <Footer /> */}
            </Card>
        </ScrollView>
    </>);
};

export default Checkout;


const STATES = [
    {
        "_id": "653399d6f16f979e73ca92a0",
        "id": 4023,
        "name": "Andaman and Nicobar Islands",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AN",
        "type": "Union territory",
        "latitude": "11.74008670",
        "longitude": "92.65864010",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.169Z",
        "updatedAt": "2023-10-21T09:28:54.169Z",
        "__v": 0,
        "label": "Andaman and Nicobar Islands",
        "value": "Andaman and Nicobar Islands"
    },
    {
        "_id": "653399d6f16f979e73ca92a3",
        "id": 4017,
        "name": "Andhra Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AP",
        "type": "state",
        "latitude": "15.91289980",
        "longitude": "79.73998750",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.174Z",
        "updatedAt": "2023-10-21T09:28:54.174Z",
        "__v": 0,
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92a6",
        "id": 4024,
        "name": "Arunachal Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AR",
        "type": "state",
        "latitude": "28.21799940",
        "longitude": "94.72775280",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.179Z",
        "updatedAt": "2023-10-21T09:28:54.179Z",
        "__v": 0,
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92a9",
        "id": 4027,
        "name": "Assam",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AS",
        "type": "state",
        "latitude": "26.20060430",
        "longitude": "92.93757390",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.182Z",
        "updatedAt": "2023-10-21T09:28:54.182Z",
        "__v": 0,
        "label": "Assam",
        "value": "Assam"
    },
    {
        "_id": "653399d6f16f979e73ca92ac",
        "id": 4037,
        "name": "Bihar",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "BR",
        "type": "state",
        "latitude": "25.09607420",
        "longitude": "85.31311940",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.187Z",
        "updatedAt": "2023-10-21T09:28:54.187Z",
        "__v": 0,
        "label": "Bihar",
        "value": "Bihar"
    },
    {
        "_id": "653399d6f16f979e73ca92af",
        "id": 4031,
        "name": "Chandigarh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "CH",
        "type": "Union territory",
        "latitude": "30.73331480",
        "longitude": "76.77941790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.191Z",
        "updatedAt": "2023-10-21T09:28:54.191Z",
        "__v": 0,
        "label": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "_id": "653399d6f16f979e73ca92b2",
        "id": 4040,
        "name": "Chhattisgarh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "CT",
        "type": "state",
        "latitude": "21.27865670",
        "longitude": "81.86614420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.195Z",
        "updatedAt": "2023-10-21T09:28:54.195Z",
        "__v": 0,
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
    },
    {
        "_id": "653399d6f16f979e73ca92b5",
        "id": 4033,
        "name": "Dadra and Nagar Haveli and Daman and Diu",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "DH",
        "type": "Union territory",
        "latitude": "20.39737360",
        "longitude": "72.83279910",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.199Z",
        "updatedAt": "2023-10-21T09:28:54.199Z",
        "__v": 0,
        "label": "Dadra and Nagar Haveli and Daman and Diu",
        "value": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
        "_id": "653399d6f16f979e73ca92b8",
        "id": 4021,
        "name": "Delhi",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "DL",
        "type": "Union territory",
        "latitude": "28.70405920",
        "longitude": "77.10249020",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.204Z",
        "updatedAt": "2023-10-21T09:28:54.204Z",
        "__v": 0,
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "_id": "653399d6f16f979e73ca92bb",
        "id": 4009,
        "name": "Goa",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "GA",
        "type": "state",
        "latitude": "15.29932650",
        "longitude": "74.12399600",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.207Z",
        "updatedAt": "2023-10-21T09:28:54.207Z",
        "__v": 0,
        "label": "Goa",
        "value": "Goa"
    },
    {
        "_id": "653399d6f16f979e73ca92be",
        "id": 4030,
        "name": "Gujarat",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "GJ",
        "type": "state",
        "latitude": "22.25865200",
        "longitude": "71.19238050",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.212Z",
        "updatedAt": "2023-10-21T09:28:54.212Z",
        "__v": 0,
        "label": "Gujarat",
        "value": "Gujarat"
    },
    {
        "_id": "653399d6f16f979e73ca92c1",
        "id": 4007,
        "name": "Haryana",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "HR",
        "type": "state",
        "latitude": "29.05877570",
        "longitude": "76.08560100",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.217Z",
        "updatedAt": "2023-10-21T09:28:54.217Z",
        "__v": 0,
        "label": "Haryana",
        "value": "Haryana"
    },
    {
        "_id": "653399d6f16f979e73ca92c4",
        "id": 4020,
        "name": "Himachal Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "HP",
        "type": "state",
        "latitude": "31.10482940",
        "longitude": "77.17339010",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.223Z",
        "updatedAt": "2023-10-21T09:28:54.223Z",
        "__v": 0,
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92c7",
        "id": 4029,
        "name": "Jammu and Kashmir",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "JK",
        "type": "Union territory",
        "latitude": "33.27783900",
        "longitude": "75.34121790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.228Z",
        "updatedAt": "2023-10-21T09:28:54.228Z",
        "__v": 0,
        "label": "Jammu and Kashmir",
        "value": "Jammu and Kashmir"
    },
    {
        "_id": "653399d6f16f979e73ca92ca",
        "id": 4025,
        "name": "Jharkhand",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "JH",
        "type": "state",
        "latitude": "23.61018080",
        "longitude": "85.27993540",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.233Z",
        "updatedAt": "2023-10-21T09:28:54.233Z",
        "__v": 0,
        "label": "Jharkhand",
        "value": "Jharkhand"
    },
    {
        "_id": "653399d6f16f979e73ca92cd",
        "id": 4026,
        "name": "Karnataka",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "KA",
        "type": "state",
        "latitude": "15.31727750",
        "longitude": "75.71388840",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.239Z",
        "updatedAt": "2023-10-21T09:28:54.239Z",
        "__v": 0,
        "label": "Karnataka",
        "value": "Karnataka"
    },
    {
        "_id": "653399d6f16f979e73ca92d0",
        "id": 4028,
        "name": "Kerala",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "KL",
        "type": "state",
        "latitude": "10.85051590",
        "longitude": "76.27108330",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.244Z",
        "updatedAt": "2023-10-21T09:28:54.244Z",
        "__v": 0,
        "label": "Kerala",
        "value": "Kerala"
    },
    {
        "_id": "653399d6f16f979e73ca92d3",
        "id": 4852,
        "name": "Ladakh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "LA",
        "type": "Union territory",
        "latitude": "34.22684750",
        "longitude": "77.56194190",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.252Z",
        "updatedAt": "2023-10-21T09:28:54.252Z",
        "__v": 0,
        "label": "Ladakh",
        "value": "Ladakh"
    },
    {
        "_id": "653399d6f16f979e73ca92d6",
        "id": 4019,
        "name": "Lakshadweep",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "LD",
        "type": "Union territory",
        "latitude": "10.32802650",
        "longitude": "72.78463360",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.258Z",
        "updatedAt": "2023-10-21T09:28:54.258Z",
        "__v": 0,
        "label": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "_id": "653399d6f16f979e73ca92d9",
        "id": 4039,
        "name": "Madhya Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MP",
        "type": "state",
        "latitude": "22.97342290",
        "longitude": "78.65689420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.265Z",
        "updatedAt": "2023-10-21T09:28:54.265Z",
        "__v": 0,
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92dc",
        "id": 4008,
        "name": "Maharashtra",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MH",
        "type": "state",
        "latitude": "19.75147980",
        "longitude": "75.71388840",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.273Z",
        "updatedAt": "2023-10-21T09:28:54.273Z",
        "__v": 0,
        "label": "Maharashtra",
        "value": "Maharashtra"
    },
    {
        "_id": "653399d6f16f979e73ca92df",
        "id": 4010,
        "name": "Manipur",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MN",
        "type": "state",
        "latitude": "24.66371730",
        "longitude": "93.90626880",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.279Z",
        "updatedAt": "2023-10-21T09:28:54.279Z",
        "__v": 0,
        "label": "Manipur",
        "value": "Manipur"
    },
    {
        "_id": "653399d6f16f979e73ca92e2",
        "id": 4006,
        "name": "Meghalaya",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "ML",
        "type": "state",
        "latitude": "25.46703080",
        "longitude": "91.36621600",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.285Z",
        "updatedAt": "2023-10-21T09:28:54.285Z",
        "__v": 0,
        "label": "Meghalaya",
        "value": "Meghalaya"
    },
    {
        "_id": "653399d6f16f979e73ca92e5",
        "id": 4036,
        "name": "Mizoram",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MZ",
        "type": "state",
        "latitude": "23.16454300",
        "longitude": "92.93757390",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.291Z",
        "updatedAt": "2023-10-21T09:28:54.291Z",
        "__v": 0,
        "label": "Mizoram",
        "value": "Mizoram"
    },
    {
        "_id": "653399d6f16f979e73ca92e8",
        "id": 4018,
        "name": "Nagaland",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "NL",
        "type": "state",
        "latitude": "26.15843540",
        "longitude": "94.56244260",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.295Z",
        "updatedAt": "2023-10-21T09:28:54.295Z",
        "__v": 0,
        "label": "Nagaland",
        "value": "Nagaland"
    },
    {
        "_id": "653399d6f16f979e73ca92eb",
        "id": 4013,
        "name": "Odisha",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "OR",
        "type": "state",
        "latitude": "20.95166580",
        "longitude": "85.09852360",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.300Z",
        "updatedAt": "2023-10-21T09:28:54.300Z",
        "__v": 0,
        "label": "Odisha",
        "value": "Odisha"
    },
    {
        "_id": "653399d6f16f979e73ca92ee",
        "id": 4011,
        "name": "Puducherry",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "PY",
        "type": "Union territory",
        "latitude": "11.94159150",
        "longitude": "79.80831330",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.305Z",
        "updatedAt": "2023-10-21T09:28:54.305Z",
        "__v": 0,
        "label": "Puducherry",
        "value": "Puducherry"
    },
    {
        "_id": "653399d6f16f979e73ca92f1",
        "id": 4015,
        "name": "Punjab",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "PB",
        "type": "state",
        "latitude": "31.14713050",
        "longitude": "75.34121790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.311Z",
        "updatedAt": "2023-10-21T09:28:54.311Z",
        "__v": 0,
        "label": "Punjab",
        "value": "Punjab"
    },
    {
        "_id": "653399d6f16f979e73ca92f4",
        "id": 4014,
        "name": "Rajasthan",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "RJ",
        "type": "state",
        "latitude": "27.02380360",
        "longitude": "74.21793260",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.316Z",
        "updatedAt": "2023-10-21T09:28:54.316Z",
        "__v": 0,
        "label": "Rajasthan",
        "value": "Rajasthan"
    },
    {
        "_id": "653399d6f16f979e73ca92f7",
        "id": 4034,
        "name": "Sikkim",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "SK",
        "type": "state",
        "latitude": "27.53297180",
        "longitude": "88.51221780",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.321Z",
        "updatedAt": "2023-10-21T09:28:54.321Z",
        "__v": 0,
        "label": "Sikkim",
        "value": "Sikkim"
    },
    {
        "_id": "653399d6f16f979e73ca92fa",
        "id": 4035,
        "name": "Tamil Nadu",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TN",
        "type": "state",
        "latitude": "11.12712250",
        "longitude": "78.65689420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.326Z",
        "updatedAt": "2023-10-21T09:28:54.326Z",
        "__v": 0,
        "label": "Tamil Nadu",
        "value": "Tamil Nadu"
    },
    {
        "_id": "653399d6f16f979e73ca92fd",
        "id": 4012,
        "name": "Telangana",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TG",
        "type": "state",
        "latitude": "18.11243720",
        "longitude": "79.01929970",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.331Z",
        "updatedAt": "2023-10-21T09:28:54.331Z",
        "__v": 0,
        "label": "Telangana",
        "value": "Telangana"
    },
    {
        "_id": "653399d6f16f979e73ca9300",
        "id": 4038,
        "name": "Tripura",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TR",
        "type": "state",
        "latitude": "23.94084820",
        "longitude": "91.98815270",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.336Z",
        "updatedAt": "2023-10-21T09:28:54.336Z",
        "__v": 0,
        "label": "Tripura",
        "value": "Tripura"
    },
    {
        "_id": "653399d6f16f979e73ca9303",
        "id": 4022,
        "name": "Uttar Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "UP",
        "type": "state",
        "latitude": "26.84670880",
        "longitude": "80.94615920",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.341Z",
        "updatedAt": "2023-10-21T09:28:54.341Z",
        "__v": 0,
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca9306",
        "id": 4016,
        "name": "Uttarakhand",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "UT",
        "type": "state",
        "latitude": "30.06675300",
        "longitude": "79.01929970",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.346Z",
        "updatedAt": "2023-10-21T09:28:54.346Z",
        "__v": 0,
        "label": "Uttarakhand",
        "value": "Uttarakhand"
    },
    {
        "_id": "653399d6f16f979e73ca9309",
        "id": 4853,
        "name": "West Bengal",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "WB",
        "type": "state",
        "latitude": "22.98675690",
        "longitude": "87.85497550",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.351Z",
        "updatedAt": "2023-10-21T09:28:54.351Z",
        "__v": 0,
        "label": "West Bengal",
        "value": "West Bengal"
    }
]

export const CartComponent = ({ cart, setCart }) => {
    if (!cart || cart.length == 0) return <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }}>
        <Text style={{
            fontSize: 14,
            color: '#000',
        }}>No items in cart</Text>
    </View>;

    return <>
        {cart && cart.length > 0 && cart.map((product, i) => <View key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        }}>
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            }}>
                <View marginR-15 style={{
                    borderRadius: '8px',
                    border: '2px solid #000',
                }}>
                    <Image source={{
                        uri: product.image_urls ? product.image_urls[0].url : "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                    }}
                        style={{ width: 80, height: 80 }} />
                </View>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'Sen_700Bold',
                        color: '#414223',
                    }}>{product.name}</Text>

                    {product.meta && Object.keys(product.meta).map((key, i) => <View key={i} style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: 5,
                    }}>
                        <Text style={{
                            fontSize: 14,
                            color: '#535353',
                        }}>{key}: </Text>
                        <Text style={{
                            fontSize: 14,
                            color: '#535353',
                        }}>{product.meta[key]}</Text>
                    </View>)}

                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '40%',
                        paddingTop: 10,
                    }}>
                        <TouchableOpacity onPress={() => {
                            if (product.quantity == 1) {
                                setCart(cart.filter((p) => p._id != product._id))
                            } else {
                                product.quantity = (product.quantity || 1) - 1
                                setCart([...cart])
                            }
                        }}
                            style={{
                                backgroundColor: '#fff',
                                border: '2px solid #DCDCDC',
                                color: '#000',
                                padding: 5,
                                width: 60,
                                alignItems: 'center',
                                fontFamily: 'Sen_700Bold',
                                marginRight: 5,
                                height: 36,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={{
                                color: '#000',
                            }}>-</Text>
                        </TouchableOpacity>
                        <View style={{
                            width: 60
                        }}>
                            <CustomTextField
                                placeholder={"0"}
                                value={product.quantity || 1}
                                onChange={(value) => {
                                    if (isNaN(value)) {
                                        return
                                    }
                                    product.quantity = (+value)
                                    setCart([...cart])
                                }}
                                style={{
                                    borderRadius: 0,
                                    backgroundColor: '#DCDCDC',
                                    color: '#000',
                                }}
                                center />
                        </View>
                        <TouchableOpacity onPress={() => {
                            product.quantity = (product.quantity || 1) + 1
                            setCart([...cart])
                        }}
                            style={{
                                backgroundColor: '#fff',
                                border: '2px solid #DCDCDC',
                                color: '#000',
                                padding: 5,
                                width: 60,
                                alignItems: 'center',
                                fontFamily: 'Sen_700Bold',
                                marginLeft: 5,
                            }}
                        >
                            <Text style={{
                                color: '#000',
                            }}>+</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View>
                <Text style={{
                    fontSize: 14,
                    fontFamily: 'Sen_700Bold',
                    color: '#414223',
                }}>Rs. {product.sales_rate}</Text>
            </View>
        </View>)}

        <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 10,
            marginTop: 10
        }}>
            <Text style={{
                fontSize: 14,
                color: '#000',
            }}>Subtotal</Text>
            <Text style={{
                fontSize: 14,
                color: '#000',
            }}>Rs. {cart && cart.length > 0 && cart.reduce((a, b) => a + ((b.sales_rate || 0) * (b.quantity || 1)), 0)}</Text>
        </View>
    </>
}

export const WishlistComponent = ({ wishList, setWishList, cart, setCart }) => {
    if (!wishList || wishList.length == 0) return <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }}>
        <Text style={{
            fontSize: 14,
            color: '#000',
        }}>No items in wishlist</Text>
    </View>;

    return <>
        {wishList && wishList.length > 0 && wishList.map((product, i) => <View key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 10,
        }}>
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            }}>
                <View marginR-15 style={{
                    borderRadius: '8px',
                    border: '2px solid #000',
                }}>
                    <Image source={{
                        uri: product.image_urls ? product.image_urls[0].url : "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                    }}
                        style={{ width: 80, height: 80 }} />
                </View>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'Sen_700Bold',
                        color: '#414223',
                    }}>{product.name}</Text>

                    {product.meta && Object.keys(product.meta).map((key, i) => <View key={i} style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: 5,
                    }}>
                        <Text style={{
                            fontSize: 14,
                            color: '#535353',
                        }}>{key}: </Text>
                        <Text style={{
                            fontSize: 14,
                            color: '#535353',
                        }}>{product.meta[key]}</Text>
                    </View>)}

                    {/* Add buttons to delete from wishlist and add to cart */}

                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 10,
                    }}>
                        <TouchableOpacity onPress={() => {
                            setWishList(wishList.filter((p) => p._id != product._id))
                        }}
                            style={{
                                padding: 5,
                                paddingHorizontal: 10,
                                borderWidth: 1,
                                borderColor: 'gainsboro',
                                borderRadius: 6,
                            }}
                        >
                            <Text style={{
                                fontFamily: 'Sen_700Bold',
                                letterSpacing: 1,
                                color: '#cc0c0c',
                            }}>Remove</Text>
                        </TouchableOpacity>
                        {(cart && cart.length > 0 && cart.filter((p) => p._id == product._id).length > 0) ?
                            <TouchableOpacity onPress={() => {
                                setCart(cart.filter((p) => p._id != product._id))
                            }}
                                style={{
                                    marginLeft: 10,
                                    padding: 5,
                                    paddingHorizontal: 10,
                                    borderWidth: 1,
                                    borderColor: 'gainsboro',
                                    borderRadius: 6,
                                }}
                            >
                                <Text style={{
                                    fontFamily: 'Sen_700Bold',
                                    letterSpacing: 1,
                                    color: "#323232",
                                }}>Remove from cart</Text>
                            </TouchableOpacity> :
                            <TouchableOpacity onPress={() => {
                                setCart([...cart, product])
                            }}
                                style={{
                                    marginLeft: 10,
                                    padding: 5,
                                    paddingHorizontal: 10,
                                    borderWidth: 1,
                                    borderColor: 'gainsboro',
                                    borderRadius: 6,
                                }}
                            >
                                <Text style={{
                                    fontFamily: 'Sen_700Bold',
                                    letterSpacing: 1,
                                    color: "#323232",
                                }}>Add to cart</Text>
                            </TouchableOpacity>}

                    </View>

                </View>
            </View>
            <View>
                <Text style={{
                    fontSize: 14,
                    fontFamily: 'Sen_700Bold',
                    color: '#414223',
                }}>Rs. {product.sales_rate}</Text>
            </View>
        </View>)}
    </>
}